import { ITableColumn, ITableDataItem } from "@inceptionbg/ui-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRenewalCondition } from "../../types/IFarm";
import { faCircleCheck } from "../../assets/icons/solid/faCircleCheck";
import { faCircleXmark } from "../../assets/icons/solid/faCircleXmark";
import { faMinus } from "../../assets/icons/solid/faMinus";
import { RecapitulationTable } from "./RecapitulationTable";

interface Props {
  renewalConditions: IRenewalCondition;
}

export const RenewalConditionsTable: FC<Props> = ({ renewalConditions }) => {
  const { t } = useTranslation();

  const columns: ITableColumn[] = [
    { id: "name", label: "" },
    { id: "value", label: "", align: "right" },
  ];

  const iconType = (condition?: boolean) => {
    return condition !== undefined
      ? condition
        ? faCircleCheck
        : faCircleXmark
      : faMinus;
  };

  const labelColor = (condition?: boolean) => {
    return condition !== undefined ? (condition ? "success" : "error") : "";
  };

  const haveTechnicalConditions =
    renewalConditions.animal !== false &&
    renewalConditions.upzCondition !== false &&
    renewalConditions.farmStatus !== false &&
    renewalConditions.hasEDelivery !== false &&
    renewalConditions.hasEmailOrPhoneNumber !== false;

  const data: ITableDataItem[] = [
    {
      uuid: "countrysideTourism",
      cells: {
        name: {
          value: t("CountrysideTourism"),
          className: `color-${
            renewalConditions.countrysideTourism === false ? "error" : "black"
          }`,
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.countrysideTourism)}
              className={`alert-icon icon-2 ${labelColor(
                renewalConditions.countrysideTourism
              )}`}
            />
          ),
        },
      },
    },
    {
      uuid: "atLeastOnePlantCultureOrAnimal",
      cells: {
        name: {
          value: t("AtLeastOnePlantCultureOrAnimal"),
          className: `color-${
            renewalConditions.atLeastOnePlantCultureOrAnimal === false
              ? "error"
              : "black"
          }`,
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.atLeastOnePlantCultureOrAnimal)}
              className={`alert-icon icon-2 ${labelColor(
                renewalConditions.atLeastOnePlantCultureOrAnimal
              )}`}
            />
          ),
        },
      },
    },
    {
      uuid: "plantCulture",
      cells: {
        name: {
          value: t("PlantCulture"),
          className: clsx("ml-4", {
            "color-error": renewalConditions.plantCulture === false,
          }),
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.plantCulture)}
              className={`alert-icon icon-1 ${labelColor(
                renewalConditions.plantCulture
              )}`}
            />
          ),
        },
      },
    },
    {
      uuid: "animal",
      cells: {
        name: {
          value: t("AnimalCulture"),
          className: clsx("ml-4", {
            "color-error": renewalConditions.animal === false,
          }),
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.animal)}
              className={`alert-icon icon-1 ${labelColor(
                renewalConditions.animal
              )}`}
            />
          ),
        },
      },
    },
    {
      uuid: "allowedAprCodes",
      cells: {
        name: {
          value: t("AllowedAprCodes"),
          className: `color-${
            renewalConditions.allowedAprCodes === false ? "error" : "black"
          }`,
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.allowedAprCodes)}
              className={`alert-icon icon-2 ${labelColor(
                renewalConditions.allowedAprCodes
              )}`}
            />
          ),
        },
      },
    },
    {
      uuid: "technicalConditions",
      cells: {
        name: {
          value: t("TechnicalPrerequisiteCondition"),
          className: `color-${!haveTechnicalConditions ? "error" : "black"}`,
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(haveTechnicalConditions)}
              className={`alert-icon icon-2 ${labelColor(
                haveTechnicalConditions
              )}`}
            />
          ),
        },
      },
    },
    {
      uuid: "basicInfoData",
      cells: {
        name: {
          value: t("BasicInfoData"),
          className: clsx("ml-4"),
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={faCircleCheck}
              className={`alert-icon icon-1 success`}
            />
          ),
        },
      },
    },
    {
      uuid: "upzCondition",
      cells: {
        name: {
          value: t("UpzCondition"),
          className: clsx("ml-4", {
            "color-error": renewalConditions.upzCondition === false,
          }),
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.upzCondition)}
              className={`alert-icon icon-1 ${labelColor(
                renewalConditions.upzCondition
              )}`}
            />
          ),
        },
      },
    },
    {
      uuid: "animalNumber",
      cells: {
        name: {
          value: t("AnimalNumberData"),
          className: clsx("ml-4", {
            "color-error": renewalConditions.animal === false,
          }),
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.animal)}
              className={`alert-icon icon-1 ${labelColor(
                renewalConditions.animal
              )}`}
            />
          ),
        },
      },
    },
    {
      uuid: "farmStatus",
      cells: {
        name: {
          value: t("RenewalFarmStatus"),
          className: clsx("ml-4", {
            "color-error": renewalConditions.farmStatus === false,
          }),
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.farmStatus)}
              className={`alert-icon icon-1 ${labelColor(
                renewalConditions.farmStatus
              )}`}
            />
          ),
        },
      },
    },
    {
      uuid: "hasEDelivery",
      cells: {
        name: {
          value: t("HasEDelivery"),
          className: clsx("ml-4", {
            "color-error": renewalConditions.hasEDelivery === false,
          }),
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.hasEDelivery)}
              className={`alert-icon icon-1 ${labelColor(
                renewalConditions.hasEDelivery
              )}`}
            />
          ),
        },
      },
    },
    {
      uuid: "hasEmailOrPhoneNumber",
      cells: {
        name: {
          value: t("HasEmailOrPhoneNumber"),
          className: clsx("ml-4", {
            "color-error": renewalConditions.hasEmailOrPhoneNumber === false,
          }),
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.hasEmailOrPhoneNumber)}
              className={`alert-icon icon-1 ${labelColor(
                renewalConditions.hasEmailOrPhoneNumber
              )}`}
            />
          ),
        },
      },
    },
  ];

  const totals: ITableDataItem[] = [
    {
      uuid: "readyForRenewal",
      cells: {
        name: {
          value: t("ReadyForRenewal"),
          className: clsx({
            "color-error": renewalConditions.readyForRenewal === false,
          }),
        },
        value: {
          value: (
            <FontAwesomeIcon
              icon={iconType(renewalConditions.readyForRenewal)}
              className={`alert-icon icon-2 ${labelColor(
                renewalConditions.readyForRenewal
              )}`}
            />
          ),
        },
      },
    },
  ];

  return (
    <RecapitulationTable
      title={t("RenewalConditionsTable")}
      tableColumns={columns}
      tableData={data}
      totalsData={totals}
      hideHeader
    />
  );
};
