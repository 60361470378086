import { axiosInstance } from "../App";
import {
  IAprResponse,
  ICulture,
  ILand,
  ILandInfo,
  ILandPart,
  ILandReport,
  ILandsSum,
  LandFetchOption,
} from "../types/ILand";
import { IPagination } from "@inceptionbg/ui-components";
import { IFile } from "../types/IFile";

export const getLands = (
  farmUuid: string,
  filters?: {
    fetchOptions?: LandFetchOption[];
    memberUuid?: string;
  }
) =>
  axiosInstance
    .get(`/farm/${farmUuid}/lands`, { params: filters })
    .then(({ data }) => ({ lands: data.lands as ILand[] }));

export const getLandsSum = (farmUuid: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/lands/sum`)
    .then(({ data }) => ({ sum: data as ILandsSum }));

export const getLandParts = (landUuid: string) =>
  axiosInstance
    .get(`/land/${landUuid}/parts`)
    .then(({ data }) => data as ILandPart[]);

export const getUnclaimedLand = (farmUuid: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/unclaimed-land`)
    .then(({ data }) => data.surface as number);

export const updateLandInfoRGZ = (landUuid: string) =>
  axiosInstance.patch("/admin/land", { uuid: landUuid });

export const getLandAPRData = (landUuid: string) =>
  axiosInstance.get(`/land/${landUuid}/apr`).then(({ data }) => ({
    aprResponse: data.aprResponse as IAprResponse,
    storedFile: data.storedFile as IFile,
  }));

export const updateLandAPRData = (landUuid: string) =>
  axiosInstance.patch("/admin/land/apr", { uuid: landUuid });

// CULTURES

export const getLandCultures = (landUuid: string) =>
  axiosInstance
    .get(`/land/${landUuid}/cultures`)
    .then(({ data }) => ({ cultures: data.cultures as ICulture[] }));

export const getLandInfo = (landUuid: string) =>
  axiosInstance
    .get(`/land/${landUuid}/info`)
    .then(({ data }) => data as ILandInfo);

export const getLandReports = (pagination: IPagination) =>
  axiosInstance
    .get(`/land/reports`, {
      params: {
        ...pagination,
      },
    })
    .then(({ data }) => ({
      reports: data.approvals as ILandReport[],
      totalRows: data.totalRows as number,
    }));
