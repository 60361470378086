import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ILandReport } from "../../../types/ILand";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import { SurfaceValue } from "../../Inputs/Surface/SurfaceValue";
import { convertSurfaceToObj } from "../../../utils/CalculateUtils";

interface Props {
  landReport: ILandReport;
}

export const LandReportForm: FC<Props> = ({ landReport }) => {
  const { t } = useTranslation();

  const formInfo: IInputComponentProps[] = [
    {
      label: t("CadastreMunicipality"),
      value: landReport.cadastreMunicipality?.name,
    },
    {
      label: t("CadastreNumber"),
      value: landReport.cadastreNumber,
    },
    {
      label: t("LandType"),
      value: landReport.type ? t(`LandReport${landReport.type}`) : "",
    },
    {
      label: t("UsedSurface"),
      valueElement: (
        <SurfaceValue
          valueObj={convertSurfaceToObj(landReport.usedSurface)}
          simple
        />
      ),
    },
    {
      label: t("Note"),
      value: landReport.note,
    },
    {
      label: t("Submitter"),
      value: landReport.farm?.registrationNumber,
    },
    {
      label: t("SubmitterFirstNameAndLastName"),
      value:
        landReport.createdBy?.firstName + " " + landReport.createdBy?.lastName,
    },
    {
      label: t("SubmitterPersonalIdentityNumber"),
      value: landReport.creatorPersonalIdentityNumber,
    },
    {
      label: t("Status"),
      value: landReport.status ? t(`${landReport.status}`) : "",
    },
  ];

  return (
    <div className="form-container">
      {formInfo.map((e) => !e.hidden && <Input key={e.label} component={e} />)}
    </div>
  );
};
