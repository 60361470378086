import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import { IRefractionRequestSummaryReport } from "../../../types/IRefraction";
import { getRefractionRequestSummaryReport } from "../../../repos/RefractionRequestsRepo";

export const RefractionRequestSummaryReport: FC = () => {
  const [summaryData, setSummaryData] =
    useState<IRefractionRequestSummaryReport>({});

  const { t } = useTranslation();

  useEffect(() => {
    getRefractionRequestSummaryReport().then((data) => setSummaryData(data));
  }, []);

  const refractionRequestSummary: IInputComponentProps[] = [
    {
      label: t("TotalSubmittedRequests"),
      value: summaryData.totalSubmittedRequests ?? "0",
    },
    {
      label: t("TotalResolutionsIssued"),
      value: summaryData.totalResolutionsIssued ?? "0",
    },
    {
      label: t("TotalSentToPaymentRequests"),
      value: summaryData.totalSentToPaymentRequests ?? "0",
    },
    {
      label: t("TotalPayedRequests"),
      value: summaryData.totalPayedRequests ?? "0",
    },
    {
      label: t("TotalRejectedRequests"),
      value: summaryData.totalRejectedRequests ?? "0",
    },
    {
      label: t("TotalRequestsInDraft"),
      value: summaryData.totalRequestsInDraft ?? "0",
    },
  ];

  return (
    <div className="form-container">
      {refractionRequestSummary.map(
        (e) => !e.hidden && <Input key={e.label} component={e} />
      )}
    </div>
  );
};
