import { axiosInstance } from "../App";
import { IPagination } from "@inceptionbg/ui-components";
import {
  IApprovalGrouped,
  IApprovalNew,
  IApprovalSearch,
  IChangeApprovalStatus,
  IChangedObject,
  IProcedureData,
  IUpdateApprovalEntityData,
} from "../types/IApprovals";
import { RequestUtils } from "../utils/RequestUtils";

export const getApprovals = (
  pagination: IPagination,
  search?: IApprovalSearch
) =>
  axiosInstance
    .get(`/approvals`, {
      params: {
        ...pagination,
        ...RequestUtils.replaceEmptyWithNull(search),
      },
    })
    .then(({ data }) => ({
      approvals: data.approvals as IApprovalNew[],
      totalRows: data.totalRows as number,
    }));

export const getApprovalsGrouped = (
  pagination: IPagination,
  search?: IApprovalSearch
) =>
  axiosInstance
    .get(`/approvals/grouped`, {
      params: {
        ...pagination,
        ...RequestUtils.replaceEmptyWithNull(search),
      },
    })
    .then(({ data }) => ({
      approvalsGrouped: data.approvalsGrouped as IApprovalGrouped[],
      totalRows: data.totalRows as number,
    }));

export const getApprovalByUuid = (
  approvalUuid: string,
  approvalPreview: boolean
) =>
  axiosInstance
    .get(`/approval/${approvalUuid}`, { params: { approvalPreview } })
    .then(({ data }) => ({
      approval: data as IApprovalNew,
    }));

export const moveToAdministrativeProcedure = (
  approvalUuid: string,
  data: { comment: string }
) =>
  axiosInstance.post(
    `/approval/${approvalUuid}/administrative-procedure`,
    data
  );

export const approveOrRejectProcedure = (
  approvalUuid: string,
  status: "APPROVED" | "REJECTED",
  data: IProcedureData
) => axiosInstance.post(`/approval/${approvalUuid}/${status}`, data);

export const changeApprovalStatus = (
  approvalUuid: string,
  approvalStatus: IChangeApprovalStatus,
  statusComment?: string
) =>
  axiosInstance.post(`/approval/${approvalUuid}/` + approvalStatus, {
    statusComment,
  });

export const getInvoiceApprovals = (
  pagination: IPagination,
  search?: IApprovalSearch
) =>
  axiosInstance
    .get(`/invoice/approvals`, {
      params: {
        ...pagination,
        ...RequestUtils.replaceEmptyWithNull(search),
      },
    })
    .then(({ data }) => ({
      approvals: data.approvals as IApprovalNew[],
      totalRows: data.totalRows as number,
    }));

export const uploadApproval = (changedObject?: IChangedObject) =>
  axiosInstance.post("/approvals.xlsx", { changedObject });

export const updateApproval = (
  approvalUuid: string,
  data: IUpdateApprovalEntityData
) =>
  axiosInstance.patch("/admin/approval", {
    uuid: approvalUuid,
    ...data,
  });

export const changeApprovalProcessorInBulk = (approvals: { uuid: string }[]) =>
  axiosInstance.patch("/approval/change-processor-in-bulk", {
    approvals,
  });
