import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IApprovalNew, IApprovalSearch } from "../../types/IApprovals";
import {
  Button,
  clsx,
  deleteProps,
  formatDate,
  ITableColumn,
  ITableDataItem,
} from "@inceptionbg/ui-components";
import { Table } from "../CustomIUI/Table/Table";
import { Dialog } from "../../Elements/Dialog";
import { ApprovalsFilters } from "./ApprovalsFilters";
import { getInvoiceApprovals } from "../../repos/ApprovalsRepo";
import { defaultApprovalsSearch } from "../../Pages/ProceduresPage";
import { useAppSelector } from "../../redux/reduxHooks";

export const InvoiceApprovalsTable: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<IApprovalSearch>(defaultApprovalsSearch);
  const [searchData, setSearchData] = useState<IApprovalSearch>(
    defaultApprovalsSearch
  );
  const [processingApproval, setProcessingApproval] = useState<IApprovalNew>(
    {}
  );

  const { t } = useTranslation();
  const activeUserUuid = useAppSelector((state) => state.user.uuid);

  const hasWriteAccess = useAppSelector(
    (state) => state.user.privileges.APPROVAL_W
  );

  const handleGetApprovals = useCallback(() => {
    setIsLoading(true);
    getInvoiceApprovals({ limit, offset }, search)
      .then(({ approvals, totalRows }) => {
        setData(
          approvals.map((e) => ({
            uuid: e.uuid,
            cells: {
              approvalNumber: { value: e.approvalNumber || "" },
              farmNumberShort: { value: e.farm?.registrationNumber || "" },
              createdAt: { value: formatDate(e.createdAt) || "" },
              statusTime: { value: formatDate(e.statusTime) || "" },
              approvalStatus: {
                value: e.approvalStatus ? (
                  <p
                    className={clsx({
                      "color-warning": e.approvalStatus === "IN_PROGRESS",
                      "color-error": e.approvalStatus === "PENDING",
                      disabled:
                        e.approvalStatus === "REJECTED" ||
                        e.approvalStatus === "APPROVED",
                    })}
                  >
                    {t(`ApprovalStatus${e.approvalStatus}`)}
                  </p>
                ) : (
                  ""
                ),
              },
              actions: {
                value:
                  e.approvalStatus !== "REJECTED" ? (
                    <Button
                      label={t(
                        e.approvalStatus === "APPROVED"
                          ? "DetailsPreview"
                          : "DownloadForProcessing"
                      )}
                      onClick={() => setProcessingApproval(e)}
                      outlined
                      size={"s"}
                      disabled={
                        e.approvalStatus === "IN_PROGRESS" &&
                        !!activeUserUuid &&
                        activeUserUuid !== e.processor?.uuid
                      }
                    />
                  ) : (
                    ""
                  ),
              },
            },
          })) as ITableDataItem[]
        );
        setTotalRows(totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [limit, offset, search, t, activeUserUuid]);

  useEffect(handleGetApprovals, [handleGetApprovals]);

  const columns: ITableColumn[] = [
    { id: "approvalNumber", label: t("ApprovalNumber") },
    { id: "farmNumberShort", label: t("OrgNumberShort") },
    { id: "createdAt", label: t("RequestDate") },
    { id: "statusTime", label: t("StatusTime") },
    { id: "approvalStatus", label: t("Status") },
    { id: "actions", label: "", unavailable: !hasWriteAccess },
  ];

  const onSearch = (search: IApprovalSearch) => {
    setSearch(deleteProps(search, ["farmObj", "processorUser"]));
    setOffset(0);
  };

  return (
    <>
      <Table
        columns={columns}
        data={data}
        filterData={{
          columns: [
            "concluded",
            "approvalNumber",
            "farm",
            "farmRegistrationNumber",
            "holderTemporary",
            "approvalStatus",
            "creationDate",
            "statusTime",
            "processorUser",
            "holderPersonalIdentityNumber",
            "holderRegistrationNumber",
          ],
          filters: ApprovalsFilters(searchData, setSearchData),
          search,
          searchData,
          setSearchData,
          onSubmit: onSearch,
          resetData: defaultApprovalsSearch,
        }}
        isLoading={isLoading}
        serverSidePagination={{ limit, offset, setLimit, setOffset, totalRows }}
        footerAction={{
          onClick: handleGetApprovals,
        }}
      />
      <Dialog
        isOpen={!!processingApproval?.uuid}
        onClose={() => setProcessingApproval({})}
        title={t(
          processingApproval?.approvalStatus === "APPROVED"
            ? "DetailsPreviewTitle"
            : "DownloadForProcessingTitle"
        )}
        desc={t(
          processingApproval?.approvalStatus === "APPROVED"
            ? "DetailsPreviewDesc"
            : "DownloadForProcessingDesc"
        )}
        confirmButton={{
          label: t("Download"),
          onClick: () => {
            window.open(`/approval/${processingApproval.uuid}/edit`, "_blank");
          },
        }}
        cancelButton={{ onClick: () => setProcessingApproval({}) }}
      />
    </>
  );
};
