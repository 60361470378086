import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../Components/Sidebar";
import { RefractionRequestsTable } from "./RefractionRequestsTable";
import { NoAccessComponent } from "../../Components/Common/NoAccessComponent";
import { useAppSelector } from "../../redux/reduxHooks";
import { RefractionRequestSummaryReportWrapper } from "./Request/RefractionRequestSummaryReportWrapper";

export const RefractionRequestsPage: FC = () => {
  const { t } = useTranslation();
  const hasAccess = useAppSelector(
    (state) => state.user.privileges.REFRACTION_APPROVAL_R
  );

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`RefractionRequests`)}`}</h1>
      </div>
      <div>
        <div className="flex p-3">
          <Sidebar />
          <div className="flex column gap-4">
            <RefractionRequestSummaryReportWrapper />
            {hasAccess ? <RefractionRequestsTable /> : <NoAccessComponent />}
          </div>
        </div>
      </div>
    </div>
  );
};
