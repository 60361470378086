import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubSection } from "../../SubSection";
import { getHids } from "../../../repos/AnimalRepo";

interface Props {
  farmUuid: string;
}

export const AnimalHidSection: FC<Props> = ({ farmUuid }) => {
  const [hidsList, setHidsList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleGetHids = useCallback(() => {
    if (farmUuid) {
      setIsLoading(true);
      getHids(farmUuid)
        .then((hids) => setHidsList(hids))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [farmUuid]);

  useEffect(handleGetHids, [handleGetHids]);

  return (
    <SubSection
      title={t("AnimalHidsSection")}
      expandable
      isLoading={isLoading}
      initialyOpen={false}
    >
      {!!hidsList.length && (
        <div className="files-list">
          <p className="files-header">{t("AnimalHidsList")}:</p>
          <div className="list">
            {hidsList.map((hid) => (
              <div key={hid} className="file">
                <p>{hid}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </SubSection>
  );
};
