import { formatDate } from "@inceptionbg/ui-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { IChangedObject } from "../../types/IApprovals";
import { IPersonalInfoData } from "../../types/IFarm";

interface Props {
  infoData: IPersonalInfoData;
  changedActivity?: IChangedObject;
  isHolder?: boolean;
  isLegalRepresentative?: boolean;
  simpleDisplay?: boolean;
}

export const PersonalInfoForm: FC<Props> = ({
  infoData,
  changedActivity,
  isHolder,
  isLegalRepresentative,
  simpleDisplay = false,
}) => {
  const { t } = useTranslation();

  const formInfo: IInputComponentProps[] = [
    {
      label: t("PersonalIdentityNumber"),
      value: infoData.personalIdentityNumber,
    },
    {
      label: t("FirstName"),
      value: infoData.firstName,
    },
    {
      label: t("LastName"),
      value: infoData.lastName,
    },
    {
      label: t("BirthDate"),
      value: formatDate(infoData.birthDate),
      hidden: simpleDisplay,
    },
    {
      label: t("BirthPlace"),
      value: infoData.birthPlace,
      hidden: simpleDisplay,
    },
    {
      label: t("BirthCountry"),
      value: infoData.birthCountry?.name,
      hidden: changedActivity === "MEMBER" || simpleDisplay,
    },
    {
      label: t("Gender"),
      value: infoData.gender ? t(infoData.gender) : "/",
      hidden: simpleDisplay,
    },
    {
      label: t("WorkActivity"),
      value: infoData.workActivity?.name || "/",
      hidden:
        isLegalRepresentative ||
        changedActivity === "MEMBER" ||
        changedActivity === "LEGAL_REPRESENTATIVE" ||
        simpleDisplay,
    },
    {
      label: t("Education"),
      value: infoData.education?.name || "/",
      hidden:
        !isHolder ||
        isLegalRepresentative ||
        changedActivity === "MEMBER" ||
        changedActivity === "LEGAL_REPRESENTATIVE" ||
        simpleDisplay,
    },
    // Legal Representative
    {
      label: t("Street"),
      value: infoData.street,
      hidden: !isLegalRepresentative || simpleDisplay,
    },
    {
      label: t("NumberLetter"),
      value: infoData.houseNumber,
      hidden: !isLegalRepresentative || simpleDisplay,
    },
    {
      label: t("Place"),
      value: infoData.place,
      hidden: !isLegalRepresentative || simpleDisplay,
    },
    {
      label: t("Municipality"),
      value: infoData.municipality,
      hidden: !isLegalRepresentative || simpleDisplay,
    },
    {
      label: t("PostalCode"),
      value: infoData.postalCode,
      hidden: !isLegalRepresentative || simpleDisplay,
    },
    {
      label: t("Status"),
      value: t(infoData.temporary ? "Temporary" : "Permanently"),
      hidden:
        !isHolder ||
        typeof infoData.temporary !== "boolean" ||
        infoData.type === "legalEntity" ||
        simpleDisplay,
    },
  ];

  return (
    <div className="form-container">
      {formInfo.map((e) => !e.hidden && <Input key={e.label} component={e} />)}
    </div>
  );
};
