import { Button, Dialog } from "@inceptionbg/ui-components";
import { FC, useState } from "react";
import { SubSection } from "../../../Components/SubSection";
import { RefractionRequestSummaryReport } from "./RefractionRequestSummaryReport";
import { t } from "i18next";

export const RefractionRequestSummaryReportWrapper: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SubSection title={t("RefractionRequestSummaryReport")}>
      <Button
        label={t("ShowReport")}
        onClick={() => setIsOpen(true)}
        outlined
      />
      <Dialog
        title={t("RefractionRequestSummaryReport")}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <RefractionRequestSummaryReport />
      </Dialog>
    </SubSection>
  );
};
