import { FC, useEffect, useState } from "react";
import { SubSection } from "../SubSection";
import { IBankAccount } from "../../types/IFarm";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { TextInput } from "@inceptionbg/ui-components";
import { inputPattern } from "../../utils/InputPatternValidation";
import { Form } from "../Wrapper/FormWrapper";
import { IUpdateApprovalEntityData } from "../../types/IApprovals";

interface Props {
  bankAccount: IBankAccount;
  isLoading?: boolean;
  editable?: boolean;
  initiallyOpen?: boolean;
  onSubmit?: (data: IUpdateApprovalEntityData) => void;
  isChild?: boolean;
}

export const BankAccount: FC<Props> = ({
  bankAccount = {},
  isLoading,
  editable,
  initiallyOpen,
  onSubmit,
  isChild = false,
}) => {
  const [accountNumber, setAccountNumber] = useState("");
  const [edit, setEdit] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setAccountNumber(bankAccount?.accountNumber || "");
  }, [bankAccount]);

  const formInfo: IInputComponentProps[] = [
    {
      label: t("AccountNumber"),
      labelHelper: t("AccountNumberHelper"),
      value: accountNumber,
      input: (
        <TextInput
          value={accountNumber}
          setValue={setAccountNumber}
          required
          inputProps={{ pattern: inputPattern.bankAccount }}
          autoFocus={edit}
          error={!accountNumber}
        />
      ),
    },
    {
      label: t("BankName"),
      value: bankAccount.bank?.name || "",
    },
  ];

  return (
    <SubSection
      title={t(isChild ? "ChildFarmBankAccountData" : "FarmBankAccountData")}
      expandable={true}
      initialyOpen={!!initiallyOpen}
    >
      <div className="form-container">
        {!editable ? (
          formInfo.map(
            (e) => !e.hidden && <Input key={e.label} component={e} />
          )
        ) : (
          <Form
            data={formInfo}
            edit={edit}
            setEdit={setEdit}
            submitButton={{
              editLabel: t(edit ? "Save" : "Edit"),
              onSubmit: () => {
                setEdit(false);
                onSubmit && onSubmit({ bankAccount: { accountNumber } });
              },
            }}
            onResetForm={() =>
              setAccountNumber(bankAccount?.accountNumber || "")
            }
            isLoading={!!isLoading}
          />
        )}
      </div>
    </SubSection>
  );
};
