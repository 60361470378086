import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IFarm } from "../../types/IFarm";
import { Section } from "../Section";
import { FarmInfo } from "./SubSections/FarmInfo";
import { SubSection } from "../SubSection";
import { PersonalInfoForm } from "../Common/PersonalInfoForm";
import { LegalEntityInfo } from "../Procedures/LegalEntityInfo";
import { HeadOffice } from "../Procedures/HeadOffice";
import { BankAccount } from "../Procedures/BankAccount";
import { ContactInfo } from "./SubSections/ContactInfo";
import { LegalRepresentative } from "../Procedures/LegalRepresentative";
import { IUpdateApprovalEntityData } from "../../types/IApprovals";
import { updateApproval } from "../../repos/ApprovalsRepo";

interface Props {
  farm: IFarm;
  approvalUuid?: string;
  editable?: boolean;
  refreshApproval?: () => void;
}

export const BasicInfoSection: FC<Props> = ({
  farm,
  approvalUuid,
  editable = false,
  refreshApproval,
}) => {
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const { t } = useTranslation();
  const holderType = farm?.holder?.type;

  const onApprovalUpdate = (data: IUpdateApprovalEntityData) => {
    setIsUpdateLoading(true);
    !!approvalUuid &&
      updateApproval(approvalUuid, data)
        .then(refreshApproval)
        .catch(() => {})
        .finally(() => setIsUpdateLoading(false));
  };

  return (
    <Section id="basic" title={t("BasicInfoTitle")}>
      <div className="form-container">
        <FarmInfo farm={farm} />
        {farm && (
          <>
            {holderType === "physicalPerson" && (
              <SubSection
                title={t("ArgHolderInfo")}
                expandable={true}
                initialyOpen={false}
              >
                <PersonalInfoForm isHolder infoData={farm.holder} />
              </SubSection>
            )}
            {holderType === "legalEntity" && (
              <LegalEntityInfo legalEntity={farm.holder} />
            )}
            <HeadOffice
              headOffice={farm.headOffice}
              isLoading={isUpdateLoading}
              editable={editable}
              onSubmit={onApprovalUpdate}
            />
            <ContactInfo farm={farm} />
            {!!farm.bankAccount && (
              <BankAccount
                bankAccount={farm.bankAccount}
                isLoading={isUpdateLoading}
                editable={editable}
                onSubmit={onApprovalUpdate}
              />
            )}
            {holderType === "legalEntity" && !!farm.legalRepresentative && (
              <LegalRepresentative
                legalRepresentative={farm.legalRepresentative}
              />
            )}
          </>
        )}
      </div>
    </Section>
  );
};
