import { IAnyObject, TextInput } from "@inceptionbg/ui-components";
import { Dispatch, FC, SetStateAction } from "react";

interface Props {
  formData: IAnyObject;
  setFormData: Dispatch<SetStateAction<IAnyObject>>;
  value: string;
  required?: boolean;
  error?: boolean;
}

export const SurfaceInput: FC<Props> = ({
  formData,
  setFormData,
  value,
  required,
  error
}) => {
  return (
    <div className="flex gap-1 full-width">
      {/* @ts-ignore */}
      <div className="surface-input" dataunit="ha">
        <TextInput
          required={required}
          value={`${formData[value]?.ha}`}
          inputProps={{
            type: "number",
            min: 0,
            onClick: (e) => e.currentTarget.select(),
          }}
          setValue={(e) =>
            setFormData({
              ...formData,
              [value]: {
                a: formData[value]?.a || 0,
                m: formData[value]?.m || 0,
                ha: e,
              },
            })
          }
          error={error}
        />
      </div>
      {/* @ts-ignore */}
      <div className="surface-input a" dataunit="a">
        <TextInput
          required={required}
          value={`${formData[value]?.a}`}
          inputProps={{
            type: "number",
            min: 0,
            max: 99,
            onClick: (e) => e.currentTarget.select(),
          }}
          setValue={(e) =>
            setFormData({
              ...formData,
              [value]: {
                ha: formData[value]?.ha || 0,
                m: formData[value]?.m || 0,
                a: e,
              },
            })
          }
          error={error}
        />
      </div>
      {/* @ts-ignore */}
      <div className="surface-input" dataunit="m²">
        <TextInput
          required={required}
          value={`${formData[value]?.m}`}
          inputProps={{
            type: "number",
            min: 0,
            max: 99,
            onClick: (e) => e.currentTarget.select(),
          }}
          setValue={(e) =>
            setFormData({
              ...formData,
              [value]: {
                ha: formData[value]?.ha || 0,
                a: formData[value]?.a || 0,
                m: e,
              },
            })
          }
          error={error}
        />
      </div>
    </div>
  );
};
