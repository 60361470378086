import { axiosInstance } from "../App";
import {
  FarmFetchOption,
  IFarm,
  IFarmDataChange,
  IFarmSearch,
  IFarmStatusChange,
  IRenewalCondition,
} from "../types/IFarm";
import { IPagination } from "@inceptionbg/ui-components";
import { IOpenCase } from "../types/IOpenCase";
import { ISummaryReport } from "../types/IReport";
import { IHolderMemberDataChange } from "../types/IHolderMemberDataChange";
import { IFarmStatusHistory } from "../types/IFarmStatusHistory";

export const getFarmByRegistrationNumber = (
  registrationNumber: string,
  filters?: {
    fetchOptions?: FarmFetchOption[];
  }
) =>
  axiosInstance
    .get(`/admin/farm/${registrationNumber}`, {
      params: filters,
    })
    .then(({ data }) => ({ farm: data as IFarm }));

export const getFarms = (search: IFarmSearch, pagination: IPagination) =>
  axiosInstance
    .get(`/admin/farms`, { params: { ...search, ...pagination } })
    .then(({ data }) => ({
      farms: data.farms as IFarm[],
      totalRows: data.totalRows as number,
    }));

export const getFarmRenewalConditions = (farmUuid: string) =>
  axiosInstance
    .get(`/admin/farm/${farmUuid}/renewal-condition`)
    .then(({ data }) => data as IRenewalCondition);

export const changeFarmStatus = (regNum: string, data: IFarmStatusChange) =>
  axiosInstance.post(`/admin/farm/${regNum}/status`, data);

export const changeFarmData = (regNum: string, data: IFarmDataChange) =>
  axiosInstance.patch(`/admin/farm/${regNum}`, data);

export const openAdminCase = (data: IOpenCase) =>
  axiosInstance.post(`/admin/case`, data);

export const getFarmReports = () =>
  axiosInstance
    .get(`/admin/farms/summary-report`)
    .then(({ data }) => data as ISummaryReport);

export const changeHolderMemberData = (
  regNum: string,
  data: IHolderMemberDataChange
) => axiosInstance.patch(`/admin/farm/${regNum}`, data);

export const changeFarmStatusExcel = (file: File) => {
  const formData = new FormData();
  formData.append("excelFile", file);

  return axiosInstance
    .post(`/admin/farm/status-change/import-excel`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        noToast: true,
      },
    })
    .then(({ data }) => data);
};

export const getFarmHistoryAtDay = (farmUuid: string, atDay: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/history-at-day`, { params: { atDay } })
    .then(({ data }) => data as IFarm);

export const getFarmStatusHistory = (farmUuid: string) =>
  axiosInstance
    .get("/admin/farm/status-changes", { params: { farmUuid } })
    .then(({ data }) => ({
      farmStatusChanges: data.farmStatusChanges as IFarmStatusHistory[],
    }));
