import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { Button, TextInput } from "@inceptionbg/ui-components";
import { IGasStation } from "../../types/IGasStation";
import { inputPattern } from "../../utils/InputPatternValidation";

interface Props {
  data?: Partial<IGasStation>;
  setData: Dispatch<SetStateAction<Partial<IGasStation>>>;
  onSubmit: () => void;
}

export const GasStationForm: FC<Props> = ({ data, setData, onSubmit }) => {
  const { t } = useTranslation();

  const formInfo: IInputComponentProps[] = [
    {
      label: t("Name"),
      value: data?.name || "",
      input: (
        <TextInput
          required
          value={data?.name || ""}
          setValue={(e) => setData({ ...data, name: e })}
        />
      ),
    },
    {
      label: t("TaxId"),
      input: (
        <TextInput
          required
          value={data?.taxId || ""}
          setValue={(e) => setData({ ...data, taxId: e })}
          inputProps={{ pattern: inputPattern.taxId }}
        />
      ),
    },
  ];

  return (
    <form
      className="form-container"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {formInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} edit />
      )}
      <div className="flex justify-right">
        <Button label={t("Save")} size="s" className="primary" primary />
      </div>
    </form>
  );
};
