import { FC } from "react";
import { ISurfaceObj } from "../../../types/ILand";

interface Props {
  valueObj?: ISurfaceObj;
  simple?: boolean;
  isRecapitulation?: boolean;
}

const zeroValue = (e?: string) => !!e && +e === 0;

export const SurfaceValue: FC<Props> = ({
  valueObj,
  simple,
  isRecapitulation,
}) =>
  isRecapitulation ? (
    <>
      {zeroValue(valueObj?.ha) ? "" : valueObj?.ha}{" "}
      {zeroValue(valueObj?.a)
        ? zeroValue(valueObj?.ha)
          ? ""
          : "00"
        : (!zeroValue(valueObj?.ha) && valueObj?.a?.length === 1 ? "0" : "") +
          valueObj?.a}{" "}
      {(!zeroValue(valueObj?.ha) &&
      !zeroValue(valueObj?.a) &&
      valueObj?.m?.length === 1
        ? "0"
        : "") + valueObj?.m}
    </>
  ) : simple ? (
    <>
      {valueObj?.ha || "0"}ha{" "}
      {valueObj?.a ? (valueObj.a.length === 1 ? "0" : "") + valueObj.a : "00"}a{" "}
      {valueObj?.m ? (valueObj.m.length === 1 ? "0" : "") + valueObj.m : "00"}m²
    </>
  ) : (
    <div className="flex justify-between full-width">
      <p>{valueObj?.ha || "0"} ha</p>
      <p>{valueObj?.a || "00"} a</p>
      <p>{valueObj?.m || "00"} m²</p>
    </div>
  );
