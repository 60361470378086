import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { IAdminUser } from "../../types/IUser";
import { Button, deleteProps, TextInput } from "@inceptionbg/ui-components";
import { getAdminUserRoles } from "../../utils/LoadOptions";
import { SelectAsyncPaginate } from "../../Components/Inputs/SelectAsyncPaginate";
import { Alert } from "../../Elements/Alert/Alert";

interface Props {
  data?: IAdminUser;
  setData: Dispatch<SetStateAction<IAdminUser>>;
  onSubmit: () => void;
}

export const AdminUserForm: FC<Props> = ({ data, setData, onSubmit }) => {
  const { t } = useTranslation();

  const formInfo: IInputComponentProps[] = [
    {
      label: t("FirstName"),
      value: data?.firstName || "",
      input: (
        <TextInput
          required
          value={data?.firstName || ""}
          setValue={(e) => setData({ ...data, firstName: e })}
        />
      ),
    },
    {
      label: t("LastName"),
      value: data?.lastName || "",
      input: (
        <TextInput
          required
          value={data?.lastName || ""}
          setValue={(e) => setData({ ...data, lastName: e })}
        />
      ),
    },
    {
      label: t("PersonalIdentityNumber"),
      value: data?.umcn || "",
      input: (
        <TextInput
          required
          value={data?.umcn || ""}
          setValue={(e) => setData({ ...data, umcn: e })}
        />
      ),
    },
    {
      label: t("Email"),
      value: data?.email || "",
      input: (
        <TextInput
          required
          value={data?.email || ""}
          setValue={(e) => setData({ ...data, email: e })}
        />
      ),
    },
    {
      label: t("Role"),
      value: data?.role?.name || "",
      input: (
        <SelectAsyncPaginate
          className="select-paginate select-popup-wide"
          required={!data?.uuid}
          menuWidth="fit-content"
          placeholder={`${t("Choose")}...`}
          value={
            data?.role
              ? {
                  value: data.role.uuid,
                  label: data.role.name,
                }
              : null
          }
          loadOptions={getAdminUserRoles}
          onChange={(e) => {
            setData(
              e
                ? {
                    ...data,
                    roleUuid: e.value,
                    role: {
                      uuid: e.value,
                      name: e.label,
                    },
                  }
                : deleteProps(data, ["roleUuid", "role"])
            );
          }}
        />
      ),
    },
  ];

  return (
    <form
      className="form-container"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {formInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} edit />
      )}
      {!!data?.uuid && !data?.role && (
        <Alert text={t("RoleIsMissingDesc")} type={"info"} />
      )}
      <div className="flex justify-right">
        <Button label={t("Save")} size="s" className="primary" primary />
      </div>
    </form>
  );
};
