import { ISelectData } from "@inceptionbg/ui-components";
import { FC, useRef, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { InputWrapper } from "../Wrapper/InputWrapper";
import { createSelectStyles } from "./Select";

interface Props {
  value?: ISelectData | null;
  valuesMulti?: ISelectData[];
  loadOptions: any;
  refresh?: any[];
  onChange: (value: any) => void;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  menuWidth?: "fit-content" | "max-content" | number;
  autoFocus?: boolean;
  isMulti?: boolean;
  isOpen?: boolean;
  openDirection?: "top" | "auto" | "bottom";
  noOptionsMessage?: string;
  error?: boolean;
}

export const SelectAsyncPaginate: FC<Props> = ({
  value,
  valuesMulti,
  loadOptions,
  refresh,
  onChange,
  required,
  disabled,
  placeholder = "",
  className,
  menuWidth,
  autoFocus,
  isMulti,
  isOpen,
  openDirection = "auto",
  noOptionsMessage,
  error,
}) => {
  const [focus, setFocus] = useState(false);
  const ref = useRef<any>(null);
  return (
    <InputWrapper focus={focus} className={className} error={error}>
      <AsyncPaginate
        value={value || valuesMulti || null}
        loadOptions={loadOptions}
        additional={{ page: 0 }}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
        styles={createSelectStyles(menuWidth)}
        isDisabled={disabled}
        isClearable
        isMulti={isMulti}
        menuIsOpen={isOpen}
        menuPlacement={openDirection}
        menuPortalTarget={document.body}
        selectRef={ref}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        autoFocus={autoFocus}
        noOptionsMessage={() => noOptionsMessage || "Nema opcija"}
        cacheUniqs={refresh || undefined}
      />
      {required && !disabled && (
        <input
          className="fake-input"
          required
          tabIndex={-1}
          autoComplete="off"
          value={value?.value || valuesMulti?.length || ""}
          onChange={() => {}}
          onFocus={() => ref.current?.focus()}
        />
      )}
    </InputWrapper>
  );
};
