import { FC, useEffect, useState } from "react";
import { SubSection } from "../SubSection";
import { IUpdateApprovalEntityData } from "../../types/IApprovals";
import { IHeadOfficeNew } from "../../types/IFarm";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { TextInput } from "@inceptionbg/ui-components";
import { Form } from "../Wrapper/FormWrapper";

interface Props {
  headOffice: IHeadOfficeNew;
  isLoading?: boolean;
  editable?: boolean;
  initiallyOpen?: boolean;
  onSubmit?: (data: IUpdateApprovalEntityData) => void;
  isChild?: boolean;
}

export const HeadOffice: FC<Props> = ({
  headOffice = {},
  isLoading,
  editable,
  initiallyOpen,
  onSubmit,
  isChild = false,
}) => {
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [edit, setEdit] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setStreet(headOffice?.street || "");
    setHouseNumber(headOffice?.houseNumber || "");
  }, [headOffice]);

  const headOfficeInfo: IInputComponentProps[] = [
    {
      label: t("Street"),
      value: street,
      input: (
        <TextInput
          required
          value={street}
          setValue={setStreet}
          error={!street}
        />
      ),
    },
    {
      label: t("NumberLetter"),
      value: houseNumber,
      input: (
        <TextInput
          required
          value={houseNumber}
          setValue={setHouseNumber}
          error={!houseNumber}
        />
      ),
    },
    {
      label: t("Place"),
      value: headOffice.place?.name,
    },
    {
      label: t("Municipality"),
      value: headOffice.municipality,
    },
    {
      label: t("PostalCode"),
      value: headOffice.postalCode,
    },
  ];

  return (
    <SubSection
      title={t(isChild ? "ChildFarmHeadOfficeData" : "FarmHeadOfficeData")}
      expandable={true}
      initialyOpen={!!initiallyOpen}
    >
      <div className="form-container">
        {!editable ? (
          headOfficeInfo.map(
            (e) => !e.hidden && <Input key={e.label} component={e} />
          )
        ) : (
          <Form
            data={headOfficeInfo}
            edit={edit}
            setEdit={setEdit}
            submitButton={{
              editLabel: t(edit ? "Save" : "Edit"),
              onSubmit: () => {
                setEdit(false);
                onSubmit && onSubmit({ headOffice: { street, houseNumber } });
              },
            }}
            onResetForm={() => {
              setStreet(headOffice?.street || "");
              setHouseNumber(headOffice?.houseNumber || "");
            }}
            isLoading={!!isLoading}
          />
        )}
      </div>
    </SubSection>
  );
};
