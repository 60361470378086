import {
  deleteProps,
  ITableFilter,
  TextInput,
} from "@inceptionbg/ui-components";
import { t } from "i18next";
import { IPlantProtectionProductSearch } from "../../types/IPlantProtectionProduct";

export const PlantProtectionProductFilters: (
  search: IPlantProtectionProductSearch,
  setSearch: (search: IPlantProtectionProductSearch) => void
) => ITableFilter = (search, setSearch) => ({
  name: {
    label: t("Name"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.fullText || ""}
        setValue={(fullText) => setSearch({ ...search, fullText })}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["fullText"])),
  },
});
