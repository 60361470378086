import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../Section";
import { getApprovals } from "../../../repos/ApprovalsRepo";
import { IApprovalNew } from "../../../types/IApprovals";
import { FarmApproval } from "./FarmApproval";

interface Props {
  farmUuid: string;
}

export const FarmApprovalsSection: FC<Props> = ({ farmUuid }) => {
  const [approvals, setApprovals] = useState<IApprovalNew[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const { t } = useTranslation();

  const handleGetApprovals = useCallback(() => {
    if (farmUuid) {
      setIsLoading(true);
      getApprovals(
        { limit: 20, offset },
        {
          farmUuid: farmUuid,
          concluded: false,
        }
      )
        .then(({ approvals, totalRows }) => {
          setApprovals((prevState) => {
            const newState = [...prevState, ...approvals];
            totalRows > newState.length && setOffset(offset + 1);
            return newState;
          });
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [farmUuid, offset]);

  useEffect(handleGetApprovals, [handleGetApprovals]);

  return (
    <Section id="farmApprovals" title={t("FarmApprovals")} className="mt-3 p-0">
      <div className="form-container">
        {!isLoading &&
          approvals.map((approval) => (
            <FarmApproval key={approval.uuid} approval={approval} />
          ))}
      </div>
    </Section>
  );
};
