import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { ILand } from "../../types/ILand";
import { Checkbox } from "../../Elements/Checkbox";
import { convertSurfaceToObj } from "../../utils/CalculateUtils";

interface Props {
  isOpen: boolean;
  lands: ILand[];
  onSubmit: (excludeLands: { uuid: string }[]) => void;
  onClose: () => void;
}

export const LandsPartialConfirmationDialog: FC<Props> = ({
  isOpen,
  lands = [],
  onClose,
  onSubmit,
}) => {
  const [selected, setSelected] = useState(new Set<string>());

  const { t } = useTranslation();

  const handleSubmit = () => {
    const excludeLands = lands
      .filter((item) => !selected.has(item.uuid))
      .map((item) => ({ uuid: item.uuid }));
    onSubmit(excludeLands);
    handleClose();
  };

  const handleClose = () => {
    setSelected(new Set());
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={t("PartialConfirmationLandsTitle")}
      desc={t("PartialConfirmationLandsDesc") + ":"}
      cancelButton={{
        label: t("Cancel"),
        onClick: handleClose,
        hideIcon: true,
      }}
      confirmButton={{ label: t("Confirm"), onClick: handleSubmit }}
    >
      {lands.map((land) => {
        const surfaceObj = convertSurfaceToObj(land.surface);

        return (
          <Checkbox
            key={land.uuid}
            label={
              <div className="flex ml-2">
                <p>{land.cadastreMunicipality.name}</p>
                <p className="ml-3">
                  {t("CadastreNumberShort")} ${land.cadastreNumber}
                </p>
                <p className="ml-3">
                  {surfaceObj?.ha || 0}ha {surfaceObj?.a || 0}a{" "}
                  {surfaceObj?.m || 0}m²
                </p>
              </div>
            }
            selected={selected.has(land.uuid)}
            setSelected={(oldValue) => {
              setSelected((oldSet) => {
                let newSet = new Set(oldSet);
                if (oldValue) newSet.add(land.uuid);
                else newSet.delete(land.uuid);
                return newSet;
              });
            }}
          />
        );
      })}
    </Dialog>
  );
};
