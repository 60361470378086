import { FC } from "react";
import { SubSection } from "../SubSection";
import { PersonalInfoForm } from "../Common/PersonalInfoForm";
import { ILegalRepresentativeNew } from "../../types/IFarm";
import { IChangedObject } from "../../types/IApprovals";
import { useTranslation } from "react-i18next";

interface Props {
  legalRepresentative: ILegalRepresentativeNew;
  initiallyOpen?: boolean;
  changedObject?: IChangedObject;
  simpleDisplay?: boolean;
  isChild?: boolean;
}

export const LegalRepresentative: FC<Props> = ({
  legalRepresentative = {},
  initiallyOpen,
  changedObject,
  simpleDisplay,
  isChild = false,
}) => {
  const { t } = useTranslation();
  return (
    <SubSection
      title={t(
        isChild ? "ChildLegalRepresentativeInfo" : "LegalRepresentativeInfo"
      )}
      expandable={true}
      initialyOpen={initiallyOpen}
    >
      <PersonalInfoForm
        infoData={legalRepresentative}
        changedActivity={changedObject}
        simpleDisplay={simpleDisplay}
      />
    </SubSection>
  );
};
