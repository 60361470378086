import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { TextInput } from "@inceptionbg/ui-components";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (statusComment?: string) => void;
}

export const ChangeApprovalStatusCommentDialog: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [statusComment, setStatusComment] = useState("");
  const { t } = useTranslation();

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("ChangeApprovalStatusToWaitingComplementComment")}
      confirmButton={{ onFormSubmit: () => onSubmit(statusComment) }}
    >
      <TextInput required value={statusComment} setValue={setStatusComment} />
    </Dialog>
  );
};
