import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import { useAppSelector } from "../redux/reduxHooks";
import { FarmRenewalDateChange } from "../Components/Sections/SubSections/FarmDataChanges/FarmRenewalDateChange";
import { FarmLegalEntityChange } from "../Components/Sections/SubSections/FarmDataChanges/FarmLegalEntityChange";
import { FarmPersonalIdentityNumberChange } from "../Components/Sections/SubSections/FarmDataChanges/FarmPersonalIdentityNumberChange";
import { NoAccessComponent } from "../Components/Common/NoAccessComponent";

export const FarmDataChanges: FC = () => {
  const { t } = useTranslation();
  const privileges = useAppSelector((state) => state.user.privileges);
  const hasChangePrivilege =
    privileges.RENEWAL_DATE_CHANGE ||
    privileges.PERSONAL_IDENTITY_NUMBER_CHANGE ||
    privileges.LEGAL_ENTITY_CHANGE;

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`FarmDataChange`)}`}</h1>
      </div>
      <div className="flex p-3">
        <Sidebar />
        {hasChangePrivilege ? (
          <div>
            {privileges.RENEWAL_DATE_CHANGE && <FarmRenewalDateChange />}
            {privileges.PERSONAL_IDENTITY_NUMBER_CHANGE && (
              <FarmPersonalIdentityNumberChange />
            )}
            {privileges.LEGAL_ENTITY_CHANGE && <FarmLegalEntityChange />}
          </div>
        ) : (
          <NoAccessComponent />
        )}
      </div>
    </div>
  );
};
