import {
  Button,
  ITableColumn,
  ITableDataItem,
  formatDateAndTime,
} from "@inceptionbg/ui-components";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createPlantProtectionProduct,
  deletePlantProtectionProduct,
  getPlantProtectionProducts,
} from "../../repos/PlantProtectionProductRepo";
import { Table } from "../../Components/CustomIUI/Table/Table";
import {
  IPlantProtectionProduct,
  IPlantProtectionProductSearch,
} from "../../types/IPlantProtectionProduct";
import { Dialog } from "../../Elements/Dialog";
import { PlantProtectionProductForm } from "./PlantProtectionProductForm";
import { PlantProtectionProductFilters } from "./PlantProtectionProductFilters";

export const PlantProtectionProductTable: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [details, setDetails] = useState<Partial<IPlantProtectionProduct>>({});

  const [search, setSearch] = useState<IPlantProtectionProductSearch>({});
  const [searchData, setSearchData] = useState<IPlantProtectionProductSearch>(
    {}
  );

  const { t } = useTranslation();

  const columns: ITableColumn[] = [
    { id: "name", label: t("Name") },
    { id: "manufacturerName", label: t("Producer") },
    { id: "category", label: t("Category") },
    { id: "createdAt", label: t("CreatedAt") },
    { id: "actions", label: t("Actions"), align: "center" },
  ];

  const handleGetPlantProtectionProducts = useCallback(() => {
    setIsLoading(true);
    getPlantProtectionProducts({ limit, offset }, search)
      .then(({ plantProtectionProducts }) => {
        setData(
          plantProtectionProducts.map((plantProtectionProduct) => ({
            uuid: plantProtectionProduct.uuid,
            cells: {
              name: {
                value: plantProtectionProduct.name,
              },
              manufacturerName: {
                value: plantProtectionProduct.manufacturerName,
              },
              category: {
                value: plantProtectionProduct.category,
              },
              createdAt: {
                value: formatDateAndTime(plantProtectionProduct.createdAt),
              },
              actions: {
                value: (
                  <Button
                    label={t("Delete")}
                    onClick={() => {
                      setIsConfirmDialogOpen(true);
                      setDetails(plantProtectionProduct);
                    }}
                    outlined
                    size={"s"}
                  />
                ),
              },
            },
          })) as ITableDataItem[]
        );
        setTotalRows(totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [totalRows, limit, offset, t, search]);

  useEffect(handleGetPlantProtectionProducts, [
    handleGetPlantProtectionProducts,
  ]);

  const onSubmit = () => {
    setIsLoading(true);
    createPlantProtectionProduct(details)
      .then(() => {
        handleGetPlantProtectionProducts();
        setDetails({});
        setIsFormOpen(false);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const onDelete = () => {
    if (details.uuid) {
      setIsLoading(true);
      deletePlantProtectionProduct(details.uuid)
        .then(() => {
          handleGetPlantProtectionProducts();
          setDetails({});
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  const onSearch = (search: IPlantProtectionProductSearch) => setSearch(search);

  return (
    <>
      <div className="flex justify-right">
        <Button
          label={t("AddPlantProtectionProduct")}
          size="s"
          primary
          onClick={() => setIsFormOpen(true)}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        serverSidePagination={{
          limit,
          offset,
          setLimit,
          setOffset,
          totalRows,
        }}
        filterData={{
          columns: ["name"],
          filters: PlantProtectionProductFilters(searchData, setSearchData),
          search,
          searchData,
          setSearchData,
          onSubmit: onSearch,
        }}
        footerAction={{
          onClick: handleGetPlantProtectionProducts,
        }}
      />
      <Dialog
        className="over"
        isOpen={isFormOpen}
        onClose={() => {
          setDetails({});
          setIsFormOpen(false);
        }}
        desc={t("AddPlantProtectionProduct")}
        children={
          <PlantProtectionProductForm
            data={details}
            setData={setDetails}
            onSubmit={onSubmit}
          />
        }
        cancelButton={{
          onClick: () => {
            setDetails({});
            setIsFormOpen(false);
          },
        }}
      />
      <Dialog
        isOpen={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        title={t("PlantProtectionProduct")}
        desc={t("PlantProtectionProductDesc")}
        isLoading={isLoading}
        confirmButton={{
          label: t("Yes"),
          onClick: onDelete,
        }}
        cancelButton={{
          label: t("No"),
          onClick: () => setIsConfirmDialogOpen(false),
        }}
      />
    </>
  );
};
