import { axiosInstance } from "../App";
import { IAnimal, IAnimalCountTotals } from "../types/IAnimal";

export const getAnimals = (farmUuid: string) =>
  axiosInstance
    .get(`/farm/${farmUuid}/animals`, {
      params: { limit: 1000 },
    })
    .then(({ data }) => ({
      animals: data.animals as IAnimal[],
      totals: {
        animalCountTotal: data.animalCountTotal,
        animalCountOrganicTotal: data.animalCountOrganicTotal,
        animalCountConventionalConTotal: data.animalCountConventionalConTotal,
      } as IAnimalCountTotals,
    }));

////// HID //////
export const getHids = (farmUuid: string) =>
  axiosInstance
    .get(`/admin/farm/${farmUuid}/animal/hids`)
    .then(({ data }) => (data.temporaryHIDS || []) as string[]);
