import { FC, ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import { PageWrapper } from "./Components/Wrapper/PageWrapper";
import { ProceduresPage } from "./Pages/ProceduresPage";
import { ProcedureEditPage } from "./Pages/ProcedureEditPage";
import { FarmsRegisterPage } from "./Pages/FarmsRegisterPage";
import { FarmDetailsPage } from "./Pages/FarmDetailsPage";
import { LandReportsPage } from "./Pages/LandReportsPage";
import { FarmStatusChange } from "./Pages/FarmStatusChange";
import { FarmDataChanges } from "./Pages/FarmDataChanges";
import { OpenCasePage } from "./Pages/OpenCasePage";
import { AdminUsersPage } from "./Pages/AdminUsers/AdminUsersPage";
import { ReportPage } from "./Pages/ReportPage";
import { HolderMemberDataChanges } from "./Pages/HolderMemberDataChanges";
import { UploadDocumentPage } from "./Pages/UploadDocumentPage";
import { RefractionRequestsPage } from "./Pages/RefractionRequests/RefractionRequestsPage";
import { HomePage } from "./Pages/HomePage";
import { GasStationsPage } from "./Pages/GasStations/GasStationsPage";
import { GasStationProductsPage } from "./Pages/GasStations/Products/GasStationProductsPage";
import { PesticideProcurementsPage } from "./Pages/PesticideProcurementsPage";
import { PlantProtectionProductsPage } from "./Pages/PlantProtectionProduct/PlantProtectionProductsPage";
import { ApprovalsGroupedPage } from "./Pages/ApprovalsGrouped/ApprovalsGroupedPage";
import { ApprovalsByGroupPage } from "./Pages/ApprovalsGrouped/ApprovalsByGroupPage";

interface IRoute {
  path?: string;
  element?: ReactElement;
  noSidebar?: boolean;
  childrenRoutes?: IRoute[];
}

const routes: IRoute[] = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/approvals-grouped",
    element: <ApprovalsGroupedPage />,
  },
  {
    path: "/farm/:farmUuid/approvals-by-group/:farmRegistrationNumber/:count/:processingStarted",
    element: <ApprovalsByGroupPage />,
  },
  {
    path: "/non-administrative-procedures",
    element: <ProceduresPage mode={"NON_ADMINISTRATIVE_PROCEDURES"} />,
  },
  {
    path: "/administrative-procedures",
    element: <ProceduresPage mode={"ADMINISTRATIVE_PROCEDURES"} />,
  },
  {
    path: "/invoices",
    element: <ProceduresPage mode={"INVOICES"} />,
  },
  { path: "/approval/:approvalUuid/edit", element: <ProcedureEditPage /> },
  { path: "/refraction-requests", element: <RefractionRequestsPage /> },
  { path: "/farms-register", element: <FarmsRegisterPage /> },
  { path: "/farm-status-change", element: <FarmStatusChange /> },
  { path: "/farm-data-change", element: <FarmDataChanges /> },
  { path: "/holder-member-data-change", element: <HolderMemberDataChanges /> },
  { path: "/farm/:registrationNumber/details", element: <FarmDetailsPage /> },
  { path: "/land-reports", element: <LandReportsPage /> },
  { path: "/document-upload", element: <UploadDocumentPage /> },
  { path: "/open-case-page", element: <OpenCasePage /> },
  { path: "/admin-users", element: <AdminUsersPage /> },
  { path: "/reports", element: <ReportPage /> },
  { path: "/gas-stations", element: <GasStationsPage /> },
  {
    path: "/plant-protection-products",
    element: <PlantProtectionProductsPage />,
  },
  {
    path: "/gas-station/:gasStationUuid/products",
    element: <GasStationProductsPage />,
  },
  {
    path: "/pesticides-procurement",
    element: <PesticideProcurementsPage />,
  },
];

export const Router: FC = () => (
  <Routes>
    {routes.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        index={!route.path}
        element={<PageWrapper>{route.element}</PageWrapper>}
      />
    ))}
  </Routes>
);
