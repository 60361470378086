import {
  deleteProps,
  ITableFilter,
  TextInput,
} from "@inceptionbg/ui-components";
import { t } from "i18next";
import { IGasStationProductSearch } from "../../../types/IGasStation";

export const GasStationProductsFilters: (
  search: IGasStationProductSearch,
  setSearch: (search: IGasStationProductSearch) => void
) => ITableFilter = (search, setSearch) => ({
  name: {
    label: t("CodeOrName"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.fullText || ""}
        setValue={(fullText) => setSearch({ ...search, fullText })}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["fullText"])),
  },
});
