import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { IFarm } from "../../types/IFarm";
import { SubSection } from "../SubSection";
import { AnimalForm } from "../Sections/Animals/AnimalForm";
import { Land } from "../Procedures/Land";
import { BasicInfoSection } from "../Sections/BasicInfoSection";
import { Member } from "../Procedures/Member";
import { formatDate } from "@inceptionbg/ui-components";

interface Props {
  data?: IFarm;
  onClose: () => void;
}

export const FarmHistoryDataDialog: FC<Props> = ({ data, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("FarmHistoryDataOnDay") + " " + formatDate(data?.historyOnDay)}
      size="l"
      isOpen={!!data}
      onClose={onClose}
      isLoading={false}
      confirmButton={{ okButton: true }}
      children={
        !!data ? (
          <>
            <BasicInfoSection farm={data} />
            {!!data.members && (
              <SubSection title={t("MembersTitle")} expandable initialyOpen>
                {data.members.map((member) => (
                  <Member
                    key={member.personalIdentityNumber}
                    member={member}
                    farm={data}
                    hideMemberLands
                  />
                ))}
              </SubSection>
            )}
            {!!data.lands && (
              <SubSection title={t("Land")} expandable initialyOpen>
                {data.lands.map((land) => (
                  <Land key={land.uuid} land={land} hideUpdateInfoRgz />
                ))}
              </SubSection>
            )}
            {!!data.animals && (
              <SubSection title={t("Animals")} expandable initialyOpen>
                <div className="form-container px-4">
                  {data.animals.map((animal) => (
                    <SubSection
                      key={animal.hid}
                      title={
                        animal.group === "CUSTOM"
                          ? animal.name!
                          : `${t("HidShort")}: ${animal.hid}`
                      }
                      expandable
                      tertiary
                    >
                      <AnimalForm animal={animal} />
                    </SubSection>
                  ))}
                </div>
              </SubSection>
            )}
          </>
        ) : (
          <p className="desc">{t("NoDataForChosenDay")}</p>
        )
      }
    />
  );
};
