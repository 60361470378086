import { IPagination } from "@inceptionbg/ui-components";
import { axiosInstance } from "../App";
import { IWorkRequest } from "../types/IWorkRequest";

export const getWorkRequests = (pagination: IPagination) =>
  axiosInstance
    .get("/work-requests", { params: { ...pagination } })
    .then(({ data }) => ({
      workRequests: data.workRequests as IWorkRequest[],
      totalRows: data.totalRows as number,
    }));
