import { IFuelType, IInvoice } from "./IInvoice";
import { ILandPlantCulture } from "./ILand";
import { IFarm } from "./IFarm";

export interface IRefractionRequest {
  uuid?: string;
  farm?: IFarm;
  id?: string;
  createdAt?: string;
  invoices?: IInvoice[];
  items?: IRefractionRequestItem[];
  caseNumber?: string;
  status?: IRefractionStatus;
}

export interface IRefractionRequestItem {
  uuid: string;
  liter: number;
  landPlantCulture: ILandPlantCulture;
  fuelType: IFuelType;
}

export interface IRefractionRequestSearch {
  farmRegistrationNumber?: string;
  caseNumber?: string;
  status?: IRefractionStatus;
}

export interface IRefractionRequestSummaryReport {
  totalSubmittedRequests?: string;
  totalResolutionsIssued?: string;
  totalPayedRequests?: string;
  totalRejectedRequests?: string;
  totalRequestsInDraft?: string;
  totalSentToPaymentRequests?: string;
}

export type RefractionRequestFetchOption =
  | "CREATED_BY"
  | "ITEMS"
  | "INVOICES"
  | "FARM";

export const RefractionStatuses = [
  "DRAFT",
  "SUBMITTED",
  "APPROVED",
  "REJECTED",
  "SENT",
  "READY_FOR_PAYMENT",
  "PAYED",
] as const;
export type IRefractionStatus = (typeof RefractionStatuses)[number];
