import { IHolderNew } from "./IHolder";
import { IMemberNew } from "./IMember";
import { INameCode } from "./IBase";
import {
  ISimpleObject,
  ISimpleObjectWithCode,
} from "@inceptionbg/ui-components";
import { IContactInfo } from "./IContactInfo";
import { ILand } from "./ILand";
import { IAnimal } from "./IAnimal";
import { IEvidence } from "./IFile";

export interface IFarm {
  uuid: string;
  registrationNumber: string;
  status: string;
  passiveStatusReason: IPassiveStatusReason;
  renewalDate: string;
  activeStatusDays: number;
  registrationDate: string;
  latestChangeDate: string;
  organizationForm: ISimpleObjectWithCode;
  membersCount?: number;
  holder: IHolderNew;
  headOffice: IHeadOfficeNew;
  contactInfo: IContactInfo;
  bankAccount?: IBankAccount;
  legalRepresentative?: ILegalRepresentativeNew;
  members?: IMemberNew[];
  lands?: ILand[];
  animals?: IAnimal[];
  permanentHolder?: IHolderNew;
  agriculturalProduction?: boolean;
  agriculturalProductsProcessing?: boolean;
  nonAgriculturalProduction?: boolean;

  historyOnDay?: string;
}

export interface IFarmSearch {
  registrationNumber?: string;
  holderPersonalIdentityNumber?: string;
  holderRegistrationNumber?: string;
  holderName?: string;
  memberPersonalIdentityNumber?: string;
  atLeastOnelandWithMunicipalityUuid?: string;
  atLeastOnelandWithMunicipality?: ISimpleObjectWithCode;
  atLeastOneLandWithCadastreNumber?: string;
  atLeastOneAnimalWithHid?: string;
  memberNameLike?: string;
}

export interface IHeadOfficeNew {
  street?: string;
  houseNumber?: string;
  place?: ISimpleObjectWithCode;
  municipality?: string;
  postalCode?: string;
  approved?: boolean;
}

export interface IBankAccount {
  accountNumber?: string;
  bank?: INameCode;
  approved?: boolean;
}

export interface ILegalRepresentativeNew extends IHolderNew {
  street?: string;
  houseNumber?: string;
  place?: string;
  municipality?: string;
  postalCode?: string;
  approved?: boolean;
}

export interface IPersonalInfoData extends ILegalRepresentativeNew {
  education?: ISimpleObject;
}

export type FarmFetchOption =
  | "HOLDER"
  | "HEAD_OFFICE"
  | "BANK_ACCOUNT"
  | "CONTACT_INFO"
  | "ORGANIZATIONAL_FORM"
  | "VERFICATION_STATUS"
  | "CREATED_BY"
  | "LEGAL_REPRESENTATIVE"
  | "MEMBERS"
  | "LAND"
  | "ANIMALS"
  | "PASSIVE_STATUS_REASON";

export const RequestTypes = [
  "EXCERPT_FROM_REGISTER",
  "EXCERPT_PLANT_CULTURE",
  "EXCERPT_ANIMAL",
  "STATUS_CONFIRMATION",
] as const;
export type IRequestType = (typeof RequestTypes)[number];

export interface IRenewalCondition {
  farmStatus?: boolean;
  upzCondition?: boolean;
  countrysideTourism?: boolean;
  allowedAprCodes?: boolean;
  atLeastOnePlantCultureOrAnimal?: boolean;
  plantCulture?: boolean;
  animal?: boolean;
  readyForRenewal?: boolean;
  hasEDelivery?: boolean;
  hasEmailOrPhoneNumber?: boolean;
}

export interface IFarmStatusChange {
  newStatus?: IFarmStatus;
  note?: string;
  decisionNumber?: string;
  passiveStatusReason?: IPassiveStatusReason;
  evidence?: IEvidence;
}

export interface IPassiveStatusReason {
  uuid?: string;
  code?: string;
  name?: string;
  canGoToActive?: boolean;
}

export const farmStatuses = ["ACTIVE", "PASSIVE", "DELETED"] as const;
export type IFarmStatus = (typeof farmStatuses)[number];

export interface IFarmDataChange {
  renewalDate?: string;
  holder?: IHolderNew;
  legalRepresentative?: IHolderNew;
}
