import { FC, useCallback, useState } from "react";
import { SubSection } from "../SubSection";
import { PersonalInfoForm } from "../Common/PersonalInfoForm";
import { IMemberNew } from "../../types/IMember";
import { IChangedObject } from "../../types/IApprovals";
import { useTranslation } from "react-i18next";
import { IFarm } from "../../types/IFarm";
import { ILand } from "../../types/ILand";
import { getLands } from "../../repos/LandRepo";
import { Land } from "./Land";

interface Props {
  member: IMemberNew;
  farm: IFarm;
  initiallyOpen?: boolean;
  changedObject?: IChangedObject;
  hideMemberLands?: boolean;
  isChild?: boolean;
}

export const Member: FC<Props> = ({
  member = {},
  farm,
  initiallyOpen,
  changedObject,
  hideMemberLands = false,
  isChild = false,
}) => {
  const [lands, setLands] = useState<ILand[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleGetLands = useCallback(
    (callback?: () => void) => {
      if (!hideMemberLands && farm && member) {
        setIsLoading(true);
        getLands(farm.uuid, {
          fetchOptions: [
            "CADASTRE_CULTURE",
            "CADASTRE_MUNICIPALITY",
            "VERFICATION_STATUS",
            "LEASE_TYPE",
          ],
          memberUuid: member.uuid,
        })
          .then((e) => {
            setLands(e.lands);
            !!callback && callback();
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    },
    [farm, member, hideMemberLands]
  );

  return (
    <SubSection
      title={
        (isChild ? `${t("ChildMember")}: ` : "") +
        `${member.firstName} ${member.lastName}`
      }
      expandable={true}
      onOpen={handleGetLands}
      initialyOpen={!!initiallyOpen}
    >
      <PersonalInfoForm infoData={member} changedActivity={changedObject} />
      {!hideMemberLands && (
        <SubSection
          title={t("MembersLandTitle")}
          secondary
          isLoading={isLoading}
        >
          {!!lands.length && (
            <div className="form-container">
              {lands.map((land) => (
                <Land land={land} isMemberSubsection />
              ))}
            </div>
          )}
        </SubSection>
      )}
    </SubSection>
  );
};
