import { IPagination } from "@inceptionbg/ui-components";
import { axiosInstance } from "../App";
import {
  IPlantProtectionProduct,
  IPlantProtectionProductSearch,
} from "../types/IPlantProtectionProduct";

export const getPlantProtectionProducts = (
  pagination: IPagination,
  search?: IPlantProtectionProductSearch
) =>
  axiosInstance
    .get("/plant-protection-products", {
      params: { ...pagination, ...search },
    })
    .then(({ data }) => ({
      plantProtectionProducts:
        data.plantProtectionProducts as IPlantProtectionProduct[],
      totalRows: data.totalRows as number,
    }));

export const createPlantProtectionProduct = (
  plantProtectionProduct: Partial<IPlantProtectionProduct>
) => axiosInstance.post(`/plant-protection-product`, plantProtectionProduct);

export const deletePlantProtectionProduct = (productUuid: string) =>
  axiosInstance.delete(`/plant-protection-product/${productUuid}`);
