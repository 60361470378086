import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import { ErpgTotalsReport } from "../Components/Sections/Reports/ErpgTotalsReport";
import { useAppSelector } from "../redux/reduxHooks";
import { NoAccessComponent } from "../Components/Common/NoAccessComponent";
import { ApprovalsReport } from "../Components/Sections/Reports/ApprovalsReport";
import { WorkRequestsReport } from "../Components/Sections/Reports/WorkRequestsReport";

export const ReportPage: FC = () => {
  const { t } = useTranslation();

  const hasAccess = useAppSelector((state) => state.user.privileges.SEARCH_R);

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`Reports`)}`}</h1>
      </div>
      <div className="flex p-3">
        <Sidebar />
        <div className="flex column gap-4">
          {hasAccess ? <ErpgTotalsReport /> : <NoAccessComponent />}
          {hasAccess ? <ApprovalsReport /> : <NoAccessComponent />}
          {hasAccess ? <WorkRequestsReport /> : <NoAccessComponent />}
        </div>
      </div>
    </div>
  );
};
