import "@inceptionbg/ui-components/dist/index.css";
import "./styles/App.scss";
import { createContext, FC, Fragment, useEffect, useState } from "react";
import { IUser } from "./types/IUser";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { ScrollToTop } from "./Elements/ScrollToTop";
import { localStorageGet } from "./utils/LocalStorageHelper";
import { isTokenValid, setUserFromToken } from "./utils/TokenUtils";
import { logout, refreshToken } from "./repos/AuthRepo";
import { Dialog } from "./Elements/Dialog";
import { Trans, useTranslation } from "react-i18next";
import { IError } from "@inceptionbg/ui-components";
import i18n from "./utils/i18n";
import { I18nextProvider } from "react-i18next";
import { ConfirmDialog } from "./Components/Dialogs/ConfirmDialog";
import { IConfirmDialogData } from "./types/IBase";
import { LoginPage } from "./Pages/LoginPage";
import { Router } from "./Router";
import { Provider } from "react-redux";
import { store } from "./redux/store";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL || "";

export const UserContext = createContext<IUser | null>(null);

// Axios
export const axiosInstance = axios.create({ baseURL: API_URL });
export const axiosAuth = axios.create({ baseURL: AUTH_API_URL });

axiosInstance.interceptors.request.use((config) => {
  const token = config.headers?.token || localStorageGet("token");
  const tokenValid = isTokenValid(token as string);
  if (tokenValid) {
    config.headers!.Authorization = `Bearer ${token}`;
  } else {
    const controller = new AbortController();
    controller.abort();
    config.signal = controller.signal;
    token && logout();
  }

  return config;
});

////// APP //////

export const App: FC = () => {
  const [user, setUser] = useState<IUser | null>(null);
  const [confirm, setConfirm] = useState<IConfirmDialogData | null>(null);
  const [errors, setErrors] = useState<string[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    // Set User from token
    const token = localStorageGet("token");
    token && setUserFromToken(token, setUser);

    // Set Refresh token
    const handleTokenExpiration = () => {
      const tokenExp = localStorageGet("tokenExp");
      const currentTimestamp = Math.round(new Date().getTime() / 1000);
      if (!document.hidden && !!tokenExp) {
        // Logout if token is expired
        if (currentTimestamp > +tokenExp) {
          logout();
          // Refresh token 40 minutes before expiration
        } else if (currentTimestamp > +tokenExp - 2400) {
          console.log("Get new token");
          refreshToken();
        }
      }
    };
    handleTokenExpiration();
    const intervalId = setInterval(handleTokenExpiration, 30000);
    return () => {
      clearInterval(intervalId!);
    };
  }, []);

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (e) => {
        switch (e.config.method) {
          case "post":
            if (!e.config.headers.noToast) {
              setConfirm({
                type: "Save",
                validation: e.config.headers.validation,
              });
            }
            break;
          case "put":
          case "patch":
            setConfirm({
              type: "Edit",
              validation: e.config.headers.validation,
            });
            break;
        }
        return e;
      },
      function (error) {
        const errors: IError[] = error.response?.data.errors;

        const errorMessages = errors?.length
          ? errors.map((e) =>
              e.errorCode ? (
                // @ts-ignore
                e.errorPlaceholders ? (
                  <Trans
                    i18nKey={`Error${e.errorCode}`}
                    // @ts-ignore
                    values={e.errorPlaceholders}
                  />
                ) : (
                  t(`Error${e.errorCode}`)
                )
              ) : (
                e.errorMessage
              )
            )
          : [t("ErrorMessage")];

        setErrors(errorMessages as string[]);

        return Promise.reject(error);
      }
    );
  }, [t]);

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          {user ? (
            <UserContext.Provider value={user}>
              <Router />
              <ConfirmDialog
                isOpen={!!confirm}
                onClose={() => setConfirm(null)}
                type={confirm?.type!}
                validation={confirm?.validation}
              />
              <Dialog
                isOpen={!!errors.length}
                onClose={() => setErrors([])}
                error
              >
                <div>
                  {errors.map((e, i) => (
                    <Fragment key={e}>
                      {i > 0 && <hr />}
                      <div className="text-center new-line">{e}</div>
                    </Fragment>
                  ))}
                </div>
              </Dialog>
            </UserContext.Provider>
          ) : (
            <LoginPage setUser={setUser} />
          )}
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  );
};
