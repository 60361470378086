import { axiosInstance } from "../App";
import { IAdminUser, IAdminUserSearch } from "../types/IUser";

export const getAdminUsers = (filters?: IAdminUserSearch) =>
  axiosInstance
    .get("/admin/users", {
      params: filters,
    })
    .then(({ data }) => ({
      admins: data.admins as IAdminUser[],
      totalRows: data.totalRows as number,
    }));

export const saveOrUpdateAdminUsers = (data: IAdminUser) =>
  axiosInstance.post(`/admin/users`, data);
