import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IFarm } from "../../../types/IFarm";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import { formatDate } from "@inceptionbg/ui-components";

interface Props {
  farm: IFarm;
}

export const FarmInfo: FC<Props> = ({ farm }) => {
  const { t } = useTranslation();
  const holderType = farm?.holder.type;

  const holdingInfo: IInputComponentProps[] = [
    {
      label: t("OrgNumberLong"),
      value: farm.registrationNumber,
    },
    {
      label: t("StatusPG"),
      value: `${t(farm.status)} ${
        farm.passiveStatusReason && farm.status !== "ACTIVE"
          ? " - " + farm.passiveStatusReason?.name
          : ""
      }`,
    },
    {
      label: t("RegistrationDate"),
      value: formatDate(farm.registrationDate),
    },
    {
      label: t("RenewalDate"),
      value: formatDate(farm.renewalDate),
    },
    {
      label: t("ActiveStatusDays"),
      value: farm.activeStatusDays?.toString(),
      hidden: !farm.activeStatusDays,
    },
    {
      label: t("OrganizationalForm"),
      value: farm.organizationForm ? farm.organizationForm.name : "/",
      // hidden: holderType === 'legalEntity',
    },
    /*{
      label: t('MembersCount'),
      value: `${farm.membersCount || '/'}`,
      // hidden: holderType === 'legalEntity',
    },*/
    {
      label: t("AgriculturalProduction"),
      value: t(!!farm.agriculturalProduction ? "Yes" : "No"),
      hidden: holderType === "physicalPerson",
    },
    {
      label: t("AgriculturalProductsProcessing"),
      value: t(!!farm.agriculturalProductsProcessing ? "Yes" : "No"),
      hidden: holderType === "physicalPerson",
    },
    {
      label: t("NonAgriculturalProduction"),
      value: t(!!farm.nonAgriculturalProduction ? "Yes" : "No"),
      hidden: holderType === "physicalPerson",
    },
  ];

  return (
    <>
      {holdingInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} />
      )}
    </>
  );
};
