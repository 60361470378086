import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../Components/Sidebar";
import { AdminUsersTable } from "./AdminUsersTable";
import { NoAccessComponent } from "../../Components/Common/NoAccessComponent";
import { useAppSelector } from "../../redux/reduxHooks";

export const AdminUsersPage: FC = () => {
  const { t } = useTranslation();
  const hasRPrivilege = useAppSelector(
    (state) => !!state.user.privileges.USERS_R
  );

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`AdminUsers`)}`}</h1>
      </div>
      <div>
        <div className="flex p-3">
          <Sidebar />
          {hasRPrivilege ? (
            <div className="full-width">
              <AdminUsersTable />
            </div>
          ) : (
            <NoAccessComponent />
          )}
        </div>
      </div>
    </div>
  );
};
