import React, { FC, useCallback, useEffect, useState } from "react";
import {
  approveOrRejectProcedure,
  changeApprovalStatus,
  getApprovalByUuid,
  updateApproval,
} from "../repos/ApprovalsRepo";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import { IApprovalNew, IUpdateApprovalEntityData } from "../types/IApprovals";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Member } from "../Components/Procedures/Member";
import { Holder } from "../Components/Procedures/Holder";
import { LegalRepresentative } from "../Components/Procedures/LegalRepresentative";
import { Button, Dialog } from "@inceptionbg/ui-components";
import { MoveTOAdministrativeProcedureDialog } from "../Components/Dialogs/MoveToAdministrativeProcedureDialog";
import { BankAccount } from "../Components/Procedures/BankAccount";
import { HeadOffice } from "../Components/Procedures/HeadOffice";
import { Registration } from "../Components/Procedures/Registration";
import { EvidencesForm } from "../Components/Procedures/EvidencesForm";
import { Land } from "../Components/Procedures/Land";
import { BasicInfoSection } from "../Components/Sections/BasicInfoSection";
import { MembersSection } from "../Components/Sections/Member/MembersSection";
import { LandSection } from "../Components/Sections/Land/LandSection";
import { AnimalsSection } from "../Components/Sections/Animals/AnimalsSection";
import { AuthorizedPersonsSection } from "../Components/Sections/AuthorizedPerson/AuthorizedPersonsSection";
import { useAppSelector } from "../redux/reduxHooks";
import { NoAccessComponent } from "../Components/Common/NoAccessComponent";
import { LandsRecapitulationTable } from "../Components/Recapitulation/LandsRecapitulationTable";
import { getLandInfo, getLands } from "../repos/LandRepo";
import { ILand, ILandInfo } from "../types/ILand";
import { ChangeApprovalStatusDialog } from "../Components/Dialogs/ChangeApprovalStatusDialog";
import { IRenewalCondition } from "../types/IFarm";
import { getFarmRenewalConditions } from "../repos/FarmRepo";
import { RenewSection } from "../Components/Sections/Renew/RenewSection";
import { FarmApprovalsSection } from "../Components/Sections/FarmApprovals/FarmApprovalsSection";
import { ProcedureStatus } from "../Components/Procedures/ProcedureStatus";
import { ChangeApprovalStatusCommentDialog } from "../Components/Dialogs/ChangeApprovalStatusCommentDialog";
import { IUser } from "../types/IUser";
import { Invoice } from "../Components/Procedures/Invoice";
import { InfoBox } from "../Elements/Alert/InfoBox";
import { LandsConfirmationDialog } from "../Components/Dialogs/LandsConfirmationDialog";
import { LandsPartialConfirmationDialog } from "../Components/Dialogs/LandsPartialConfirmationDialog";
import { APRData } from "../Components/Procedures/APRData";

type Params = { approvalUuid: string };

export const ProcedureEditPage: FC = () => {
  const [approval, setApproval] = useState<IApprovalNew>({});
  const [processor, setProcessor] = useState<IUser>();
  const [moveToAdministrativeOpen, setMoveToAdministrativeOpen] =
    useState(false);

  const [landInfoData, setLandInfoData] = useState<ILandInfo>();

  const [changeApprovalStatusOpen, setChangeApprovalStatusOpen] =
    useState(false);
  const [renewalConditions, setRenewalConditions] = useState<IRenewalCondition>(
    {}
  );
  const [isConditionsLoading, setIsConditionsLoading] = useState(false);
  const [changeApprovalStatusCommentOpen, setChangeApprovalStatusCommentOpen] =
    useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const [lands, setLands] = useState<ILand[]>([]);
  const [isLandConfirmationOpen, setIsLandConfirmationOpen] = useState(false);
  const [isPartialLandConfirmationOpen, setIsPartialLandConfirmationOpen] =
    useState(false);

  const [isOpen, setIsOpen] = useState("");

  const { t } = useTranslation();
  const { approvalUuid } = useParams<Params>() as Params;
  const navigate = useNavigate();
  const privileges = useAppSelector((state) => state.user.privileges);
  const activeUserUuid = useAppSelector((state) => state.user.uuid);
  const isInvoiceMode = approval.changedObject === "INVOICE";
  const justPreviewRights = !privileges.APPROVAL_W && privileges.APPROVAL_R;
  const approvalPreview =
    new URLSearchParams(useLocation().search).get("approvalPreview") === "true";

  const editableData =
    !approvalPreview &&
    !justPreviewRights &&
    privileges.UNVERIFIED_DATA_CHANGE &&
    !["APPROVED", "REJECTED"].includes(approval?.approvalStatus || "");

  const handleGetApproval = useCallback(() => {
    getApprovalByUuid(approvalUuid, approvalPreview)
      .then((res) => setApproval(res.approval))
      .catch(() => navigate("/"));
  }, [approvalUuid, navigate, approvalPreview]);

  useEffect(handleGetApproval, [handleGetApproval]);

  useEffect(() => {
    if (approval.uuid)
      getApprovalByUuid(approval.uuid, approvalPreview)
        .then((res) => {
          setProcessor(res.approval.processor);
        })
        .catch(() => {});
  }, [approval, approvalPreview]);

  const holderType = approval?.farm?.holder.type;

  const handleGetLandInfo = useCallback(() => {
    if (!!approval?.land && approval.changedObject === "LAND") {
      getLandInfo(approval.land.uuid)
        .then((e) => setLandInfoData(e))
        .catch(() => {});
    }
  }, [approval]);

  useEffect(handleGetLandInfo, [handleGetLandInfo]);

  const handleGetRenewalConditions = useCallback(
    (callback?: () => void) => {
      if (approval?.farm) {
        setIsConditionsLoading(true);
        getFarmRenewalConditions(approval?.farm.uuid)
          .then((e) => {
            setRenewalConditions(e);
            callback && callback();
          })
          .catch(() => {})
          .finally(() => setIsConditionsLoading(false));
      }
    },
    [approval]
  );

  useEffect(handleGetRenewalConditions, [handleGetRenewalConditions]);

  const handleGetLands = useCallback(() => {
    if (approval.farm && approval.changedObject === "REGISTRATION") {
      getLands(approval.farm.uuid)
        .then((e) => setLands(e.lands))
        .catch(() => {});
    }
  }, [approval]);

  useEffect(handleGetLands, [handleGetLands]);

  const onApprovalUpdate = (data: IUpdateApprovalEntityData) => {
    setIsUpdateLoading(true);
    updateApproval(approvalUuid, data)
      .then(handleGetApproval)
      .catch(() => {})
      .finally(() => setIsUpdateLoading(false));
  };

  const showApprovalData = (approval: IApprovalNew, isChild: boolean) => {
    return (
      <>
        {approval && <ProcedureStatus approval={approval} isChild={isChild} />}
        {approval?.member &&
          approval.farm &&
          approval.changedObject === "MEMBER" && (
            <Member
              member={approval.member}
              farm={approval.farm}
              changedObject={approval.changedObject}
              initiallyOpen
              isChild={isChild}
            />
          )}
        {approval?.holder && approval.changedObject === "HOLDER" && (
          <Holder
            holder={approval.holder}
            changedObject={approval.changedObject}
            isChild={isChild}
          />
        )}
        {approval?.legalRepresentative &&
          approval.changedObject === "LEGAL_REPRESENTATIVE" && (
            <LegalRepresentative
              legalRepresentative={approval.legalRepresentative}
              initiallyOpen
              changedObject={approval.changedObject}
              simpleDisplay
              isChild={isChild}
            />
          )}
        {approval?.bankAccount && approval.changedObject === "BANK_ACCOUNT" && (
          <BankAccount
            bankAccount={approval.bankAccount}
            isLoading={isUpdateLoading}
            editable={editableData && !isChild}
            initiallyOpen
            onSubmit={onApprovalUpdate}
            isChild={isChild}
          />
        )}
        {approval?.headOffice && approval.changedObject === "HEAD_OFFICE" && (
          <HeadOffice
            headOffice={approval.headOffice}
            isLoading={isUpdateLoading}
            editable={editableData && !isChild}
            initiallyOpen
            onSubmit={onApprovalUpdate}
            isChild={isChild}
          />
        )}
        {approval?.changedObject === "REGISTRATION" && (
          <Registration approval={approval} isChild={isChild} />
        )}
        {approval?.land && approval.changedObject === "LAND" && (
          <>
            <Land
              land={approval.land}
              initiallyOpen
              farm={approval.farm}
              isUpdateLoading={isUpdateLoading}
              editable={editableData && !isChild}
              onSubmit={onApprovalUpdate}
              hideUpdateInfoRgz={approvalPreview || justPreviewRights}
            />
            {approval.land.usageBasis === "LEASE_LEGAL_ENTITY" && (
              <APRData landUuid={approval.land.uuid} />
            )}
          </>
        )}
        {approval?.invoice && isInvoiceMode && (
          <Invoice invoice={approval.invoice} initiallyOpen isChild={isChild} />
        )}
      </>
    );
  };

  const approveOrRejectProcedureStatus = (
    excludeLands?: { uuid: string }[]
  ) => {
    approveOrRejectProcedure(approvalUuid, "APPROVED", {
      decisionNumber: approval.decisionNumber,
      decisionDate: approval.decisionDate,
      excludeLands: !!excludeLands?.length ? excludeLands : undefined,
    })
      .then(() =>
        navigate(isInvoiceMode ? "/invoices" : "/non-administrative-procedures")
      )
      .catch(() => {});
  };

  const handleDialog = () => {
    isOpen === "confirm"
      ? approval?.changedObject === "REGISTRATION" && !!lands.length
        ? setIsLandConfirmationOpen(true)
        : approveOrRejectProcedureStatus()
      : approveOrRejectProcedure(approvalUuid, "REJECTED", {
          decisionNumber: approval.decisionNumber,
          decisionDate: approval.decisionDate,
        })
          .then(() => navigate(`/`))
          .catch(() => {});
    setIsOpen("");
  };
  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("OrgNumberShort")}: ${approval.farm?.registrationNumber} - ${
          approval.administrativeProcedure
            ? t("AdministrativeProcedures")
            : t("NonAdministrativeProcedures")
        } - ${approval.approvalNumber} - ${t(
          `ChangedObject${approval.changedObject}`
        )} - ${t(`ApprovalStatus${approval.approvalStatus}`)}`}</h1>
      </div>
      <div className="flex pl-3 pr-3">
        <Sidebar />
        {privileges.APPROVAL_R || privileges.APPROVAL_W ? (
          <>
            <div className="p-3">
              {approval.statusComment && (
                <InfoBox
                  className="mb-3"
                  title={t("EditorsComment")}
                  desc={approval.statusComment}
                />
              )}

              {approval && showApprovalData(approval, false)}
              {approval?.childApproval &&
                showApprovalData(approval.childApproval, true)}
              {!!approval && (
                <EvidencesForm
                  approval={approval}
                  setApproval={setApproval}
                  enableEdit={!approvalPreview && !justPreviewRights}
                />
              )}
              {!approvalPreview &&
                !justPreviewRights &&
                approval?.approvalStatus === "APPROVED" &&
                !approval?.administrativeProcedure && (
                  <div className="page-sub-section-buttons flex justify-right">
                    <Button
                      label={t("ChangeStatus")}
                      // disabled={activeUserUuid !== processor?.uuid}
                      onClick={() => setChangeApprovalStatusOpen(true)}
                      size="s"
                      className="secondary mr-3"
                      primary
                    />
                  </div>
                )}
              {!approvalPreview &&
                !justPreviewRights &&
                approval?.approvalStatus !== "APPROVED" && (
                  <div className="page-sub-section-buttons flex justify-right">
                    {!approval?.administrativeProcedure && !isInvoiceMode && (
                      <Button
                        label={t("SendToProcedure")}
                        onClick={() => setMoveToAdministrativeOpen(true)}
                        size="s"
                        className="error mr-3"
                        primary
                      />
                    )}
                    <Button
                      label={t("ChangeStatus")}
                      disabled={
                        approval?.approvalStatus === "IN_PROGRESS" &&
                        activeUserUuid !== processor?.uuid
                      }
                      onClick={() => setChangeApprovalStatusOpen(true)}
                      size="s"
                      className="secondary mr-3"
                      primary
                    />
                    {approval?.administrativeProcedure && (
                      <Button
                        label={t("Reject")}
                        disabled={
                          !approval.decisionNumber || !approval.decisionDate
                        }
                        onClick={() => setIsOpen("reject")}
                        size="s"
                        className="error mr-3"
                        primary
                      />
                    )}
                    <Button
                      label={t("Confirm")}
                      disabled={
                        (approval?.changedObject === "REGISTRATION" &&
                          !approval?.administrativeProcedure) ||
                        (approval?.administrativeProcedure &&
                          (!approval.decisionNumber || !approval.decisionDate))
                      }
                      onClick={() => setIsOpen("confirm")}
                      size="s"
                      className="primary"
                      primary
                    />
                  </div>
                )}
              {!!landInfoData && (
                <div className="mt-4">
                  <LandsRecapitulationTable landInfo={landInfoData} />
                </div>
              )}
              {approval?.farm?.uuid && (
                <FarmApprovalsSection farmUuid={approval.farm.uuid} />
              )}
            </div>

            {!!approval?.farm && (
              <div className="farm-basic-info-section">
                <BasicInfoSection
                  farm={approval.farm}
                  approvalUuid={approvalUuid}
                  editable={editableData}
                  refreshApproval={handleGetApproval}
                />
                {holderType === "physicalPerson" && (
                  <MembersSection farm={approval.farm} />
                )}
                {holderType === "legalEntity" && (
                  <AuthorizedPersonsSection farm={approval.farm} />
                )}
                <LandSection
                  farm={approval.farm}
                  approvalUuid={approvalUuid}
                  editable={editableData}
                  refreshApproval={handleGetApproval}
                  hideUpdateInfoRgz={
                    approvalPreview ||
                    justPreviewRights ||
                    ["APPROVED", "REJECTED"].includes(
                      approval?.approvalStatus || ""
                    )
                  }
                />
                <AnimalsSection
                  farm={approval.farm}
                  approvalChangedObjectRegistration={
                    approval?.changedObject === "REGISTRATION"
                  }
                />
                {!!renewalConditions && (
                  <RenewSection
                    renewalConditions={renewalConditions}
                    farmRegistrationNumber={approval.farm.registrationNumber}
                    isLoading={isConditionsLoading}
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <NoAccessComponent />
        )}
      </div>
      <MoveTOAdministrativeProcedureDialog
        isOpen={moveToAdministrativeOpen}
        onClose={() => setMoveToAdministrativeOpen(false)}
        approvalUuid={approvalUuid}
      />
      <ChangeApprovalStatusDialog
        isOpen={changeApprovalStatusOpen}
        onClose={() => setChangeApprovalStatusOpen(false)}
        onSubmit={(status) => {
          if (status === "WAITING_COMPLEMENT") {
            setChangeApprovalStatusOpen(false);
            setChangeApprovalStatusCommentOpen(true);
          } else {
            changeApprovalStatus(approvalUuid, status)
              .then(() => {
                setChangeApprovalStatusOpen(false);
                navigate(
                  approval?.administrativeProcedure
                    ? "/administrative-procedures"
                    : isInvoiceMode
                    ? "/invoices"
                    : "/non-administrative-procedures"
                );
              })
              .catch(() => {});
          }
        }}
      />
      <ChangeApprovalStatusCommentDialog
        isOpen={changeApprovalStatusCommentOpen}
        onClose={() => setChangeApprovalStatusCommentOpen(false)}
        onSubmit={(statusComment) =>
          changeApprovalStatus(
            approvalUuid,
            "WAITING_COMPLEMENT",
            statusComment
          )
            .then(() => {
              setChangeApprovalStatusCommentOpen(false);
              navigate(
                approval?.administrativeProcedure
                  ? "/administrative-procedures"
                  : "/non-administrative-procedures"
              );
            })
            .catch(() => {})
        }
      />
      <LandsConfirmationDialog
        isOpen={isLandConfirmationOpen}
        onSubmit={approveOrRejectProcedureStatus}
        onClose={() => setIsLandConfirmationOpen(false)}
        onPartialSubmit={() => {
          setIsLandConfirmationOpen(false);
          setIsPartialLandConfirmationOpen(true);
        }}
      />
      <LandsPartialConfirmationDialog
        isOpen={isPartialLandConfirmationOpen}
        lands={lands}
        onSubmit={(excludeLands: { uuid: string }[]) =>
          approveOrRejectProcedureStatus(excludeLands)
        }
        onClose={() => setIsPartialLandConfirmationOpen(false)}
      />
      <Dialog
        isOpen={!!isOpen}
        onClose={() => setIsOpen("")}
        desc={
          isOpen === "confirm"
            ? t("ConfirmRequestDesc")
            : t("RejectRequestDesc")
        }
        confirmButton={{
          label:
            isOpen === "confirm"
              ? t("ConfirmRequestButton")
              : t("RejectRequestButton"),
          onClick: () => handleDialog(),
        }}
        cancelButton={{ onClick: () => setIsOpen("") }}
      />
    </div>
  );
};
