import { FC } from "react";
import { SubSection } from "../SubSection";
import { PersonalInfoForm } from "../Common/PersonalInfoForm";
import { IHolderNew } from "../../types/IHolder";
import { IChangedObject } from "../../types/IApprovals";
import { LegalEntityInfo } from "./LegalEntityInfo";
import { useTranslation } from "react-i18next";

interface Props {
  holder: IHolderNew;
  changedObject: IChangedObject;
  isChild?: boolean;
}

export const Holder: FC<Props> = ({
  holder = {},
  changedObject,
  isChild = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {holder.type === "physicalPerson" ? (
        <SubSection
          title={t(isChild ? "ChildArgHolderInfo" : "ArgHolderInfo")}
          expandable={true}
          initialyOpen={true}
        >
          <PersonalInfoForm infoData={holder} changedActivity={changedObject} />
        </SubSection>
      ) : (
        <LegalEntityInfo legalEntity={holder} initiallyOpen />
      )}
    </>
  );
};
