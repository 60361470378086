import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import {
  Button,
  deleteProps,
  Select,
  TextInput,
} from "@inceptionbg/ui-components";
import { useAppSelector } from "../redux/reduxHooks";
import { NoAccessComponent } from "../Components/Common/NoAccessComponent";
import { openAdminCase } from "../repos/FarmRepo";
import { IInputComponentProps, Input } from "../Elements/Input";
import { SubSection } from "../Components/SubSection";
import { IEvidence } from "../types/IFile";
import { OpenCaseEvidences } from "../Components/Files/OpenCaseEvidences";
import { IOpenCase } from "../types/IOpenCase";

export const OpenCasePage: FC = () => {
  const [data, setData] = useState<IOpenCase>();
  const [isLoading, setIsLoading] = useState(false);
  const [evidences, setEvidences] = useState<IEvidence[]>([]);

  const { t } = useTranslation();
  const privileges = useAppSelector((state) => state.user.privileges);

  const farmStatusInfo: IInputComponentProps[] = [
    {
      label: t("OrgNumberShort"),
      input: (
        <TextInput
          required
          value={data?.farmRegistrationNumber || ""}
          setValue={(e) => setData({ ...data, farmRegistrationNumber: e })}
        />
      ),
    },
    {
      label: t("ProcessType"),
      input: (
        <Select
          placeholder={`${t("Choose")}...`}
          isClearable
          value={
            data?.generalRequestType
              ? {
                  value: data.generalRequestType.code,
                  label: data.generalRequestType.name,
                }
              : null
          }
          options={[
            { code: "01", name: "Opšte" },
            { code: "05", name: "Brisanje iz registra" },
            { code: "08", name: "Promena statusa" },
          ].map((e) => ({
            value: e.code,
            label: e.code + " - " + e.name,
          }))}
          onChange={(e) => {
            e
              ? setData({
                  ...data,
                  generalRequestType: { code: e.value, name: e.label },
                })
              : setData(deleteProps(data, ["generalRequestType"]));
          }}
        />
      ),
    },
    {
      label: t("Note"),
      input: (
        <TextInput
          required
          value={data?.note || ""}
          setValue={(e) => setData({ ...data, note: e })}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`OpenCasePage`)}`}</h1>
      </div>
      <div className="flex p-3">
        <Sidebar />
        {privileges.APPROVAL_W ? (
          <>
            <SubSection
              title={t("OpenCasePage")}
              expandable={false}
              isLoading={isLoading}
            >
              <div className="form-container">
                {farmStatusInfo.map(
                  (e) => !e.hidden && <Input key={e.label} component={e} edit />
                )}
                <OpenCaseEvidences
                  evidences={evidences}
                  setEvidences={setEvidences}
                  setIsLoading={setIsLoading}
                />
                <div className="flex justify-right">
                  <Button
                    label={t("Save")}
                    onClick={() => {
                      setIsLoading(true);
                      openAdminCase({
                        ...data,
                        evidences: evidences.map((e) => ({
                          uuid: e.storedFile.uuid!,
                        })),
                      })
                        .then(() => {
                          setData({});
                          setEvidences([]);
                        })
                        .catch(() => {})
                        .finally(() => setIsLoading(false));
                    }}
                    disabled={
                      !data?.farmRegistrationNumber ||
                      !data?.generalRequestType ||
                      !data?.note ||
                      !evidences.length
                    }
                    size="s"
                    className="primary"
                    primary
                  />
                </div>
              </div>
            </SubSection>
          </>
        ) : (
          <NoAccessComponent />
        )}
      </div>
    </div>
  );
};
