import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IFarm } from "../../../types/IFarm";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import { SubSection } from "../../SubSection";

interface Props {
  farm: IFarm;
}

export const ContactInfo: FC<Props> = ({ farm }) => {
  const { t } = useTranslation();

  const contactInfo: IInputComponentProps[] = [
    {
      label: t("Email"),
      labelHelper: t("EmailHelper"),
      value: farm?.contactInfo?.email,
    },
    {
      label: t("PhoneNumber"),
      labelHelper: t("PhoneNumberHelper"),
      value: farm?.contactInfo?.phoneNumber,
    },
  ];

  return (
    <SubSection title={t("ContactTitle")} expandable={true}>
      {contactInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} />
      )}
    </SubSection>
  );
};
