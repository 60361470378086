import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch } from "./store";
import { IPrivileges } from "../types/IPrivileges";

interface IReduxState {
  user: {
    uuid: string;
    privileges: IPrivileges;
  };
}

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<IReduxState> = useSelector;
