import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IApprovalSearch } from "../../types/IApprovals";
import {
  deleteProps,
  formatDate,
  ITableColumn,
  ITableDataItem,
} from "@inceptionbg/ui-components";
import { Table } from "../CustomIUI/Table/Table";
import { ApprovalsFilters } from "./ApprovalsFilters";
import { getApprovalsGrouped } from "../../repos/ApprovalsRepo";
import { defaultApprovalsSearch } from "../../Pages/ProceduresPage";
import { useNavigate } from "react-router-dom";

export const ApprovalsGroupedTable: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<IApprovalSearch>(defaultApprovalsSearch);
  const [searchData, setSearchData] = useState<IApprovalSearch>(
    defaultApprovalsSearch
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGetApprovals = useCallback(() => {
    setIsLoading(true);
    getApprovalsGrouped({ limit, offset }, search)
      .then(({ approvalsGrouped, totalRows }) => {
        setData(
          approvalsGrouped.map((e, i) => ({
            uuid: i + "s",
            onRowClick: () =>
              navigate(
                `/farm/${e.farm.uuid}/approvals-by-group/${e.farm?.registrationNumber}/${e.count}/${e.processingStarted}`
              ),
            cells: {
              farmRegistrationNumber: {
                value: e.farm?.registrationNumber || "",
              },
              count: { value: e.count || "" },
              latestCratedAt: { value: formatDate(e.latestCratedAt) || "" },
              status: {
                value: t(
                  e.processingStarted
                    ? "ApprovalStatusIN_PROGRESS"
                    : "ApprovalStatusPENDING"
                ),
              },
            },
          })) as ITableDataItem[]
        );
        setTotalRows(totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [limit, offset, search, t, navigate]);

  useEffect(handleGetApprovals, [handleGetApprovals]);

  const columns: ITableColumn[] = [
    { id: "farmRegistrationNumber", label: t("OrgNumberShort") },
    { id: "count", label: t("OpenApprovalsCount"), align: "center" },
    { id: "latestCratedAt", label: t("LatestCratedAtDate"), align: "center" },
    { id: "status", label: t("ApprovalsStatus") },
  ];

  const onSearch = (search: IApprovalSearch) => {
    setSearch(
      deleteProps(
        {
          ...search,
          changedObject: ["TEMPORARY_HOLDER", "CHANGE_HOLDER"].includes(
            search.changedObject!
          )
            ? "HOLDER"
            : search.changedObject,
          holderTemporary:
            search.changedObject === "TEMPORARY_HOLDER"
              ? true
              : search.changedObject === "CHANGE_HOLDER"
              ? false
              : null,
        },
        ["farmObj", "processorUser"]
      )
    );
    setOffset(0);
  };

  return (
    <Table
      columns={columns}
      data={data}
      filterData={{
        columns: [
          "processorUser",
          "concluded",
          "approvalNumber",
          "farm",
          "farmRegistrationNumber",
          "changedObject",
          "holderTemporary",
          "approvalStatus",
          "creationDate",
          "statusTime",
          "memberLand",
          "holderPersonalIdentityNumber",
          "holderRegistrationNumber",
          "landUsageBasis",
        ],
        filters: ApprovalsFilters(searchData, setSearchData),
        search,
        searchData,
        setSearchData,
        onSubmit: onSearch,
        resetData: defaultApprovalsSearch,
      }}
      isLoading={isLoading}
      serverSidePagination={{ limit, offset, setLimit, setOffset, totalRows }}
      footerAction={{
        onClick: handleGetApprovals,
      }}
    />
  );
};
