import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IApprovalNew } from "../../../types/IApprovals";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import { SubSection } from "../../SubSection";
import { formatDate } from "@inceptionbg/ui-components";

interface Props {
  approval: IApprovalNew;
  initiallyOpen?: boolean;
}

export const FarmApproval: FC<Props> = ({ approval = {}, initiallyOpen }) => {
  const { t } = useTranslation();

  const approvalInfo: IInputComponentProps[] = [
    {
      label: t("ChangedObject"),
      value: approval.changedObject
        ? t(
            approval.holder
              ? `ChangedObject${approval.changedObject}${approval.holder.type}`
              : `ChangedObject${approval.changedObject}`
          )
        : "",
    },
    {
      label: t("RequestDate"),
      value: formatDate(approval.createdAt) || "",
    },
    {
      label: t("Processor"),
      value: approval.processor
        ? `${approval.processor.firstName} ${approval.processor.lastName}`
        : "",
      hidden: approval.approvalStatus !== "IN_PROGRESS",
    },
  ];

  return (
    <SubSection
      titleMulti={[
        approval.approvalNumber || "",
        `${t(`ApprovalStatus${approval.approvalStatus}`)}`,
      ]}
      expandable
      initialyOpen={!!initiallyOpen}
    >
      <div className="form-container">
        {approvalInfo.map(
          (e) => !e.hidden && <Input key={e.label} component={e} />
        )}
      </div>
    </SubSection>
  );
};
