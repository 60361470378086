import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IHolderNew } from "../../types/IHolder";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { SubSection } from "../SubSection";

interface Props {
  legalEntity: IHolderNew;
  initiallyOpen?: boolean;
}

export const LegalEntityInfo: FC<Props> = ({ legalEntity, initiallyOpen }) => {
  const { t } = useTranslation();

  const legalEntityInfo: IInputComponentProps[] = [
    {
      label: t("OrganizationalForm"),
      value: legalEntity.legalEntityForm
        ? t("LEForm" + legalEntity.legalEntityForm)
        : "/",
    },
    {
      label: t("LegalEntitySize"),
      value: legalEntity.size ? t(legalEntity.size) : "/",
    },
    {
      label: t("RegistrationNumber"),
      value: legalEntity.registrationNumber,
    },
    {
      label: t("TaxId"),
      value: legalEntity.taxId,
    },
    {
      label: t("Jbkjs"),
      value: legalEntity.jbkjs,
    },
    {
      label: t("Name"),
      value: legalEntity.name,
    },
    {
      label: t("NumberOfEmployees"),
      value: `${legalEntity.numberOfEmployees || "/"}`,
    },
  ];

  return (
    <SubSection
      title={t("LegalEntityInfo")}
      expandable={true}
      initialyOpen={initiallyOpen}
    >
      <div className="form-container">
        {legalEntityInfo.map(
          (e) => !e.hidden && <Input key={e.label} component={e} />
        )}
      </div>
    </SubSection>
  );
};
