import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, DateInput, TextInput } from "@inceptionbg/ui-components";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { SubSection } from "../../../SubSection";
import { changeFarmData } from "../../../../repos/FarmRepo";

export const FarmRenewalDateChange: FC = () => {
  const [regNum, setRegNum] = useState("");
  const [data, setData] = useState<{
    renewalDate?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const renewalDateInfo: IInputComponentProps[] = [
    {
      label: t("OrgNumberShort"),
      input: (
        <TextInput required value={regNum} setValue={(e) => setRegNum(e)} />
      ),
    },
    {
      label: t("RenewalDate"),
      input: (
        <DateInput
          required
          date={data.renewalDate}
          setDate={(e) => setData({ ...data, renewalDate: e })}
        />
      ),
    },
  ];

  return (
    <SubSection
      title={t("RenewalDateChange")}
      expandable={false}
      isLoading={isLoading}
      className="mb-3"
    >
      <div className="form-container">
        {renewalDateInfo.map(
          (e) => !e.hidden && <Input key={e.label} component={e} edit />
        )}
        <div className="flex justify-right">
          <Button
            label={t("Save")}
            onClick={() => {
              setIsLoading(true);
              changeFarmData(regNum, data)
                .then(() => {
                  setData({});
                  setRegNum("");
                })
                .catch(() => {})
                .finally(() => setIsLoading(false));
            }}
            disabled={!regNum || !data?.renewalDate}
            size="s"
            className="primary"
            primary
          />
        </div>
      </div>
    </SubSection>
  );
};
