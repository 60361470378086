import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ILand } from "../../types/ILand";
import { Dialog } from "../../Elements/Dialog";
import { PartLandTable } from "../Sections/Land/PartLandTable";

interface Props {
  land: ILand;
  isOpen: boolean;
  onClose: () => void;
}

export const PartLandEvidenceDialog: FC<Props> = ({
  land,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog title={t("PartLand")} size="l" isOpen={isOpen} onClose={onClose}>
      <PartLandTable land={land} />
    </Dialog>
  );
};
