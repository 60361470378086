import { FC } from "react";
import { IChangedObject } from "../../types/IApprovals";
import { IAuthorizedPerson } from "../../types/IAuthorizedPerson";
import { SubSection } from "../SubSection";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";

interface Props {
  authorizedPerson: IAuthorizedPerson;
  isLoading?: boolean;
  initiallyOpen?: boolean;
  changedObject?: IChangedObject;
}

export const AuthorizedPerson: FC<Props> = ({
  authorizedPerson,
  isLoading,
  initiallyOpen,
}) => {
  const { t } = useTranslation();

  const authorizedPersonInfo: IInputComponentProps[] = [
    {
      label: t("FirstName"),
      value: authorizedPerson.firstName,
    },
    {
      label: t("LastName"),
      value: authorizedPerson.lastName,
    },
    {
      label: t("PersonalIdentityNumber"),
      value: authorizedPerson.personalIdentityNumber,
    },
    {
      label: t("EmailAddress"),
      value: authorizedPerson.email,
    },
    {
      label: t("PhoneNumber"),
      value: authorizedPerson.phone,
    },
  ];

  return (
    <SubSection
      title={`${authorizedPerson.firstName} ${authorizedPerson.lastName}`}
      expandable={true}
      isLoading={!!isLoading}
      initialyOpen={!!initiallyOpen}
    >
      <div className="form-container">
        {authorizedPersonInfo.map(
          (e) => !e.hidden && <Input key={e.label} component={e} />
        )}
      </div>
    </SubSection>
  );
};
