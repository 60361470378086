import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ITableColumn,
  ITableDataItem,
} from "@inceptionbg/ui-components";
import {
  getAdminUsers,
  saveOrUpdateAdminUsers,
} from "../../repos/AdminUserRepo";
import { IAdminUser, IAdminUserSearch } from "../../types/IUser";
import { Dialog } from "../../Elements/Dialog";
import { Table } from "../../Components/CustomIUI/Table/Table";
import { useAppSelector } from "../../redux/reduxHooks";
import { AdminUserForm } from "./AdminUserForm";
import { AdminUsersFilters } from "./AdminUsersFilters";

export const AdminUsersTable: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [details, setDetails] = useState<IAdminUser>({});
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [search, setSearch] = useState<IAdminUserSearch>({});
  const [searchData, setSearchData] = useState<IAdminUserSearch>({});

  const { t } = useTranslation();
  const hasWPrivilege = useAppSelector(
    (state) => !!state.user.privileges.USERS_W
  );

  const handleGetAdminUsers = useCallback(() => {
    setIsLoading(true);
    getAdminUsers(search)
      .then(({ admins }) => {
        setData(
          admins.map((e) => ({
            uuid: e.uuid,
            cells: {
              firstName: {
                value: e.firstName || "",
              },
              lastName: {
                value: e.lastName || "",
              },
              umcn: {
                value: e.umcn || "",
              },
              email: {
                value: e.email || "",
              },
              role: {
                value: e.role?.name || "",
              },
              actions: {
                value: (
                  <Button
                    label={t("Edit")}
                    onClick={() => {
                      setDetails(e);
                      setIsUserFormOpen(true);
                    }}
                    outlined
                    size={"s"}
                  />
                ),
              },
            },
          })) as ITableDataItem[]
        );
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [t, search]);

  useEffect(handleGetAdminUsers, [handleGetAdminUsers]);

  const columns: ITableColumn[] = [
    { id: "firstName", label: t("FirstName") },
    { id: "lastName", label: t("LastName") },
    { id: "umcn", label: t("PersonalIdentityNumber") },
    { id: "email", label: t("Email") },
    { id: "role", label: t("Role") },
    { id: "actions", label: "", unavailable: !hasWPrivilege },
  ];

  const onSearch = (search: IAdminUserSearch) => setSearch(search);

  const onSubmit = () => {
    setIsLoading(true);
    saveOrUpdateAdminUsers(details)
      .then(() => {
        handleGetAdminUsers();
        setDetails({});
        setIsUserFormOpen(false);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className="flex justify-right">
        <Button
          label={t("AddAdminUser")}
          size="s"
          className="primary"
          primary
          onClick={() => setIsUserFormOpen(true)}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        customPagination={{ defaultLimit: 10 }}
        filterData={{
          columns: ["firstName", "lastName", "umcn", "email"],
          filters: AdminUsersFilters(searchData, setSearchData),
          search,
          searchData,
          setSearchData,
          onSubmit: onSearch,
        }}
      />
      <Dialog
        className="over"
        isOpen={isUserFormOpen}
        onClose={() => {
          setDetails({});
          setIsUserFormOpen(false);
        }}
        desc={t(!!details.uuid ? "EditAdminUser" : "AddAdminUser")}
        children={
          <AdminUserForm
            data={details}
            setData={setDetails}
            onSubmit={onSubmit}
          />
        }
        cancelButton={{
          onClick: () => {
            setDetails({});
            setIsUserFormOpen(false);
          },
        }}
      />
    </>
  );
};
