import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import { Button, DateInput } from "@inceptionbg/ui-components";
import {
  getFarmByRegistrationNumber,
  getFarmHistoryAtDay,
  getFarmRenewalConditions,
} from "../repos/FarmRepo";
import {
  IFarm,
  IRenewalCondition,
  IRequestType,
  RequestTypes,
} from "../types/IFarm";
import { BasicInfoSection } from "../Components/Sections/BasicInfoSection";
import { MembersSection } from "../Components/Sections/Member/MembersSection";
import { LandSection } from "../Components/Sections/Land/LandSection";
import { AnimalsSection } from "../Components/Sections/Animals/AnimalsSection";
import { useAppSelector } from "../redux/reduxHooks";
import { NoAccessComponent } from "../Components/Common/NoAccessComponent";
import { Section } from "../Components/Section";
import { downloadDocumentFile } from "../utils/FileUtils";
import { useParams } from "react-router-dom";
import { RenewSection } from "../Components/Sections/Renew/RenewSection";
import { FarmHistoryDataDialog } from "../Components/Dialogs/FarmHistoryDataDialog";
import { Dialog } from "../Elements/Dialog";
import { FarmStatusHistoryDialog } from "../Components/Dialogs/FarmStatusHistoryDialog";

type Params = { registrationNumber: string };

export const FarmDetailsPage: FC = () => {
  const [farm, setFarm] = useState<IFarm>();
  const [renewalConditions, setRenewalConditions] = useState<IRenewalCondition>(
    {}
  );
  const [isConditionsLoading, setIsConditionsLoading] = useState(false);
  const [historyDateOpen, setHistoryDateOpen] = useState(false);
  const [historyStatusDialogOpen, setHistoryStatusDialogOpen] = useState(false);
  const [historyOnDay, setHistoryOnDay] = useState("");
  const [historyData, setHistoryData] = useState<IFarm>();

  const { t } = useTranslation();
  const privileges = useAppSelector((state) => state.user.privileges);
  const { registrationNumber } = useParams<Params>() as Params;

  useEffect(() => {
    getFarmByRegistrationNumber(registrationNumber, {
      fetchOptions: [
        "HOLDER",
        "HEAD_OFFICE",
        "BANK_ACCOUNT",
        "CONTACT_INFO",
        "LEGAL_REPRESENTATIVE",
        "ORGANIZATIONAL_FORM",
        "MEMBERS",
        "LAND",
        "ANIMALS",
        "PASSIVE_STATUS_REASON",
      ],
    })
      .then((e) => setFarm(e.farm))
      .catch(() => {});
  }, [registrationNumber]);

  const handleGetRenewalConditions = useCallback(
    (callback?: () => void) => {
      if (!!farm) {
        setIsConditionsLoading(true);
        getFarmRenewalConditions(farm.uuid)
          .then((e) => {
            setRenewalConditions(e);
            callback && callback();
          })
          .catch(() => {})
          .finally(() => setIsConditionsLoading(false));
      }
    },
    [farm]
  );

  useEffect(handleGetRenewalConditions, [handleGetRenewalConditions]);

  const getDownloadFileName = (type: IRequestType) => {
    return (
      (type === "EXCERPT_FROM_REGISTER"
        ? "farm_status_confirmation"
        : type === "EXCERPT_ANIMAL"
        ? "animals"
        : type === "STATUS_CONFIRMATION"
        ? "status_confirmation"
        : "plant_culture") +
      "_" +
      farm?.registrationNumber
    );
  };

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`FarmsRegister`)}`}</h1>
      </div>
      <div>
        <div className="flex p-3">
          <Sidebar />
          {privileges.SEARCH_R ? (
            <>
              {!!farm && (
                <div className="farm-basic-info-section mt-3">
                  <BasicInfoSection farm={farm} />
                  {farm.holder?.type === "physicalPerson" &&
                    !!farm.members?.length && <MembersSection farm={farm} />}
                  {!!farm.lands?.length && <LandSection farm={farm} />}
                  {!!farm.animals?.length && <AnimalsSection farm={farm} />}
                  {!!renewalConditions && (
                    <RenewSection
                      renewalConditions={renewalConditions}
                      farmRegistrationNumber={farm.registrationNumber}
                      isLoading={isConditionsLoading}
                    />
                  )}
                  <Section id="registration" title="">
                    {RequestTypes.map((type, i) => (
                      <div key={i} className="flex justify-right mt-3">
                        <Button
                          label={t("Label" + type)}
                          primary
                          size="l"
                          onClick={() =>
                            downloadDocumentFile(
                              getDownloadFileName(type),
                              `/farm/${farm.uuid}/request-type/${type}/confirmation.pdf`,
                              "pdf"
                            )
                          }
                          disabled={
                            !(
                              farm?.status === "ACTIVE" ||
                              farm?.status === "PASSIVE"
                            )
                          }
                        />
                      </div>
                    ))}
                    <div className="flex justify-right mt-3">
                      <Button
                        label={t("ShowHistoryDataOnDay")}
                        primary
                        size="l"
                        onClick={() => setHistoryDateOpen(true)}
                      />
                    </div>
                    <div className="flex justify-right mt-3">
                      <Button
                        label={t("FarmStatusHistoryChange")}
                        primary
                        size="l"
                        onClick={() => setHistoryStatusDialogOpen(true)}
                      />
                    </div>
                  </Section>
                </div>
              )}
              <Dialog
                title={t("ShowHistoryDataOnDay")}
                isOpen={historyDateOpen}
                onClose={() => {
                  setHistoryOnDay("");
                  setHistoryDateOpen(false);
                }}
                confirmButton={{
                  label: t("Confirm"),
                  disabled: !historyOnDay,
                  onFormSubmit: () =>
                    getFarmHistoryAtDay(farm?.uuid!, historyOnDay)
                      .then((farm) =>
                        setHistoryData({ ...farm, historyOnDay: historyOnDay })
                      )
                      .catch(() => {}),
                }}
              >
                <DateInput
                  date={historyOnDay}
                  setDate={(date) => setHistoryOnDay(date)}
                />
              </Dialog>
              <FarmStatusHistoryDialog
                farm={farm!}
                isOpen={historyStatusDialogOpen}
                onClose={() => setHistoryStatusDialogOpen(false)}
              />
              {!!historyData && (
                <FarmHistoryDataDialog
                  data={historyData}
                  onClose={() => setHistoryData(undefined)}
                />
              )}
            </>
          ) : (
            <NoAccessComponent />
          )}
        </div>
      </div>
    </div>
  );
};
