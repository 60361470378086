import clsx from "clsx";
import { FC } from "react";

interface Props {
  title: string;
  desc: string;
  className?: string;
}
export const InfoBox: FC<Props> = ({ title, desc, className }) => (
  <div className={clsx("info-box", className)}>
    <p className="title">{title}</p>
    <p className="new-line">{desc}</p>
  </div>
);
