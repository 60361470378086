import {
  DateInput,
  deleteProps,
  ITableFilter,
  TextInput,
} from "@inceptionbg/ui-components";
import { t } from "i18next";
import { IPesticideProcurementSearch } from "../../types/IPesticideProcurement";
import { SelectAsyncPaginate } from "../Inputs/SelectAsyncPaginate";
import { getPlantProtectionProductOptions } from "../../utils/LoadOptions";

export const PesticideProcurementFilters: (
  search: IPesticideProcurementSearch,
  setSearch: (search: IPesticideProcurementSearch) => void
) => ITableFilter = (search, setSearch) => ({
  farmRegistrationNumber: {
    label: t("OrgNumberShort"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.farmRegistrationNumber || ""}
        setValue={(farmRegistrationNumber) =>
          setSearch({ ...search, farmRegistrationNumber })
        }
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["farmRegistrationNumber"])),
  },
  plantProtectionProductUuid: {
    label: t("PlantProtectionProduct"),
    field: (
      <SelectAsyncPaginate
        value={search.plantProtectionProductObj}
        loadOptions={getPlantProtectionProductOptions}
        onChange={(e) =>
          setSearch(
            e
              ? {
                  ...search,
                  plantProtectionProductObj: e,
                  plantProtectionProductUuid: e.value,
                }
              : deleteProps(search, [
                  "plantProtectionProductObj",
                  "plantProtectionProductUuid",
                ])
          )
        }
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["plantProtectionProductObj"])),
  },
  date: {
    label: t("PurchaseDate"),
    field: (
      <div className="flex-center full-width">
        <DateInput
          date={search.dateFrom || ""}
          setDate={(dateFrom) => setSearch({ ...search, dateFrom })}
        />
        <p className="mx-2">{t("to")}</p>
        <DateInput
          date={search.dateTo || ""}
          setDate={(dateTo) => setSearch({ ...search, dateTo })}
        />
      </div>
    ),
    resetField: () => setSearch(deleteProps(search, ["dateFrom", "dateTo"])),
  },
});
