import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../Components/Sidebar";
import { useAppSelector } from "../../redux/reduxHooks";
import { NoAccessComponent } from "../../Components/Common/NoAccessComponent";
import { ApprovalsTable } from "../../Components/Tables/ApprovalsTable";
import { useParams } from "react-router-dom";

type Params = {
  farmUuid: string;
  farmRegistrationNumber: string;
  count: string;
  processingStarted: string;
};

export const ApprovalsByGroupPage: FC = () => {
  const { t } = useTranslation();
  const { farmUuid, farmRegistrationNumber, count, processingStarted } =
    useParams<Params>() as Params;
  const hasPrivilegeR = useAppSelector(
    (state) => state.user.privileges.APPROVAL_R
  );

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t("ApprovalsByGroupPage")}`}</h1>
      </div>
      <div className="flex p-3">
        <Sidebar />
        {hasPrivilegeR ? (
          <div className="full-width">
            <ApprovalsTable
              farmUuid={farmUuid}
              farmRegistrationNumber={farmRegistrationNumber}
              count={+count}
              processingStarted={processingStarted === "true"}
            />
          </div>
        ) : (
          <NoAccessComponent />
        )}
      </div>
    </div>
  );
};
