import { FC } from "react";
import { AlertIcon } from "../../Elements/Alert/Alert";
import { useTranslation } from "react-i18next";

export const NoAccessComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex m-3">
      <AlertIcon type="error" className="icon-3" />
      <h1 className="ml-3">{t("NoAccessMessage")}</h1>
    </div>
  );
};
