export interface IGasStation {
  uuid: string;
  name: string;
  taxId: string;
}

export interface IGasStationSearch {
  fullText?: string;
}

export interface IGasStationProduct {
  uuid: string;
  name: string;
  code: string;
  fuelType: IFuelType;
  gasStation: Partial<IGasStation>;
}

export const FuelTypes = ["GAS_OIL", "BIO_FUEL"] as const;
export type IFuelType = (typeof FuelTypes)[number];

export interface IGasStationProductSearch {
  fullText?: string;
}
