import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import { PesticideProcurementsReport } from "../Components/Sections/Reports/PesticideProcurementsReport";

export const PesticideProcurementsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`PesticideProcurements`)}`}</h1>
      </div>
      <div>
        <div className="flex p-3">
          <Sidebar />
          <div className="full-width">
            <PesticideProcurementsReport />
          </div>
        </div>
      </div>
    </div>
  );
};
