import { ITableColumn, ITableDataItem } from "@inceptionbg/ui-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ILandInfo, ISurfaceObj } from "../../types/ILand";
import { RecapitulationTable } from "./RecapitulationTable";
import { convertSurfaceToObj } from "../../utils/CalculateUtils";
import { SurfaceValue } from "../Inputs/Surface/SurfaceValue";

interface Props {
  landInfo: ILandInfo;
}

export const LandsRecapitulationTable: FC<Props> = ({ landInfo }) => {
  const { t } = useTranslation();

  const surface = convertSurfaceToObj(landInfo.totalSurface);
  const usedSurface = convertSurfaceToObj(landInfo.totalUsedSurface);

  const formatSurfaceValue = (surface: ISurfaceObj) => {
    return `${surface.ha}ha ${
      (surface.a?.length === 1 ? "0" : "") + surface.a
    }a ${(surface.m?.length === 1 ? "0" : "") + surface.m}m²`;
  };

  const columns: ITableColumn[] = [
    {
      id: "name",
      label: `${t("RGZLandSurface")} ${
        landInfo.downloadDate
          ? t("DownloadDateS") + " - " + landInfo.downloadDate
          : ""
      }`,
    },
    {
      id: "surface",
      label:
        landInfo.owners.length && surface ? formatSurfaceValue(surface) : "",
      align: "right",
      width: "110px",
    },
  ];

  const data: ITableDataItem[] = landInfo.owners.map((e, i) => ({
    uuid: i + "",
    cells: {
      name: {
        value: e.ownerName || "",
      },
      surface: {
        value: (
          <SurfaceValue
            valueObj={convertSurfaceToObj(e.ownedSurface || 0)}
            simple
          />
        ),
      },
    },
  }));

  const totalsColumn: ITableColumn[] = [
    {
      id: "name",
      label: t("RPGReportedLandSurface"),
    },
    {
      id: "surface",
      label: usedSurface ? formatSurfaceValue(usedSurface) : "",
      align: "right",
      width: "110px",
    },
  ];

  const totals: ITableDataItem[] = landInfo.usedSurfaces.map((e) => ({
    uuid: e.registrationNumber,
    cells: {
      name: {
        value: `${t("OrgNumberShort")} - ${e.registrationNumber} (${
          e.approved ? t("ApprovedS") : t("VerificationS")
        })`,
      },
      surface: {
        value: (
          <div>
            <SurfaceValue
              valueObj={convertSurfaceToObj(e.usedSurface || 0)}
              simple
            />
            <p>{t(e.usageBasis)}</p>
          </div>
        ),
      },
    },
  }));

  return (
    <RecapitulationTable
      hideHeader={!landInfo.owners.length}
      title={t("RGZDataPreview")}
      tableColumns={columns}
      tableData={data}
      totalsDataHeaderColumn={totalsColumn}
      totalsData={totals}
    />
  );
};
