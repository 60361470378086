import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ITableColumn,
  ITableDataItem,
} from "@inceptionbg/ui-components";
import { Table } from "../../Components/CustomIUI/Table/Table";
import {
  getGasStations,
  saveOrUpdateGasStation,
} from "../../repos/GasStationRepo";
import { IGasStation, IGasStationSearch } from "../../types/IGasStation";
import { Dialog } from "../../Elements/Dialog";
import { GasStationForm } from "./GasStationForm";
import { useAppSelector } from "../../redux/reduxHooks";
import { GasStationsFilters } from "./GasStationsFilters";
import { useNavigate } from "react-router-dom";

export const GasStationsTable: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [details, setDetails] = useState<Partial<IGasStation>>({});
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);

  const [search, setSearch] = useState<IGasStationSearch>({});
  const [searchData, setSearchData] = useState<IGasStationSearch>({});

  const { t } = useTranslation();
  const navigate = useNavigate();
  const hasWPrivilege = useAppSelector(
    (state) => !!state.user.privileges.GAS_STATION_R
  );

  const handleGetGasStations = useCallback(() => {
    setIsLoading(true);
    getGasStations({ limit, offset }, search)
      .then(({ gasStations, totalRows }) => {
        setData(
          gasStations.map((e) => ({
            uuid: e.uuid,
            onRowClick: () => navigate(`/gas-station/${e.uuid}/products`),
            cells: {
              name: {
                value: e.name,
              },
              taxId: {
                value: e.taxId || "",
              },
              actions: {
                value: (
                  <Button
                    label={t("Edit")}
                    onClick={(event) => {
                      event.stopPropagation();
                      setDetails(e);
                      setIsUserFormOpen(true);
                    }}
                    outlined
                    size={"s"}
                  />
                ),
              },
            },
          })) as ITableDataItem[]
        );
        setTotalRows(totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [limit, offset, t, search, navigate]);

  useEffect(handleGetGasStations, [handleGetGasStations]);

  const columns: ITableColumn[] = [
    { id: "name", label: t("Name") },
    { id: "taxId", label: t("TaxId") },
    { id: "actions", label: "", align: "center", unavailable: !hasWPrivilege },
  ];

  const onSearch = (search: IGasStationSearch) => setSearch(search);

  const onSubmit = () => {
    setIsLoading(true);
    saveOrUpdateGasStation(details)
      .then(() => {
        handleGetGasStations();
        setDetails({});
        setIsUserFormOpen(false);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {hasWPrivilege && (
        <div className="flex justify-right">
          <Button
            label={t("AddGasStation")}
            size="s"
            primary
            onClick={() => setIsUserFormOpen(true)}
          />
        </div>
      )}
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        serverSidePagination={{ limit, offset, setLimit, setOffset, totalRows }}
        filterData={{
          columns: ["name"],
          filters: GasStationsFilters(searchData, setSearchData),
          search,
          searchData,
          setSearchData,
          onSubmit: onSearch,
        }}
      />
      <Dialog
        className="over"
        isOpen={isUserFormOpen}
        onClose={() => {
          setDetails({});
          setIsUserFormOpen(false);
        }}
        desc={t(!!details.uuid ? "EditGasStation" : "AddGasStation")}
        children={
          <GasStationForm
            data={details}
            setData={setDetails}
            onSubmit={onSubmit}
          />
        }
        cancelButton={{
          onClick: () => {
            setDetails({});
            setIsUserFormOpen(false);
          },
        }}
      />
    </>
  );
};
