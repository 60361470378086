import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../Components/Sidebar";
import { NoAccessComponent } from "../../Components/Common/NoAccessComponent";
import { useAppSelector } from "../../redux/reduxHooks";
import { GasStationsTable } from "./GasStationsTable";

export const GasStationsPage: FC = () => {
  const { t } = useTranslation();
  const hasAccess = useAppSelector(
    (state) => state.user.privileges.GAS_STATION_R
  );

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`GasStations`)}`}</h1>
      </div>
      <div>
        <div className="flex p-3">
          <Sidebar />
          {hasAccess ? (
            <div className="full-width">
              <GasStationsTable />
            </div>
          ) : (
            <NoAccessComponent />
          )}
        </div>
      </div>
    </div>
  );
};
