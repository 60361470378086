import clsx from "clsx";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/reduxHooks";

export const Sidebar: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const privileges = useAppSelector((state) => state.user.privileges);

  const sidebarLinks: {
    label: string;
    links: {
      label: string;
      url: string;
      hidden?: boolean;
      disabled?: boolean;
    }[];
    url?: string;
    hidden?: boolean;
  }[] = [
    {
      label: t("OpenApprovalFarms"),
      links: [],
      url: "/approvals-grouped",
      hidden: !privileges.APPROVAL_R,
    },
    {
      label: t("NonAdministrativeProcedures"),
      links: [
        {
          label: t("DataChange"),
          url: "/non-administrative-procedures",
          hidden: !privileges.APPROVAL_R,
        },
      ],
      hidden: !privileges.APPROVAL_R,
    },
    {
      label: t("AdministrativeProcedures"),
      links: [
        {
          label: t("DataChange"),
          url: "/administrative-procedures",
          hidden: !privileges.APPROVAL_R,
        },
      ],
      hidden: !privileges.APPROVAL_R,
    },
    {
      label: t("FuelInvoices"),
      url: "/invoices",
      hidden: !privileges.REFRACTION_APPROVAL_R,
      links: [],
    },
    {
      label: t("GasStations"),
      url: "/gas-stations",
      hidden: !privileges.GAS_STATION_R,
      links: [],
    },
    {
      label: t("RefractionRequests"),
      url: "/refraction-requests",
      hidden: !privileges.REFRACTION_APPROVAL_R,
      links: [],
    },
    {
      label: t("LandReports"),
      url: "/land-reports",
      hidden: !privileges.APPROVAL_W,
      links: [],
    },
    {
      label: t("FarmsRegister"),
      url: "/farms-register",
      links: [],
      hidden: !privileges.SEARCH_R,
    },
    {
      label: t("FarmStatusChange"),
      url: "/farm-status-change",
      links: [],
      hidden: !privileges.FARM_STATUS_CHANGE,
    },
    {
      label: t("FarmDataChange"),
      url: "/farm-data-change",
      links: [],
      hidden: !(
        privileges.RENEWAL_DATE_CHANGE ||
        privileges.PERSONAL_IDENTITY_NUMBER_CHANGE ||
        privileges.LEGAL_ENTITY_CHANGE
      ),
    },
    {
      label: t("HolderMemberDataChange"),
      url: "/holder-member-data-change",
      links: [],
      hidden: !privileges.PERSONAL_IDENTITY_NUMBER_CHANGE,
    },
    {
      label: t("OpenCase"),
      url: "/open-case-page",
      links: [],
      hidden: !privileges.APPROVAL_W,
    },
    {
      label: t("ImportExcelFile"),
      url: "/document-upload",
      links: [],
      hidden: !privileges.FARM_STATUS_CHANGE,
    },
    {
      label: t("AdminUsers"),
      url: "/admin-users",
      links: [],
      hidden: !privileges.USERS_R,
    },
    {
      label: t("Reports"),
      url: "/reports",
      links: [],
      hidden: !privileges.SEARCH_R,
    },
    {
      label: t("Pesticides"),
      url: "/pesticides-procurement",
      links: [],
    },
    {
      label: t("PlantProtectionProducts"),
      url: "/plant-protection-products",
      links: [],
    },
  ];

  return (
    <div className="sticky flex column">
      {/*{privileges.APPROVAL_R && (
        <div className="sidebar-header">{t("ProcessingItems")}</div>
      )}*/}
      {sidebarLinks.map((e) => (
        <Fragment key={e.label}>
          {!e.hidden && (
            <div
              className={clsx("sidebar-header", {
                active: !!e.url && pathname.endsWith(e.url),
                clickable: !!e.url,
                "items-header": !e.url,
              })}
              onClick={() => e.url && navigate(e.url)}
            >
              {e.label}
            </div>
          )}
          {e.links.map(
            (l, i) =>
              !l.hidden && (
                <div
                  key={i}
                  className={clsx("sidebar-link", {
                    active: pathname.endsWith(l.url),
                  })}
                  onClick={() => navigate(l.url)}
                >
                  {l.label}
                </div>
              )
          )}
        </Fragment>
      ))}
    </div>
  );
};
