import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../../Elements/Input";
import { formatDate, formatDateAndTime } from "@inceptionbg/ui-components";
import { IRefractionRequest } from "../../../types/IRefraction";
import { SubSection } from "../../../Components/SubSection";

interface Props {
  refractionReq: IRefractionRequest;
}

export const RefractionReq: FC<Props> = ({ refractionReq }) => {
  const { t } = useTranslation();

  return (
    <SubSection
      title={`${formatDate(refractionReq.createdAt)} ${
        refractionReq?.id ? ` - ${refractionReq?.id}` : ""
      }`}
      initialyOpen
    >
      <Input
        component={{
          label: t("CreatedAtTime"),
          value: formatDateAndTime(refractionReq.createdAt),
        }}
      />
      <SubSection
        title={t("Invoices")}
        secondary
        initialyOpen
        expandable={!!refractionReq.invoices?.length}
      >
        {refractionReq.invoices?.map((invoice) => (
          <SubSection
            key={invoice.uuid}
            title={`${formatDate(invoice?.date)} - ${invoice?.number}`}
            expandable={!!invoice.items?.length}
            tertiary
          >
            {invoice.items?.map((item) => (
              <Input
                key={item.uuid}
                component={{
                  label: item.fuelType?.name || "",
                  value: `${item.liter || "0"} l`,
                }}
              />
            ))}
          </SubSection>
        ))}
      </SubSection>
      <SubSection title={t("RequestItems")} initialyOpen expandable secondary>
        {refractionReq.items?.map((item) => (
          <SubSection
            key={item.uuid}
            title={`${
              item.landPlantCulture?.land?.nickname ||
              item.landPlantCulture?.land?.cadastreNumber ||
              ""
            } - ${item.landPlantCulture?.plantCulture?.name || ""}`}
            expandable
            tertiary
          >
            <Input
              component={{
                label: t("Municipality"),
                value: item.landPlantCulture?.land?.cadastreMunicipality.name,
              }}
            />
            <Input
              component={{
                label: t("Land"),
                value:
                  item.landPlantCulture?.land?.nickname ||
                  item.landPlantCulture?.land?.cadastreNumber,
              }}
            />
            <Input
              component={{
                label: t("LandCulture"),
                value: item.landPlantCulture?.plantCulture?.name,
              }}
            />
            <Input
              component={{
                label: item.fuelType?.name || "",
                value: `${item.liter || "0"} l`,
              }}
            />
          </SubSection>
        ))}
      </SubSection>
    </SubSection>
  );
};
