import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  deleteProps,
  formatDate,
  ITableColumn,
  ITableDataItem,
} from "@inceptionbg/ui-components";
import { Table } from "../CustomIUI/Table/Table";
import { IFarmSearch } from "../../types/IFarm";
import { useNavigate } from "react-router-dom";
import { FarmsFilters } from "./FarmsFilters";
import { getFarms } from "../../repos/FarmRepo";

export const FarmsTable: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<IFarmSearch>({});
  const [searchData, setSearchData] = useState<IFarmSearch>({});

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGetFarms = useCallback(() => {
    if (Object.keys(search).length) {
      setIsLoading(true);
      getFarms(search, { limit, offset })
        .then(({ farms, totalRows }) => {
          setData(
            farms.map((e) => ({
              uuid: e.uuid,
              cells: {
                registrationNumber: {
                  value: e.registrationNumber,
                },
                holder: {
                  value:
                    e.holder.type === "physicalPerson"
                      ? `${e.holder.firstName} ${e.holder.lastName}`
                      : e.holder.name,
                },
                status: {
                  value: t(e.status),
                },
                form: {
                  value: e.organizationForm ? e.organizationForm.name : "",
                },
                renewalDate: {
                  value: e.renewalDate ? formatDate(e.renewalDate) : "",
                },
                registrationDate: {
                  value: e.registrationDate
                    ? formatDate(e.registrationDate)
                    : "",
                },
                actions: {
                  value: (
                    <Button
                      label={t("ShowDetails")}
                      onClick={() =>
                        navigate(`/farm/${e.registrationNumber}/details`)
                      }
                      outlined
                      size={"s"}
                    />
                  ),
                },
              },
            })) as ITableDataItem[]
          );
          setTotalRows(totalRows);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    } else {
      setData([]);
      setTotalRows(0);
    }
  }, [limit, offset, search, t, navigate]);

  useEffect(handleGetFarms, [handleGetFarms]);

  const columns: ITableColumn[] = [
    { id: "registrationNumber", label: t("OrgNumberShort") },
    { id: "holder", label: t("Holder") },
    { id: "status", label: t("Status") },
    { id: "form", label: t("Form") },
    { id: "renewalDate", label: t("RenewalDate") },
    { id: "registrationDate", label: t("RegistrationDate") },
    { id: "actions", label: "" },
  ];

  const onSearch = (search: IFarmSearch) => {
    setSearch(deleteProps(search, ["landCadatreMunicipality"]));
    setOffset(0);
  };

  return (
    <Table
      columns={columns}
      data={data}
      filterData={{
        columns: [
          "registrationNumber",
          "holderPersonalIdentityNumber",
          "holderRegistrationNumber",
          "holderName",
          "memberNameLike",
          "memberPersonalIdentityNumber",
          "landCadatreMunicipality",
          "landCadastreNumber",
          "animalHid",
        ],
        filters: FarmsFilters(searchData, setSearchData),
        search,
        searchData,
        setSearchData,
        onSubmit: onSearch,
      }}
      serverSidePagination={{
        limit,
        offset,
        setLimit,
        setOffset,
        totalRows,
      }}
      isLoading={isLoading}
    />
  );
};
