import { Button, toastError } from "@inceptionbg/ui-components";
import { FC } from "react";
import Dropzone, { Accept } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { faFileArrowUp } from "../../../assets/icons/light/faFileArrowUp";

interface Props {
  accept?: Accept;
  onSubmit: (files: File[]) => void;
}

export const FilesDropzone: FC<Props> = ({ accept, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dropzone
        onDrop={(files) => onSubmit(files)}
        accept={accept}
        onDropRejected={() => toastError(t("ErrorUnsupportedFile"))}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="dropzone-container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <div className="flex center gap-2 mb-2">
                <Button
                  label={t("PCUpload")}
                  iconEnd={faFileArrowUp}
                  outlined
                />
              </div>
              <p>{t("justDragAndDropFile")}.</p>
            </div>
          </section>
        )}
      </Dropzone>
    </>
  );
};
