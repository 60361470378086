import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import {
  Button,
  deleteProps,
  Select,
  TextInput,
} from "@inceptionbg/ui-components";
import { FuelTypes, IGasStationProduct } from "../../../types/IGasStation";

interface Props {
  data?: Partial<IGasStationProduct>;
  setData: Dispatch<SetStateAction<Partial<IGasStationProduct>>>;
  onSubmit: () => void;
}

export const GasStationProductForm: FC<Props> = ({
  data,
  setData,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const formInfo: IInputComponentProps[] = [
    {
      label: t("Code"),
      input: (
        <TextInput
          required
          value={data?.code || ""}
          setValue={(e) => setData({ ...data, code: e })}
        />
      ),
    },
    {
      label: t("Name"),
      value: data?.name || "",
      input: (
        <TextInput
          required
          value={data?.name || ""}
          setValue={(e) => setData({ ...data, name: e })}
        />
      ),
    },
    {
      label: t("FuelType"),
      input: (
        <Select
          required
          placeholder={`${t("Choose")}...`}
          isClearable
          value={
            data?.fuelType
              ? {
                  value: data.fuelType,
                  label: t(`FuelType${data.fuelType}`),
                }
              : null
          }
          options={FuelTypes.map((e) => ({
            value: e,
            label: t(`FuelType${e}`),
          }))}
          onChange={(e) => {
            e
              ? setData({ ...data, fuelType: e.value })
              : setData(deleteProps(data, ["fuelType"]));
          }}
        />
      ),
    },
  ];

  return (
    <form
      className="form-container"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {formInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} edit />
      )}
      <div className="flex justify-right">
        <Button label={t("Save")} size="s" className="primary" primary />
      </div>
    </form>
  );
};
