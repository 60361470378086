import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, TextInput } from "@inceptionbg/ui-components";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { SubSection } from "../../../SubSection";
import { changeHolderMemberData } from "../../../../repos/FarmRepo";
import { IHolderMemberDataChange } from "../../../../types/IHolderMemberDataChange";
import { Select } from "../../../Inputs/Select";

export const HolderMemberDataChange: FC = () => {
  const [regNum, setRegNum] = useState("");
  const [data, setData] = useState<IHolderMemberDataChange>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isHolder, setIsHolder] = useState<boolean>();

  const { t } = useTranslation();
  const inputRef = useRef(null);

  const renewalDateInfo: IInputComponentProps[] = [
    {
      label: t("OrgNumberShort"),
      input: (
        <div ref={inputRef} id="on-blur">
          <TextInput required value={regNum} setValue={(e) => setRegNum(e)} />
        </div>
      ),
    },
    {
      label: t("HolderMemberDataChange"),
      input: (
        <Select
          placeholder={`${t("Choose")}...`}
          isClearable
          value={
            typeof isHolder === "boolean"
              ? {
                  value: isHolder,
                  label: t(isHolder ? "Holder" : "Member"),
                }
              : null
          }
          options={[true, false].map((e) => ({
            value: e,
            label: t(e ? "Holder" : "Member"),
          }))}
          onChange={(data) =>
            setIsHolder(
              typeof data?.value === "boolean" ? data.value : undefined
            )
          }
        />
      ),
    },
    {
      label: t("PersonalIdentityNumber"),
      input: (
        <TextInput
          required
          value={data.personalIdentityNumber}
          setValue={(e) => setData({ ...data, personalIdentityNumber: e })}
        />
      ),
      hidden: isHolder ? true : false,
    },
    {
      label: t("FirstName"),
      input: (
        <TextInput
          value={data.firstName}
          setValue={(e) => setData({ ...data, firstName: e })}
        />
      ),
    },
    {
      label: t("LastName"),
      input: (
        <TextInput
          value={data.lastName}
          setValue={(e) => setData({ ...data, lastName: e })}
        />
      ),
    },
  ];

  return (
    <SubSection
      title={t("HolderMemberDataChange")}
      expandable={false}
      isLoading={isLoading}
      className="mb-3"
    >
      <div className="form-container">
        {renewalDateInfo.map(
          (e) => !e.hidden && <Input key={e.label} component={e} edit />
        )}
        <div className="flex justify-right">
          <Button
            label={t("Save")}
            onClick={() => {
              setIsLoading(true);
              changeHolderMemberData(
                regNum,
                isHolder
                  ? { holder: { ...data, type: "physicalPerson" } }
                  : { member: { ...data } }
              )
                .then(() => {
                  setData({});
                  setRegNum("");
                })
                .catch(() => {})
                .finally(() => setIsLoading(false));
            }}
            disabled={!regNum}
            size="s"
            className="primary"
            primary
          />
        </div>
      </div>
    </SubSection>
  );
};
