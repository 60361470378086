import { IPagination } from "@inceptionbg/ui-components";
import { axiosInstance } from "../App";
import {
  IRefractionRequest,
  IRefractionRequestSearch,
  IRefractionRequestSummaryReport,
  RefractionRequestFetchOption,
} from "../types/IRefraction";

export const getRefractionRequests = (
  pagination: IPagination,
  search?: IRefractionRequestSearch,
  fetchOptions?: RefractionRequestFetchOption[]
) =>
  axiosInstance
    .get(`/admin/refraction-requests`, {
      params: {
        ...pagination,
        ...search,
        fetchOptions,
      },
    })
    .then(({ data }) => ({
      refractionRequests: data.refractionRequests as IRefractionRequest[],
      totalRows: data.totalRows as number,
    }));

export const getRefractionRequestByUuid = (refractionRequestUuid: string) =>
  axiosInstance
    .get(`/refraction-request/${refractionRequestUuid}`)
    .then(({ data }) => ({
      refractionRequest: data as IRefractionRequest,
    }));

export const getRefractionRequestSummaryReport = () =>
  axiosInstance
    .get(`/refraction-requests-summary-report`)
    .then(({ data }) => data as IRefractionRequestSummaryReport);
