import { LargeTextInput } from "@inceptionbg/ui-components";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { Dialog } from "../../Elements/Dialog";
import { moveToAdministrativeProcedure } from "../../repos/ApprovalsRepo";
import { useNavigate } from "react-router-dom";
import { Select } from "../Inputs/Select";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  approvalUuid: string;
}

export const MoveTOAdministrativeProcedureDialog: FC<Props> = ({
  isOpen,
  onClose,
  approvalUuid,
}) => {
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const data: IInputComponentProps[] = [
    {
      label: t("ProcessType"),
      value: "RFarmDataChange",
      input: (
        <Select
          required
          value={{ label: t("RFarmDataChange"), value: "RFarmDataChange" }}
          options={["RFarmDataChange"].map((e) => ({ label: t(e), value: e }))}
          onChange={() => {}}
        />
      ),
    },
    {
      label: t("EditorsComment"),
      value: comment,
      input: (
        <LargeTextInput
          required
          placeholder={t("FreeTextPlaceholder")}
          label={""}
          rows={7}
          value={comment}
          setValue={(e) => setComment(e)}
        />
      ),
    },
  ];

  const handleClose = () => {
    setComment("");
    onClose();
  };

  const onSubmit = () => {
    if (!!approvalUuid) {
      setIsLoading(true);
      moveToAdministrativeProcedure(approvalUuid, { comment })
        .then(() => {
          handleClose();
          navigate(`/administrative-procedures`);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={t(`OpenProcedureTitle`)}
      confirmButton={{
        label: t("RunProcedure"),
        onClick: () => {
          onSubmit();
        },
        disabled: !comment,
        keepOpen: true,
      }}
      cancelButton={{ onClick: handleClose }}
      size="m"
      isLoading={isLoading}
    >
      {data.map((e) => !e.hidden && <Input key={e.label} component={e} edit />)}
    </Dialog>
  );
};
