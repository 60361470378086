import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { IApprovalNew } from "../../types/IApprovals";
import { SubSection } from "../SubSection";

interface Props {
  approval: IApprovalNew;
  isChild?: boolean;
}

export const ProcedureStatus: FC<Props> = ({ approval, isChild = false }) => {
  const { t } = useTranslation();

  const formInfo: IInputComponentProps[] = [
    {
      label: t("Status"),
      value: t("ApprovalStatus" + approval.approvalStatus),
    },
    {
      label: t("ApprovalNumber"),
      value: approval.approvalNumber,
    },
  ];

  return (
    <SubSection
      title={t(isChild ? "ChildApprovalInfo" : "ApprovalInfo")}
      expandable={true}
      initialyOpen={false}
    >
      <div className="form-container">
        {formInfo.map(
          (e) => !e.hidden && <Input key={e.label} component={e} />
        )}
      </div>
    </SubSection>
  );
};
