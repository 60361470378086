import {
  DateInput,
  deleteProps,
  ITableFilter,
  Select,
  TextInput,
} from "@inceptionbg/ui-components";
import { t } from "i18next";
import {
  approvalStatuses,
  changedObjects,
  IApprovalSearch,
} from "../../types/IApprovals";
import { landUsageBasis } from "../../types/ILand";
import { SelectAsyncPaginate } from "../Inputs/SelectAsyncPaginate";
import { getUserOptions } from "../../utils/LoadOptions";

export const ApprovalsFilters: (
  search: IApprovalSearch,
  setSearch: (search: IApprovalSearch) => void
) => ITableFilter = (search, setSearch) => ({
  concluded: {
    label: t("Concluded"),
    field: (
      <Select
        placeholder={`${t("Choose")}...`}
        isClearable
        value={
          typeof search.concluded === "boolean"
            ? {
                value: search.concluded,
                label: t(search.concluded ? "Yes" : "No"),
              }
            : null
        }
        options={[true, false].map((e) => ({
          value: e,
          label: t(e ? "Yes" : "No"),
        }))}
        onChange={(data) => {
          typeof data?.value === "boolean"
            ? setSearch({
                ...search,
                concluded: data.value,
              })
            : setSearch(deleteProps(search, ["concluded"]));
        }}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["concluded"])),
  },
  approvalNumber: {
    label: t("ApprovalNumber"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.approvalNumber || ""}
        setValue={(approvalNumber) => setSearch({ ...search, approvalNumber })}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["approvalNumber"])),
  },
  farmRegistrationNumber: {
    label: t("OrgNumberShort"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.farmRegistrationNumber || ""}
        setValue={(farmRegistrationNumber) =>
          setSearch({ ...search, farmRegistrationNumber })
        }
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["farmRegistrationNumber"])),
  },
  changedObject: {
    label: t("ChangedObject"),
    field: (
      <Select
        placeholder={`${t("Choose")}...`}
        isClearable
        value={
          search.changedObject
            ? {
                value: search.changedObject,
                label: t(`ChangedObject${search.changedObject}`),
              }
            : null
        }
        options={changedObjects
          .filter((e) => e !== "INVOICE")
          .map((e) => ({
            value: e,
            label: t(`ChangedObject${e}`),
          }))}
        onChange={(data) => {
          data
            ? setSearch({
                ...search,
                changedObject: data.value,
              })
            : setSearch(deleteProps(search, ["changedObject"]));
        }}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["changedObject"])),
  },
  creationDate: {
    label: `${t("RequestDate")} ${t("from")}`,
    field: (
      <div className="flex-center full-width">
        <DateInput
          date={search.creationDateFrom || ""}
          setDate={(creationDateFrom) =>
            setSearch({ ...search, creationDateFrom })
          }
        />
        <p className="mx-2">{t("to")}</p>
        <DateInput
          date={search.creationDateTo || ""}
          setDate={(creationDateTo) => setSearch({ ...search, creationDateTo })}
        />
      </div>
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["creationDateFrom", "creationDateTo"])),
  },
  statusTime: {
    label: `${t("StatusTime")} ${t("from")}`,
    field: (
      <div className="flex-center full-width">
        <DateInput
          date={search.statusTimeFrom || ""}
          setDate={(statusTimeFrom) => setSearch({ ...search, statusTimeFrom })}
        />
        <p className="mx-2">{t("to")}</p>
        <DateInput
          date={search.statusTimeTo || ""}
          setDate={(statusTimeTo) => setSearch({ ...search, statusTimeTo })}
        />
      </div>
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["statusTimeFrom", "statusTimeTo"])),
  },
  memberLand: {
    label: t("LandShort"),
    field: (
      <Select
        placeholder={`${t("Choose")}...`}
        isClearable
        value={
          typeof search.memberLand === "boolean"
            ? {
                value: search.memberLand,
                label: t(search.memberLand ? "MemberLand" : "HolderLand"),
              }
            : null
        }
        options={[true, false].map((e) => ({
          value: e,
          label: t(e ? "MemberLand" : "HolderLand"),
        }))}
        onChange={(data) => {
          typeof data?.value === "boolean"
            ? setSearch({
                ...search,
                memberLand: data.value,
              })
            : setSearch(deleteProps(search, ["memberLand"]));
        }}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["memberLand"])),
  },
  processorUser: {
    label: t("ProcessorUser"),
    field: (
      <SelectAsyncPaginate
        className="select-paginate select-popup-wide"
        placeholder={`${t("Choose")}...`}
        value={
          search.processorUser
            ? {
                value: search.processorUser.uuid,
                label:
                  search.processorUser.firstName +
                  " " +
                  search.processorUser.lastName,
              }
            : null
        }
        loadOptions={getUserOptions}
        onChange={(e) => {
          setSearch(
            e
              ? {
                  ...search,
                  processorUserUuid: e.value,
                  processorUser: {
                    uuid: e.value,
                    firstName: e.label.substring(
                      0,
                      e.label.lastIndexOf(" ") + 1
                    ),
                    lastName: e.label.substring(
                      e.label.lastIndexOf(" ") + 1,
                      e.label.length
                    ),
                  },
                }
              : deleteProps(search, ["processorUserUuid", "processorUser"])
          );
        }}
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["processorUserUuid", "processorUser"])),
  },
  holderPersonalIdentityNumber: {
    label: t("HolderPersonalIdentityNumber"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.holderPersonalIdentityNumber || ""}
        setValue={(holderPersonalIdentityNumber) =>
          setSearch({ ...search, holderPersonalIdentityNumber })
        }
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["holderPersonalIdentityNumber"])),
  },
  holderRegistrationNumber: {
    label: t("RegistrationNumber"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.holderRegistrationNumber || ""}
        setValue={(holderRegistrationNumber) =>
          setSearch({ ...search, holderRegistrationNumber })
        }
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["holderRegistrationNumber"])),
  },
  landUsageBasis: {
    label: t("LandUsageBasis"),
    field: (
      <Select
        placeholder={`${t("Choose")}...`}
        isClearable
        value={
          search.landUsageBasis
            ? {
                value: search.landUsageBasis,
                label: t(`${search.landUsageBasis}`),
              }
            : null
        }
        options={landUsageBasis.map((e) => ({
          value: e,
          label: t(`${e}`),
        }))}
        onChange={(data) => {
          data
            ? setSearch({
                ...search,
                landUsageBasis: data.value,
              })
            : setSearch(deleteProps(search, ["landUsageBasis"]));
        }}
        openDirection="top"
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["landUsageBasis"])),
  },
  approvalStatus: {
    label: t("Status"),
    field: (
      <Select
        placeholder={`${t("Choose")}...`}
        isClearable
        value={
          search.approvalStatus
            ? {
                value: search.approvalStatus,
                label: t(`ApprovalStatus${search.approvalStatus}`),
              }
            : null
        }
        options={approvalStatuses.map((e) => ({
          value: e,
          label: t(`ApprovalStatus${e}`),
        }))}
        onChange={(data) => {
          data
            ? setSearch({
                ...search,
                approvalStatus: data.value,
              })
            : setSearch(deleteProps(search, ["approvalStatus"]));
        }}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["approvalStatus"])),
  },
});
