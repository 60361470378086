import {
  deleteProps,
  ITableFilter,
  TextInput,
} from "@inceptionbg/ui-components";
import { t } from "i18next";
import { IFarmSearch } from "../../types/IFarm";
import { SelectAsyncPaginate } from "../Inputs/SelectAsyncPaginate";
import { getCadastreMunicipalityOptions } from "../../utils/LoadOptions";

export const FarmsFilters: (
  search: IFarmSearch,
  setSearch: (search: IFarmSearch) => void
) => ITableFilter = (search, setSearch) => ({
  registrationNumber: {
    label: t("OrgNumberShort"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.registrationNumber || ""}
        setValue={(registrationNumber) =>
          setSearch({ ...search, registrationNumber })
        }
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["registrationNumber"])),
  },
  holderPersonalIdentityNumber: {
    label: t("HolderPersonalIdentityNumber"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.holderPersonalIdentityNumber || ""}
        setValue={(holderPersonalIdentityNumber) =>
          setSearch({ ...search, holderPersonalIdentityNumber })
        }
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["holderPersonalIdentityNumber"])),
  },
  holderRegistrationNumber: {
    label: t("HolderRegistrationNumber"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.holderRegistrationNumber || ""}
        setValue={(holderRegistrationNumber) =>
          setSearch({ ...search, holderRegistrationNumber })
        }
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["holderRegistrationNumber"])),
  },
  holderName: {
    label: t("HolderName"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.holderName || ""}
        setValue={(holderName) => setSearch({ ...search, holderName })}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["holderName"])),
  },
  memberNameLike: {
    label: t("MemberName"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.memberNameLike || ""}
        setValue={(memberNameLike) => setSearch({ ...search, memberNameLike })}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["memberNameLike"])),
  },
  memberPersonalIdentityNumber: {
    label: t("MemberPersonalIdentityNumber"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.memberPersonalIdentityNumber || ""}
        setValue={(memberPersonalIdentityNumber) =>
          setSearch({ ...search, memberPersonalIdentityNumber })
        }
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["memberPersonalIdentityNumber"])),
  },
  landCadatreMunicipality: {
    label: t("CadastreMunicipalityNumber"),
    field: (
      <div className="flex align-center gap-2 full-width min-w-500">
        <SelectAsyncPaginate
          className="select-paginate select-popup-wide"
          required={!!search.atLeastOneLandWithCadastreNumber}
          menuWidth="fit-content"
          placeholder={`${t("Choose")}...`}
          value={
            search.atLeastOnelandWithMunicipality
              ? {
                  value: search.atLeastOnelandWithMunicipality.uuid,
                  label: search.atLeastOnelandWithMunicipality.name,
                }
              : null
          }
          loadOptions={getCadastreMunicipalityOptions}
          onChange={(e) => {
            setSearch(
              e
                ? {
                    ...search,
                    atLeastOnelandWithMunicipalityUuid: e.value,
                    atLeastOnelandWithMunicipality: {
                      uuid: e.value,
                      name: e.label,
                    },
                  }
                : deleteProps(search, [
                    "atLeastOnelandWithMunicipalityUuid",
                    "atLeastOnelandWithMunicipality",
                  ])
            );
          }}
        />
        <p>{t("number")}</p>
        <TextInput
          placeholder={t("AddInput") + "..."}
          required={!!search.atLeastOnelandWithMunicipalityUuid}
          value={search.atLeastOneLandWithCadastreNumber || ""}
          setValue={(atLeastOneLandWithCadastreNumber) =>
            setSearch({ ...search, atLeastOneLandWithCadastreNumber })
          }
        />
      </div>
    ),
    resetField: () =>
      setSearch(
        deleteProps(search, [
          "atLeastOnelandWithMunicipalityUuid",
          "atLeastOnelandWithMunicipality",
          "atLeastOneLandWithCadastreNumber",
        ])
      ),
  },
  // landCadastreNumber: {
  //   label: t("CadastreNumber"),
  //   field: (
  //     <TextInput
  //       placeholder={t("AddInput") + "..."}
  //       value={search.atLeastOneLandWithCadastreNumber || ""}
  //       setValue={(atLeastOneLandWithCadastreNumber) =>
  //         setSearch({ ...search, atLeastOneLandWithCadastreNumber })
  //       }
  //     />
  //   ),
  //   resetField: () =>
  //     setSearch(deleteProps(search, ["atLeastOneLandWithCadastreNumber"])),
  // },
  animalHid: {
    label: t("HidShort"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.atLeastOneAnimalWithHid || ""}
        setValue={(atLeastOneAnimalWithHid) =>
          setSearch({ ...search, atLeastOneAnimalWithHid })
        }
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["atLeastOneAnimalWithHid"])),
  },
});
