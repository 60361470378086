import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { IFarm } from "../../types/IFarm";
import { FarmStatusHistoryTable } from "../Tables/FarmStatusHistoryTable";

interface Props {
  farm: IFarm;
  isOpen: boolean;
  onClose: () => void;
}

export const FarmStatusHistoryDialog: FC<Props> = ({
  farm,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("FarmStatusHistoryChange")}
      size="l"
      isOpen={isOpen}
      onClose={onClose}
    >
      <FarmStatusHistoryTable farm={farm} />
    </Dialog>
  );
};
