import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import { FarmStatusChangeExcel } from "../Components/Files/FarmStatusChangeExcel";

export const UploadDocumentPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`ImportExcelFile`)}`}</h1>
      </div>
      <div className="flex p-3">
        <Sidebar />
        <div className="form-container">
          <FarmStatusChangeExcel />
        </div>
      </div>
    </div>
  );
};
