import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ConditionalWrapper } from "@inceptionbg/ui-components";
import clsx from "clsx";
import { FC, ReactNode, useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { faArrowLeft } from "../assets/icons/regular/faArrowLeft";
import { faTriangleExclamation } from "../assets/icons/regular/faTriangleExclamation";
import { faXmark } from "../assets/icons/regular/faXmark";
import { Loader } from "./Loader";

interface Props {
  title?: string;
  desc?: string;
  descEl?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  confirmButton?: {
    label?: string;
    onClick?: () => void;
    onFormSubmit?: () => void;
    okButton?: boolean;
    disabled?: boolean;
    outlined?: boolean;
    keepOpen?: boolean;
  };
  cancelButton?: {
    label?: string;
    onClick?: () => void;
    hideIcon?: boolean;
  };
  error?: boolean;
  noBackgroundClick?: boolean;
  className?: string;
  isLoading?: boolean;
  size?: "s" | "m" | "l";
  children?: ReactNode;
}

export const Dialog: FC<Props> = ({
  title,
  desc,
  descEl,
  isOpen,
  onClose,
  confirmButton,
  cancelButton,
  error,
  noBackgroundClick,
  className,
  isLoading,
  size,
  children,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-overflow");
      return () => document.body.classList.remove("no-overflow");
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    dialogRef.current?.classList.add("closing");
    setTimeout(onClose, 200);
  }, [dialogRef, onClose]);

  useEffect(() => {
    if (!noBackgroundClick) {
      const handleClick = (ev: MouseEvent) =>
        ev.target === dialogRef.current && handleClose();
      window.addEventListener("click", handleClick);
      return () => window.removeEventListener("click", handleClick);
    }
  }, [noBackgroundClick, handleClose]);

  useEffect(() => {
    const handleKeypress = (ev: KeyboardEvent) =>
      ev.key === "Escape" && handleClose();
    window.addEventListener("keydown", handleKeypress);
    return () => window.removeEventListener("keydown", handleKeypress);
  }, [handleClose]);

  return isOpen
    ? createPortal(
        <div ref={dialogRef} className="dialog">
          <div
            className={clsx("dialog-container", className, {
              error,
              [`size-${size}`]: size,
            })}
          >
            <Loader isLoading={!!isLoading}>
              <>
                <div className="flex full-width justify-right">
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="close-btn icon-3"
                    onClick={handleClose}
                  />
                </div>
                {error ? (
                  <div className="flex gap-5 justify-center full-width">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="color-error icon-3"
                    />
                    <h1 className="mr-5">{t("ErrorTitle")}</h1>
                  </div>
                ) : (
                  title && <h1 className="new-line">{title}</h1>
                )}
                {desc && <p className="desc">{desc}</p>}
                {descEl && <div className="desc2">{descEl}</div>}
                <div className="flex column gap-5">
                  <ConditionalWrapper
                    condition={!!confirmButton?.onFormSubmit}
                    wrapper={(children) => (
                      <form
                        className="form-container full-width"
                        onSubmit={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          confirmButton!.onFormSubmit!();
                          !confirmButton!.keepOpen && handleClose();
                        }}
                      >
                        {children}
                      </form>
                    )}
                  >
                    <>
                      {children}
                      {!!confirmButton && (
                        <div className="dialog-actions">
                          {cancelButton && (
                            <Button
                              label={cancelButton?.label || t("Back")}
                              onClick={cancelButton?.onClick || handleClose}
                              size="l"
                              icon={
                                cancelButton.hideIcon ? undefined : faArrowLeft
                              }
                            />
                          )}
                          <Button
                            label={
                              confirmButton.label ||
                              t(confirmButton.okButton ? "Ok" : "Confirm")
                            }
                            onClick={
                              confirmButton.okButton
                                ? handleClose
                                : !!confirmButton.onClick
                                ? (e) => {
                                    e.stopPropagation();
                                    confirmButton.onClick!();
                                    !confirmButton.keepOpen && handleClose();
                                  }
                                : undefined
                            }
                            primary={!confirmButton.outlined}
                            outlined={confirmButton.outlined}
                            size="l"
                            disabled={confirmButton.disabled}
                          />
                        </div>
                      )}
                    </>
                  </ConditionalWrapper>
                </div>
              </>
            </Loader>
          </div>
        </div>,
        document.body
      )
    : null;
};
