import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, TextInput } from "@inceptionbg/ui-components";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { SubSection } from "../../../SubSection";
import {
  changeFarmData,
  getFarmByRegistrationNumber,
} from "../../../../repos/FarmRepo";
import { IHolderNew, IHolderType } from "../../../../types/IHolder";

const defaultDataValue = { type: "legalEntity" as IHolderType };

export const FarmLegalEntityChange: FC = () => {
  const [regNum, setRegNum] = useState("");
  const [data, setData] = useState<IHolderNew>(defaultDataValue);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const inputRef = useRef(null);

  useEffect(() => {
    const handleFocusOut = (event: any) => {
      // @ts-ignore
      const regNumValue = inputRef.current?.querySelector("input")?.value;
      if (regNumValue === event.target?.value && regNumValue.length === 12) {
        getFarmByRegistrationNumber(regNumValue, {
          fetchOptions: ["HOLDER"],
        })
          .then(({ farm }) =>
            setData({
              ...defaultDataValue,
              taxId: farm.holder.taxId,
              registrationNumber: farm.holder.registrationNumber,
              jbkjs: farm.holder.jbkjs,
              dedicatedRegisterNumber: farm.holder.dedicatedRegisterNumber,
            })
          )
          .catch(() => {});
      }
    };

    document.addEventListener("focusout", handleFocusOut);

    return () => {
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, [inputRef]);

  const renewalDateInfo: IInputComponentProps[] = [
    {
      label: t("OrgNumberShort"),
      input: (
        <div ref={inputRef} id="on-blur">
          <TextInput required value={regNum} setValue={(e) => setRegNum(e)} />
        </div>
      ),
    },
    {
      label: t("TaxId"),
      input: (
        <TextInput
          required
          value={data.taxId}
          setValue={(e) => setData({ ...data, taxId: e })}
        />
      ),
    },
    {
      label: t("RegistrationNumber"),
      input: (
        <TextInput
          required
          value={data.registrationNumber}
          setValue={(e) => setData({ ...data, registrationNumber: e })}
        />
      ),
    },
    {
      label: t("Jbkjs"),
      input: (
        <TextInput
          value={data.jbkjs}
          setValue={(e) => setData({ ...data, jbkjs: e })}
        />
      ),
    },
    {
      label: t("DedicatedRegisterNumber"),
      input: (
        <TextInput
          value={data.dedicatedRegisterNumber}
          setValue={(e) => setData({ ...data, dedicatedRegisterNumber: e })}
        />
      ),
    },
  ];

  return (
    <SubSection
      title={t("LegalEntityChange")}
      expandable={false}
      isLoading={isLoading}
      className="mb-3"
    >
      <div className="form-container">
        {renewalDateInfo.map(
          (e) => !e.hidden && <Input key={e.label} component={e} edit />
        )}
        <div className="flex justify-right">
          <Button
            label={t("Save")}
            onClick={() => {
              setIsLoading(true);
              changeFarmData(regNum, { holder: data })
                .then(() => {
                  setData(defaultDataValue);
                  setRegNum("");
                })
                .catch(() => {})
                .finally(() => setIsLoading(false));
            }}
            disabled={!regNum || !data.taxId || !data.registrationNumber}
            size="s"
            className="primary"
            primary
          />
        </div>
      </div>
    </SubSection>
  );
};
