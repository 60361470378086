import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";

interface Props {
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
  data: number;
}

export const ImportExcelDialog: FC<Props> = ({
  open,
  onClose,
  isLoading,
  data,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("ExcelImportTitle")}
      size="s"
      isOpen={open}
      onClose={onClose}
      isLoading={isLoading}
      confirmButton={{ okButton: true }}
    >
      {t("RowsNumberChanged")}: {data}
    </Dialog>
  );
};
