import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  formatDate,
  ITableColumn,
  ITableDataItem,
} from "@inceptionbg/ui-components";
import {
  getRefractionRequestByUuid,
  getRefractionRequests,
} from "../../repos/RefractionRequestsRepo";
import { Table } from "../../Components/CustomIUI/Table/Table";
import { RefractionReq } from "./Request/RefactionReq";
import {
  IRefractionRequestSearch,
  IRefractionRequest,
} from "../../types/IRefraction";
import { RefractionRequestsFilters } from "./RefractionRequestsFilters";

export const RefractionRequestsTable: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [refractionDetails, setRefractionDetails] =
    useState<IRefractionRequest>({});

  const [search, setSearch] = useState<IRefractionRequestSearch>({});
  const [searchData, setSearchData] = useState<IRefractionRequestSearch>({});

  const { t } = useTranslation();

  const handleGetRefractionRequests = useCallback(() => {
    setIsLoading(true);
    getRefractionRequests({ limit, offset }, search, ["FARM"])
      .then(({ refractionRequests, totalRows }) => {
        setData(
          refractionRequests.map((e) => ({
            uuid: e.uuid,
            cells: {
              farmNumberShort: {
                value: e.farm?.registrationNumber || "",
              },
              caseNumber: {
                value: e.caseNumber || "",
              },
              createdAt: {
                value: formatDate(e.createdAt || ""),
              },
              status: {
                value: `${t(`RefactionStatus${e.status}`)}` || "",
              },
              actions: {
                value: (
                  <Button
                    label={t("ShowDetails")}
                    onClick={() =>
                      getRefractionRequestByUuid(e.uuid!).then((data) =>
                        setRefractionDetails(data.refractionRequest)
                      )
                    }
                    outlined
                    size={"s"}
                  />
                ),
              },
            },
          })) as ITableDataItem[]
        );
        setTotalRows(totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [limit, offset, t, search]);

  useEffect(handleGetRefractionRequests, [handleGetRefractionRequests]);

  const columns: ITableColumn[] = [
    { id: "farmNumberShort", label: t("OrgNumberShort") },
    { id: "caseNumber", label: t("CaseNumber") },
    { id: "createdAt", label: t("CreatedAtTime") },
    { id: "status", label: t("Status") },
    { id: "actions", label: "" },
  ];

  const onSearch = (search: IRefractionRequestSearch) => setSearch(search);

  return (
    <>
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        serverSidePagination={{ limit, offset, setLimit, setOffset, totalRows }}
        filterData={{
          columns: ["farmRegistrationNumber", "caseNumber", "status"],
          filters: RefractionRequestsFilters(searchData, setSearchData),
          search,
          searchData,
          setSearchData,
          onSubmit: onSearch,
        }}
        footerAction={{
          onClick: handleGetRefractionRequests,
        }}
      />
      <Dialog
        size="l"
        isOpen={!!refractionDetails.uuid}
        onClose={() => setRefractionDetails({})}
        title={t("RefractionTitle")}
        children={<RefractionReq refractionReq={refractionDetails} />}
        cancelButton={{ onClick: () => setRefractionDetails({}) }}
      />
    </>
  );
};
