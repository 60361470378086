import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../../Components/Sidebar";
import { NoAccessComponent } from "../../../Components/Common/NoAccessComponent";
import { useAppSelector } from "../../../redux/reduxHooks";
import { GasStationProductsTable } from "./GasStationProductsTable";
import { useParams } from "react-router-dom";
import { getGasStationByUuid } from "../../../repos/GasStationRepo";
import { IGasStation } from "../../../types/IGasStation";

type Params = { gasStationUuid: string };
export const GasStationProductsPage: FC = () => {
  const [gasStation, setGasStation] = useState<IGasStation | null>(null);

  const { t } = useTranslation();
  const { gasStationUuid } = useParams<Params>() as Params;
  const hasAccess = useAppSelector(
    (state) => state.user.privileges.GAS_STATION_R
  );

  useEffect(() => {
    getGasStationByUuid(gasStationUuid)
      .then(setGasStation)
      .catch(() => {});
  }, [gasStationUuid]);

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`GasStationProducts`)}: ${
          gasStation?.name
        }`}</h1>
      </div>
      <div>
        <div className="flex p-3">
          <Sidebar />
          {hasAccess ? (
            <div className="full-width">
              {gasStationUuid && (
                <GasStationProductsTable gasStationUuid={gasStationUuid} />
              )}
            </div>
          ) : (
            <NoAccessComponent />
          )}
        </div>
      </div>
    </div>
  );
};
