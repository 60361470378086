import { FC, useCallback, useEffect, useState } from "react";
import { SubSection } from "../SubSection";
import { IUpdateApprovalEntityData } from "../../types/IApprovals";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { ICulture, ILand, ILandInfo } from "../../types/ILand";
import { SurfaceValue } from "../Inputs/Surface/SurfaceValue";
import { Button, deleteProps, formatDate } from "@inceptionbg/ui-components";
import {
  calculateSurfaceFromObj,
  convertSurfaceToObj,
} from "../../utils/CalculateUtils";
import { LandCultureForm } from "../Sections/Land/LandCultureForm";
import {
  getLandCultures,
  getLandInfo,
  updateLandInfoRGZ,
} from "../../repos/LandRepo";
import { LandsRecapitulationTable } from "../Recapitulation/LandsRecapitulationTable";
import { IFarm } from "../../types/IFarm";
import { PartLandEvidenceDialog } from "../Dialogs/PartLandEvidenceDialog";
import { Form } from "../Wrapper/FormWrapper";
import { SurfaceInput } from "../Inputs/Surface/SurfaceInput";
import { SelectAsyncPaginate } from "../Inputs/SelectAsyncPaginate";
import { getCadastreMunicipalityOptions } from "../../utils/LoadOptions";

interface Props {
  land: ILand;
  isUpdateLoading?: boolean;
  editable?: boolean;
  initiallyOpen?: boolean;
  isMemberSubsection?: boolean;
  farm?: IFarm;
  hideUpdateInfoRgz?: boolean;
  onSubmit?: (data: IUpdateApprovalEntityData) => void;
}

export const Land: FC<Props> = ({
  land,
  isUpdateLoading,
  editable,
  initiallyOpen,
  isMemberSubsection,
  farm,
  hideUpdateInfoRgz = false,
  onSubmit,
}) => {
  const [cultures, setCultures] = useState<ICulture[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [landInfoData, setLandInfoData] = useState<ILandInfo>();
  const [isOpenLandPartDialog, setIsOpenLandPartDialog] = useState(false);

  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState<Partial<ILand>>({});

  const { t } = useTranslation();
  const surfaceObj = convertSurfaceToObj(land.surface);
  const usedSurfaceObj = convertSurfaceToObj(land.usedSurface);
  const cadastreTotalSurfaceObj = convertSurfaceToObj(
    land.cadastreTotalSurface || 0
  );

  const enableUpdate = [
    "LEASE",
    "LEASE_CONSOLIDATION",
    "LEASE_LEGAL_ENTITY",
  ].includes(land.usageBasis);

  const disableRGZUpdate =
    ["CONSOLIDATION", "LEASE_CONSOLIDATION"].includes(land.usageBasis) ||
    hideUpdateInfoRgz;

  // eslint-disable-next-line
  useEffect(() => setFormData({ ...land, surfaceObj }), [land]);

  const handleGetLandCultures = useCallback(() => {
    if (!!land.uuid && !isMemberSubsection) {
      setIsLoading(true);
      getLandCultures(land.uuid)
        .then((e) => {
          setCultures(e.cultures);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [land.uuid, isMemberSubsection]);

  const handleGetLandInfo = useCallback(
    (callback?: () => void) => {
      if (!!land.uuid) {
        getLandInfo(land.uuid)
          .then((e) => {
            setLandInfoData(e);
            callback && callback();
          })
          .catch(() => {});
      }
    },
    [land.uuid]
  );

  const handleUpdateLandInfoRGZ = () => {
    if (!!land.uuid) {
      setIsLoading(true);
      updateLandInfoRGZ(land.uuid)
        .then(() => {
          handleGetLandInfo(() => setIsLoading(false));
        })
        .catch(() => setIsLoading(false))
        .finally(() => {});
    }
  };

  const landInfo: IInputComponentProps[] = [
    {
      label: t("LandName"),
      value: land.nickname,
    },
    {
      label: t("CadastreMunicipality"),
      value: land.cadastreMunicipality?.name || "",
      input: enableUpdate ? (
        <SelectAsyncPaginate
          required
          value={
            formData.cadastreMunicipality
              ? {
                  label: formData.cadastreMunicipality.name,
                  value: formData.cadastreMunicipality.uuid,
                }
              : null
          }
          loadOptions={getCadastreMunicipalityOptions}
          onChange={(e) => {
            setFormData(
              e
                ? {
                    ...formData,
                    cadastreMunicipality: { name: e.label, uuid: e.value },
                  }
                : deleteProps(formData, ["cadastreMunicipality"])
            );
          }}
        />
      ) : undefined,
    },
    {
      label: t("CadastreNumber"),
      value: land.cadastreNumber,
    },
    {
      label: t("PartLand"),
      valueElement: (
        <Button
          label={t("ShowLandPartEvidence")}
          outlined
          size="s"
          onClick={() => setIsOpenLandPartDialog(true)}
        />
      ),
      hidden: land.usageBasis === "CONSOLIDATION",
    },
    {
      label: t("Surface"),
      valueElement: <SurfaceValue valueObj={surfaceObj} />,
      input:
        enableUpdate || land.usageBasis === "CONSOLIDATION" ? (
          <SurfaceInput
            formData={formData}
            setFormData={setFormData}
            value="surfaceObj"
            required
          />
        ) : undefined,
    },
    {
      label: t("UsageBasis"),
      value:
        land.usageBasis && land.leaseType
          ? `${land.leaseType.name}
${land.member ? ` (${land.member.firstName} ${land.member.lastName}` : ""}${
              land.usageBasis === "CONSOLIDATION" ||
              land.usageBasis === "LEASE_CONSOLIDATION" ||
              land.usageBasis === "LEASE_LEGAL_ENTITY"
                ? `${!land.member ? "(" : " - "}${t(land.usageBasis)})`
                : `${!land.member ? "" : ")"}`
            }`
          : `${land.usageBasis ? t(land.usageBasis) : ""}${
              land.member
                ? ` (${land.member.firstName} ${land.member.lastName})`
                : ""
            }`,
    },
    {
      label: t("UsageBasisUPZ"),
      value: land.upzBase?.name || "/",
      readOnly: true,
      hidden: land.usageBasis !== "UPZ",
    },
    {
      label: t("LeaseEndDate"),
      value: formatDate(land.leaseEndDate),
      hidden:
        land.usageBasis !== "LEASE" &&
        land.usageBasis !== "LEASE_LEGAL_ENTITY" &&
        land.usageBasis !== "LEASE_CONSOLIDATION",
    },
    {
      label: t("UsedSurface"),
      valueElement: <SurfaceValue valueObj={usedSurfaceObj} />,
    },
    {
      label: t("CadastreTotalSurface"),
      valueElement: <SurfaceValue valueObj={cadastreTotalSurfaceObj} />,
    },
    {
      label: t("LessorIdentificationNumber"),
      value: land.lessorIdentification,
      hidden:
        land.usageBasis !== "LEASE" &&
        land.usageBasis !== "LEASE_LEGAL_ENTITY" &&
        land.usageBasis !== "LEASE_CONSOLIDATION",
    },
    {
      label: t("HolderPermanent"),
      value: `${farm?.permanentHolder?.firstName} ${farm?.permanentHolder?.lastName}`,
      hidden: farm?.permanentHolder === undefined,
    },
  ];

  return (
    <SubSection
      titleMulti={[
        land.cadastreMunicipality?.name || "",
        `${t("CadastreNumberShort")} ${land.cadastreNumber}`,
        !isMemberSubsection
          ? `${surfaceObj?.ha || 0}ha ${surfaceObj?.a || 0}a ${
              surfaceObj?.m || 0
            }m²`
          : "",
      ]}
      isLoading={isLoading}
      expandable
      tertiary={isMemberSubsection}
      onOpen={() => {
        handleGetLandCultures();
        handleGetLandInfo();
      }}
      initialyOpen={!!initiallyOpen}
    >
      <div className="form-container">
        {!editable ? (
          landInfo.map(
            (e) => !e.hidden && <Input key={e.label} component={e} />
          )
        ) : (
          <Form
            data={landInfo}
            edit={edit}
            setEdit={setEdit}
            submitButton={{
              hidden: !(enableUpdate || land.usageBasis === "CONSOLIDATION"),
              editLabel: t(edit ? "Save" : "Edit"),
              onSubmit: () => {
                setEdit(false);
                onSubmit &&
                  onSubmit({
                    land: {
                      surface: formData.surfaceObj
                        ? calculateSurfaceFromObj(formData.surfaceObj)
                        : formData.surface,
                      cadastreMunicipality:
                        land.usageBasis !== "CONSOLIDATION" &&
                        !!formData.cadastreMunicipality
                          ? { uuid: formData.cadastreMunicipality.uuid }
                          : undefined,
                      uuid: land.uuid,
                    },
                  });
              },
            }}
            onResetForm={() => setFormData({ ...land, surfaceObj })}
            isLoading={!!isUpdateLoading}
          />
        )}
      </div>
      {!disableRGZUpdate && (
        <div className="mt-3 flex justify-right text-right">
          <Button
            label={t("UpdateDataFromRGZ")}
            onClick={handleUpdateLandInfoRGZ}
            primary
            size="s"
          />
        </div>
      )}
      {!!landInfoData && (
        <div className="mt-4">
          <LandsRecapitulationTable landInfo={landInfoData} />
        </div>
      )}
      {!isMemberSubsection && (
        <SubSection title={t("LandCulturesTitle")} secondary>
          <div className="form-container">
            {!!cultures?.length &&
              !isLoading &&
              cultures.map((culture) => {
                const cultureSurfaceObj = convertSurfaceToObj(culture.surface);
                return (
                  <SubSection
                    key={culture.uuid}
                    titleMulti={[
                      culture.plantCulture.name,
                      `${cultureSurfaceObj?.ha || 0}ha ${
                        cultureSurfaceObj?.a || 0
                      }a ${cultureSurfaceObj?.m || 0}m²`,
                    ]}
                    tertiary
                    expandable
                  >
                    <LandCultureForm culture={culture} />
                  </SubSection>
                );
              })}
          </div>
        </SubSection>
      )}
      {isOpenLandPartDialog && (
        <PartLandEvidenceDialog
          land={land}
          isOpen={isOpenLandPartDialog}
          onClose={() => setIsOpenLandPartDialog(false)}
        />
      )}
    </SubSection>
  );
};
