import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import { HolderMemberDataChange } from "../Components/Sections/SubSections/HolderMamberDataChanges/HolderMemberDataChange";
import { useAppSelector } from "../redux/reduxHooks";
import { NoAccessComponent } from "../Components/Common/NoAccessComponent";

export const HolderMemberDataChanges: FC = () => {
  const { t } = useTranslation();

  const hasAccess = useAppSelector(
    (state) => state.user.privileges.PERSONAL_IDENTITY_NUMBER_CHANGE
  );

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`HolderMemberDataChange`)}`}</h1>
      </div>
      <div className="flex p-3">
        <Sidebar />
        {hasAccess ? <HolderMemberDataChange /> : <NoAccessComponent />}
      </div>
    </div>
  );
};
