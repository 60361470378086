import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../Section";
import { IFarm } from "../../../types/IFarm";
import { IAuthorizedPerson } from "../../../types/IAuthorizedPerson";
import { getAuthorizedPersons } from "../../../repos/AuthorizedPersonsRepo";
import { AuthorizedPerson } from "../../Procedures/AuthorizedPerson";

interface Props {
  farm: IFarm;
}

export const AuthorizedPersonsSection: FC<Props> = ({ farm }) => {
  const [authorizedPersons, setAuthorizedPersons] = useState<
    IAuthorizedPerson[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleGetAuthorizedPersons = useCallback(
    (callback?: () => void) => {
      if (farm) {
        setIsLoading(true);
        getAuthorizedPersons(farm.uuid)
          .then((data) => {
            setAuthorizedPersons(data.authorizedPersons);
            callback && callback();
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    },
    [farm]
  );

  useEffect(handleGetAuthorizedPersons, [handleGetAuthorizedPersons]);

  return (
    <Section id="authorizedPersons" title={t("AuthorizedPersonsTitle")}>
      <div className="form-container">
        {authorizedPersons.map((authorizedPerson) => (
          <AuthorizedPerson
            authorizedPerson={authorizedPerson}
            isLoading={isLoading}
          />
        ))}
      </div>
    </Section>
  );
};
