import { toastError } from "@inceptionbg/ui-components";
import i18n from "./i18n";
import { localStorageGet } from "./LocalStorageHelper";

export const downloadDocumentFile = (
  fileName: string,
  url: string,
  type: "pdf" | "xml" | "xlsx",
  apiUrl?: string,
  search?: any
) => {
  return new Promise(function (resolve) {
    let req = new XMLHttpRequest();
    const params =
      search &&
      Object.keys(search)
        .map((key) => key + "=" + search[key])
        .join("&");

    req.open(
      "GET",
      `${apiUrl || process.env.REACT_APP_API_URL}${url}${
        params ? `?${params}` : ""
      }`,
      true
    );
    req.setRequestHeader("Authorization", "Bearer " + localStorageGet("token"));
    req.responseType = "blob";

    req.onload = function () {
      if (req.status === 200) {
        var blob = req.response;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}.${type}`;
        link.click();
        resolve(req.response);
      } else toastError(i18n.t("ErrorMessage"));
    };

    req.send();
  });
};
