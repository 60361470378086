import { Button, toastError } from "@inceptionbg/ui-components";
import { FC, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { faFileArrowUp } from "../../assets/icons/light/faFileArrowUp";
import { changeFarmStatusExcel } from "../../repos/FarmRepo";
import { ImportExcelDialog } from "../Dialogs/ImportExcelDialog";
import { SubSection } from "../SubSection";

export const FarmStatusChangeExcel: FC = () => {
  const [activeFile, setActiveFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [importExcelResponse, setImportExcelResponse] = useState(0);
  const { t } = useTranslation();

  return (
    <SubSection title={t("ImportExcelFile")} expandable={false}>
      {!activeFile && (
        <Dropzone
          onDrop={(file: any) => setActiveFile(file[0])}
          onDropRejected={() => toastError(t("ErrorUnsupportedFile"))}
          accept={{
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlxs"],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="dropzone-container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="flex center gap-2 mb-2">
                  <Button
                    label={t("PCUpload")}
                    outlined
                    iconEnd={faFileArrowUp}
                  />
                </div>
                <p className="dropzone-text">{t("or")}</p>
                <p>{t("justDragAndDropFile")}.</p>
              </div>
            </section>
          )}
        </Dropzone>
      )}
      {activeFile && (
        <div className="flex">
          <p className="pt-1">
            {t("LoadedFile")}: {activeFile.name}
          </p>
          <Button
            label={t("Delete")}
            onClick={() => setActiveFile(undefined)}
            size="s"
            outlined
            className="ml-2"
          />
        </div>
      )}
      <div className="flex justify-right mt-4">
        <Button
          label={t("Save")}
          onClick={() => {
            if (activeFile) {
              setIsLoading(true);
              changeFarmStatusExcel(activeFile!)
                .then((data) => {
                  setImportExcelResponse(data.successfulRows);
                  setActiveFile(undefined);
                })
                .catch(() => {})
                .finally(() => setIsLoading(false));
            }
          }}
          disabled={!activeFile}
          size="s"
          primary
        />
      </div>
      <ImportExcelDialog
        open={!!importExcelResponse}
        data={importExcelResponse}
        isLoading={isLoading}
        onClose={() => setImportExcelResponse(0)}
      />
    </SubSection>
  );
};
