import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { FC } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCircleInfo } from "../../assets/icons/solid/faCircleInfo";
import { faTriangleExclamation } from "../../assets/icons/solid/faTriangleExclamation";
import { IAlertType } from "../../types/IBase";
import { faCheck } from "../../assets/icons/regular/faCheck";

const icons: {
  [key in IAlertType]: IconDefinition;
} = {
  error: faTriangleExclamation,
  info: faCircleInfo,
  success: faCheck,
};

interface Props {
  text: string;
  type: IAlertType;
  className?: string;
}

export const Alert: FC<Props> = ({ text, type, className }) => (
  <div className={clsx("alert", type, className)}>
    <FontAwesomeIcon icon={icons[type]} />
    <p>{text}</p>
  </div>
);

///// ICON /////

interface IconProps {
  type: IAlertType;
  className?: string;
}

export const AlertIcon: FC<IconProps> = ({ type, className }) => {
  return (
    <FontAwesomeIcon
      icon={icons[type]}
      className={clsx("alert-icon icon-2", type, className)}
    />
  );
};
