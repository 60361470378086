import { FC } from "react";
import { SubSection } from "../SubSection";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { IInvoice } from "../../types/IInvoice";
import { formatDate } from "@inceptionbg/ui-components";

interface Props {
  invoice: IInvoice;
  initiallyOpen?: boolean;
  isChild?: boolean;
}

export const Invoice: FC<Props> = ({
  invoice = {},
  initiallyOpen,
  isChild = false,
}) => {
  const { t } = useTranslation();

  const invoiceData: IInputComponentProps[] = [
    {
      label: t("InvoiceNumber"),
      value: invoice?.number,
    },
    {
      label: t("InvoiceDate"),
      value: formatDate(invoice.date),
    },
    {
      label: t("SellerTaxId"),
      value: invoice?.sellerTaxId,
    },
  ];

  return (
    <SubSection
      title={t(isChild ? "ChildFuelInvoice" : "FuelInvoice")}
      expandable={true}
      initialyOpen={!!initiallyOpen}
    >
      <div className="form-container">
        {invoiceData.map(
          (e) => !e.hidden && <Input key={e.label} component={e} />
        )}
      </div>

      {!!invoice?.items?.length && (
        <SubSection title={t("InvoiceItems")} expandable className="pt-0">
          {invoice.items.map((item) => (
            <Input
              key={item.uuid}
              component={{
                label: item.fuelType?.name || "",
                value: `${item.liter || "0"} l`,
              }}
            />
          ))}
        </SubSection>
      )}
    </SubSection>
  );
};
