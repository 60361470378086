import { FC } from "react";
import { SubSection } from "../SubSection";
import { IApprovalNew } from "../../types/IApprovals";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";

interface Props {
  approval: IApprovalNew;
  isChild?: boolean;
}

export const Registration: FC<Props> = ({ approval = {}, isChild = false }) => {
  const { t } = useTranslation();

  const formInfo: IInputComponentProps[] = [
    {
      label: t("OrgNumberShort"),
      value: approval.farm?.registrationNumber || "",
    },
    {
      label: t("FarmHolderType"),
      value: approval.farm?.holder?.type
        ? t(`HolderType${approval.farm.holder.type}`)
        : "",
    },
    {
      label: t("Name"),
      value: !!approval.farm?.holder?.type
        ? approval.farm.holder.type === "physicalPerson"
          ? `${approval.farm.holder.firstName} ${approval.farm.holder.lastName}`
          : approval.farm.holder.name
        : "",
    },
  ];

  return (
    <SubSection
      title={t(isChild ? "ChildFarmRegistrationData" : "FarmRegistrationData")}
      expandable={true}
      initialyOpen={true}
    >
      <div className="form-container">
        {formInfo.map(
          (e) => !e.hidden && <Input key={e.label} component={e} />
        )}
      </div>
    </SubSection>
  );
};
