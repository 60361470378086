import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import { IAnimal } from "../../../types/IAnimal";

interface Props {
  animal: IAnimal;
}

export const AnimalForm: FC<Props> = ({ animal }) => {
  const { t } = useTranslation();

  const animalInfo: IInputComponentProps[] =
    animal.group === "CUSTOM"
      ? [
          {
            label: t("AnimalName"),
            value: animal.name,
          },
          {
            label: t("AnimalProductionType"),
            value: animal.productionType ? t(animal.productionType) : "/",
          },
          {
            label: t("AnimalDescriptionAndCount"),
            value: animal.description,
          },
        ]
      : [
          {
            label: t("Hid"),
            value: animal.hid,
          },
          {
            label: t("AnimalGroup"),
            value: t(animal.group),
          },
          {
            label: t("AnimalCount"),
            value: `${animal.animalCount || "/"}`,
          },
          {
            label: t("AnimalCountOrganic"),
            value: `${animal.animalCountOrganic?.toString() || "/"}`,
          },
          {
            label: t("AnimalCountConventional"),
            value: `${animal.animalCountConventional?.toString() || "/"}`,
          },
        ];

  return (
    <div className="form-container">
      {animalInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} />
      )}
    </div>
  );
};
