import {
  IBankAccount,
  IFarm,
  IHeadOfficeNew,
  ILegalRepresentativeNew,
  IPersonalInfoData,
} from "./IFarm";
import { IMemberNew } from "./IMember";
import { IHolderNew } from "./IHolder";
import { ILand, ILandUsageBasis } from "./ILand";
import { IEvidence } from "./IFile";
import { IUser } from "./IUser";
import { IInvoice } from "./IInvoice";

export interface IApprovalNew {
  uuid?: string;
  administrativeProcedure?: boolean;
  approvalStatus?: IApprovalStatus;
  farm?: IFarm;
  changedObject?: IChangedObject;
  statusTime?: string;
  createdAt?: string;
  holder?: IHolderNew;
  member?: IMemberNew;
  legalRepresentative?: ILegalRepresentativeNew;
  bankAccount?: IBankAccount;
  headOffice?: IHeadOfficeNew;
  land?: ILand;
  evidences?: IEvidence[];
  approvalNumber?: string;
  processor?: IUser;
  decisionNumber?: string;
  decisionDate?: string;
  invoice?: IInvoice;
  statusComment?: string;
  childApproval?: IApproval;
}

export interface IApproval extends IApprovalNew {
  uuid: string;
}

export interface IApprovalGrouped {
  farm: IFarm;
  count: number;
  latestCratedAt: string;
  processingStarted: boolean;
}

export interface IApprovalSearch {
  concluded?: boolean;
  administrativeProcedure?: boolean;
  farmUuid?: string;
  memberLand?: boolean;
  changedObject?: IChangedObject;
  farmRegistrationNumber?: string;
  holderPersonalIdentityNumber?: string;
  holderRegistrationNumber?: string;
  approvalNumber?: string;
  landUsageBasis?: ILandUsageBasis;
  processorUser?: IPersonalInfoData;
  processorUserUuid?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  statusTimeFrom?: string;
  statusTimeTo?: string;
  approvalStatus?: IApprovalStatus;
  holderTemporary?: boolean;
  excludeChangedObjects?: IChangedObject[];
}

export const approvalStatuses = [
  "APPROVED",
  "REJECTED",
  "PENDING",
  "IN_PROGRESS",
  "WAITING_COMPLEMENT",
  "COMPLEMENTED",
] as const;
export type IApprovalStatus = (typeof approvalStatuses)[number];

export const changeApprovalStatuses = [
  "PENDING",
  "WAITING_COMPLEMENT",
] as const;
export type IChangeApprovalStatus = (typeof changeApprovalStatuses)[number];

export const changedObjects = [
  "BANK_ACCOUNT",
  "HEAD_OFFICE",
  "HOLDER",
  "TEMPORARY_HOLDER",
  "CHANGE_HOLDER",
  "MEMBER",
  "REGISTRATION",
  "LEGAL_REPRESENTATIVE",
  "LAND",
  "INVOICE",
] as const;
export type IChangedObject = (typeof changedObjects)[number];

export interface IProcedureData {
  decisionNumber?: string;
  decisionDate?: string;
  excludeLands?: { uuid: string }[];
}

type updateApprovalEntityKey = "bankAccount" | "headOffice" | "land";
interface IUpdateApprovalData {
  uuid?: string;
  accountNumber?: string;
  street?: string;
  houseNumber?: string;
  surface?: number | string;
  cadastreMunicipality?: { uuid: string };
}

export type IUpdateApprovalEntityData = {
  [key in updateApprovalEntityKey]?: IUpdateApprovalData;
};
