import { createContext, FC } from "react";
import { useTranslation } from "react-i18next";
import { IProceduresPageMode } from "../types/IBase";
import { Sidebar } from "../Components/Sidebar";
import { ApprovalsTable } from "../Components/Tables/ApprovalsTable";
import { useAppSelector } from "../redux/reduxHooks";
import { NoAccessComponent } from "../Components/Common/NoAccessComponent";
import { InvoiceApprovalsTable } from "../Components/Tables/InvoiceApprovalsTable";

interface Props {
  mode: IProceduresPageMode;
}

export const PageModeContext = createContext<IProceduresPageMode>(
  "NON_ADMINISTRATIVE_PROCEDURES"
);

export const defaultApprovalsSearch = { concluded: false };

export const ProceduresPage: FC<Props> = ({ mode }) => {
  const { t } = useTranslation();
  const privileges = useAppSelector((state) => state.user.privileges);

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`PageTitle${mode}`)}`}</h1>
      </div>
      <PageModeContext.Provider value={mode}>
        <div className="flex p-3">
          <Sidebar />
          {(
            mode === "INVOICES"
              ? privileges.REFRACTION_APPROVAL_R
              : privileges.APPROVAL_R
          ) ? (
            <div className="full-width">
              {mode === "INVOICES" ? (
                <InvoiceApprovalsTable />
              ) : (
                <ApprovalsTable />
              )}
            </div>
          ) : (
            <NoAccessComponent />
          )}
        </div>
      </PageModeContext.Provider>
    </div>
  );
};
