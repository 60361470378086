import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoBox } from "../../../Elements/Alert/InfoBox";
import { getAnimals } from "../../../repos/AnimalRepo";
import { IAnimalByGroup, IAnimalCountTotals } from "../../../types/IAnimal";
import { Section } from "../../Section";
import { SubSection } from "../../SubSection";
import { AnimalForm } from "./AnimalForm";
import { IFarm } from "../../../types/IFarm";
import { groupArray } from "../../../utils/ArrayUtils";
import { AnimalHidSection } from "./AnimalHidSection";
import { AnimalsRecapitulationTable } from "../../Recapitulation/AnimalsRecapitulationTable";

interface Props {
  farm: IFarm;
  approvalChangedObjectRegistration?: boolean;
}

export const AnimalsSection: FC<Props> = ({
  farm,
  approvalChangedObjectRegistration,
}) => {
  const [animalsByGroups, setAnimalsByGroups] = useState<IAnimalByGroup[]>([]);
  const [animalCountTotals, setAnimalCountTotals] =
    useState<IAnimalCountTotals>();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const farmPending = ["PENDING", "PENDING_APPROVAL"].includes(
    farm?.status || ""
  );

  const handleGetAnimals = useCallback(
    (callback?: () => void) => {
      if (farm) {
        if (farm.animals) {
          setAnimalsByGroups(groupArray(farm.animals, "group"));
        } else {
          setIsLoading(true);
          getAnimals(farm.uuid)
            .then((data) => {
              setAnimalsByGroups(groupArray(data.animals, "group"));
              setAnimalCountTotals(data.totals);
              callback && callback();
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
        }
      }
    },
    [farm]
  );

  useEffect(handleGetAnimals, [handleGetAnimals]);

  return (
    <Section id="animals" title={t("Animals")}>
      <div className="form-container">
        <InfoBox
          title={t("Important")}
          desc={t(
            approvalChangedObjectRegistration && farmPending
              ? "AnimalHidsSectionInfo"
              : "AnimalInfoDesc"
          )}
        />
        {!!animalsByGroups.length && (
          <AnimalsRecapitulationTable
            animalsByGroups={animalsByGroups}
            totals={animalCountTotals}
          />
        )}
        {farm && farmPending && approvalChangedObjectRegistration && (
          <AnimalHidSection farmUuid={farm.uuid} />
        )}
        {farm &&
          !isLoading &&
          animalsByGroups.map((e) => (
            <SubSection key={e.id} title={t(e.id)} expandable>
              <div className="form-container px-4">
                {e.animals.map((animal) => (
                  <SubSection
                    key={animal.hid}
                    title={
                      animal.group === "CUSTOM"
                        ? animal.name!
                        : `${t("HidShort")}: ${animal.hid}`
                    }
                    expandable
                    tertiary
                  >
                    <AnimalForm animal={animal} />
                  </SubSection>
                ))}
              </div>
            </SubSection>
          ))}
      </div>
    </Section>
  );
};
