import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import {
  changeApprovalStatuses,
  IChangeApprovalStatus,
} from "../../types/IApprovals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "../../assets/icons/light/faArrowRight";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (status: IChangeApprovalStatus) => void;
}

export const ChangeApprovalStatusDialog: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={t("ChangeApprovalStatus")}>
      <div>
        {changeApprovalStatuses.map((status, i) => (
          <div key={i} onClick={() => onSubmit(status)}>
            <div className="flex dialog-link">
              {t(`ChangeApprovalStatusTo${status}`)}
              <div className="flex full-width justify-right">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="color-primary icon-3"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Dialog>
  );
};
