import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import {
  Button,
  deleteProps,
  Select,
  TextInput,
} from "@inceptionbg/ui-components";
import { useAppSelector } from "../redux/reduxHooks";
import { NoAccessComponent } from "../Components/Common/NoAccessComponent";
import { farmStatuses, IFarmStatusChange } from "../types/IFarm";
import { changeFarmStatus } from "../repos/FarmRepo";
import { getPassiveStatusReasonOptions } from "../utils/LoadOptions";
import { IInputComponentProps, Input } from "../Elements/Input";
import { SubSection } from "../Components/SubSection";
import { SelectAsyncPaginate } from "../Components/Inputs/SelectAsyncPaginate";
import { FarmStatusChangeEvidences } from "../Components/Files/FarmStatusChangeEvidences";
import { IEvidence } from "../types/IFile";

export const FarmStatusChange: FC = () => {
  const [regNum, setRegNum] = useState("");
  const [formData, setFormData] = useState<IFarmStatusChange>();
  const [isLoading, setIsLoading] = useState(false);
  const [evidences, setEvidences] = useState<IEvidence[]>([]);

  const { t } = useTranslation();
  const privileges = useAppSelector((state) => state.user.privileges);

  const farmStatusInfo: IInputComponentProps[] = [
    {
      label: t("OrgNumberShort"),
      input: (
        <TextInput required value={regNum} setValue={(e) => setRegNum(e)} />
      ),
    },
    {
      label: t("NewStatus"),
      input: (
        <Select
          placeholder={`${t("Choose")}...`}
          isClearable
          value={
            formData?.newStatus
              ? {
                  value: formData.newStatus,
                  label: t(`FarmStatus${formData.newStatus}`),
                }
              : null
          }
          options={farmStatuses.map((e) => ({
            value: e,
            label: t(`FarmStatus${e}`),
          }))}
          onChange={(data) => {
            data
              ? setFormData({
                  ...formData,
                  newStatus: data.value,
                })
              : setFormData(deleteProps(formData, ["newStatus"]));
          }}
        />
      ),
    },
    {
      label: t("PassiveStatusReason"),
      input: (
        <SelectAsyncPaginate
          required
          className="select-paginate select-popup-wide"
          placeholder={`${t("Choose")}...`}
          value={
            formData?.passiveStatusReason
              ? {
                  value: formData.passiveStatusReason.uuid!,
                  label: formData.passiveStatusReason.name!,
                }
              : null
          }
          loadOptions={getPassiveStatusReasonOptions}
          onChange={(e) => {
            setFormData(
              e
                ? {
                    ...formData,
                    passiveStatusReason: {
                      uuid: e.value,
                      name: e.label,
                    },
                  }
                : deleteProps(formData, ["passiveStatusReason"])
            );
          }}
        />
      ),
    },
    {
      label: t("DecisionNumber"),
      input: (
        <TextInput
          required
          value={formData?.decisionNumber || ""}
          setValue={(e) => setFormData({ ...formData, decisionNumber: e })}
        />
      ),
    },
    {
      label: t("Note"),
      input: (
        <TextInput
          required
          value={formData?.note || ""}
          setValue={(e) => setFormData({ ...formData, note: e })}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`FarmStatusChange`)}`}</h1>
      </div>
      <div className="flex p-3">
        <Sidebar />
        {privileges.FARM_STATUS_CHANGE ? (
          <>
            <SubSection
              title={t("FarmStatusChange")}
              expandable={false}
              isLoading={isLoading}
            >
              <div className="form-container">
                {farmStatusInfo.map(
                  (e) => !e.hidden && <Input key={e.label} component={e} edit />
                )}
                <FarmStatusChangeEvidences
                  evidences={evidences}
                  setEvidences={setEvidences}
                  setIsLoading={setIsLoading}
                />
                <div className="flex justify-right">
                  <Button
                    label={t("Save")}
                    onClick={() => {
                      setIsLoading(true);
                      changeFarmStatus(regNum, {
                        ...formData,
                        evidence: evidences[0],
                      })
                        .then(() => {
                          setFormData({});
                          setRegNum("");
                          setEvidences([]);
                        })
                        .catch(() => {})
                        .finally(() => setIsLoading(false));
                    }}
                    disabled={
                      !regNum ||
                      !formData?.newStatus ||
                      (formData?.newStatus !== "ACTIVE" &&
                        !formData?.passiveStatusReason)
                    }
                    size="s"
                    className="primary"
                    primary
                  />
                </div>
              </div>
            </SubSection>
          </>
        ) : (
          <NoAccessComponent />
        )}
      </div>
    </div>
  );
};
