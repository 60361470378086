import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../Elements/Loader";
import { Section } from "../../Section";
import { IRenewalCondition } from "../../../types/IFarm";
import { RenewalConditionsTable } from "../../Recapitulation/RenewalConditionsTable";

interface Props {
  renewalConditions: IRenewalCondition;
  farmRegistrationNumber: string;
  isLoading: boolean;
}

export const RenewSection: FC<Props> = ({
  renewalConditions,
  farmRegistrationNumber,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Section
      id="renew"
      title={t("RenewTitle", {
        number: farmRegistrationNumber,
      })}
    >
      <Loader isLoading={isLoading}>
        <RenewalConditionsTable renewalConditions={renewalConditions} />
      </Loader>
    </Section>
  );
};
