import {
  deleteProps,
  ITableFilter,
  Select,
  TextInput,
} from "@inceptionbg/ui-components";
import { t } from "i18next";
import {
  IRefractionRequestSearch,
  RefractionStatuses,
} from "../../types/IRefraction";

export const RefractionRequestsFilters: (
  search: IRefractionRequestSearch,
  setSearch: (search: IRefractionRequestSearch) => void
) => ITableFilter = (search, setSearch) => ({
  farmRegistrationNumber: {
    label: t("OrgNumberShort"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.farmRegistrationNumber || ""}
        setValue={(farmRegistrationNumber) =>
          setSearch({ ...search, farmRegistrationNumber })
        }
      />
    ),
    resetField: () =>
      setSearch(deleteProps(search, ["farmRegistrationNumber"])),
  },
  caseNumber: {
    label: t("CaseNumber"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.caseNumber || ""}
        setValue={(caseNumber) => setSearch({ ...search, caseNumber })}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["caseNumber"])),
  },
  status: {
    label: t("Status"),
    field: (
      <Select
        placeholder={`${t("Choose")}...`}
        isClearable
        value={
          search.status
            ? {
                value: search.status,
                label: t(`RefactionStatus${search.status}`),
              }
            : null
        }
        options={RefractionStatuses.map((e) => ({
          value: e,
          label: t(`RefactionStatus${e}`),
        }))}
        onChange={(data) => {
          data
            ? setSearch({
                ...search,
                status: data.value,
              })
            : setSearch(deleteProps(search, ["status"]));
        }}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["status"])),
  },
});
