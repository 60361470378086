import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { localStorageGet } from "./LocalStorageHelper";

const defaultLanguage = localStorageGet("language");

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  lng: defaultLanguage || "sr-RS-Cyrl",
  fallbackLng: "sr_RS_Latn",
  returnNull: false,
  debug: true,
  resources: {
    "sr-RS-Latn": {
      translation: {
        ////// BASIC //////
        Yes: "Da",
        No: "Ne",
        Back: "Nazad",
        Confirm: "Potvrdi",
        Reject: "Odbaci",
        Ok: "U redu",
        Attachments: "Prilozi",
        Cancel: "Otkaži",
        Edit: "Izmeni",
        or: "ili",
        number: "broj",
        Important: "VAŽNA INFORMACIJA!",
        eRPGLong: "Elektronski registar poljoprivrednih gazdinstava",
        FooterFreeCall: "Besplatan poziv za podršku:",
        M: "Muški",
        F: "Ženski",
        FreeTextPlaceholder: "Slobodan tekst...",
        PENDING: "U pripremi",
        PENDING_APPROVAL: "Čeka odobrenje",
        ACTIVE: "Aktivno",
        PASSIVE: "Pasivno",
        DELETED: "Obrisano iz registra",
        COMMERCIAL: "Komercijalno gazdinstvo",
        NON_COMMERCIAL: "Nekomercijalno gazdinstvo",
        CONVENTIONAL: "Konvencionalna",
        ORGANIC: "Organska",
        ChoosePlaceholder: "Izaberi...",
        Title: "Naslov",
        Note: "Napomena",
        Save: "Sačuvaj",
        Delete: "Obriši",
        AddInput: "Unesi",
        Choose: "Izaberi",
        Concluded: "Završen",
        NoAccessMessage: "Nemate privilegiju za pristup ovom resursu",
        Form: "Oblik",
        ShowDetails: "Prikaži detalje",
        CreatedAtTime: "Vreme kreiranja",
        Number: "Broj",
        HomePage: "Početna strana",
        Actions: "Akcije",
        Category: "Kategorija",
        Producer: "Proizvođač",
        Quantity: "Količina",
        UnitOfMeasure: "Jedinica mere",
        PurchaseDate: "Datum kupovine",
        Supplier: "Dobavljač",
        Date: "Datum",
        CreatedAt: "Datum kreiranja",

        ////// CONFIRM DIALOG //////
        ConfirmTitleSave: "Sačuvano!",
        ConfirmTitleEdit: "Izmenjeno!",
        ConfirmDescSave: "Uneti podaci su uspešno sačuvani.",
        ConfirmDescEdit: "Podaci su uspešno izmenjeni.",
        ConfirmDescSaveValidation: `Uneti podaci su uspešno sačuvani.
        Podaci će biti uneti u registar nakon
        provere od strane Registratora.`,
        ConfirmDescEditValidation: `Podaci su uspešno izmenjeni.
        Izmenjeni podaci će biti uneti u registar
        nakon provere od strane Registratora.`,
        ConfirmRequestDesc:
          "Da li ste sigurni da želite da PRIHVATITE ovaj zahtev?",
        ConfirmRequestButton: "Da, PRIHVATI zahtev",
        RejectRequestDesc:
          "Da li ste sigurni da želite da ODBIJETE ovaj zahtev?",
        RejectRequestButton: "Da, ODBIJAM zahtev",

        ////// LOGIN, REGISTRATION //////
        Login: "Prijavi se",
        LoginTitle: "Prijavite se preko portala za elektronsku identifikaciju",
        LogOut: "Odjavi se",
        ChooseFarm: "Odaberite poljoprivredno gazdinstvo",

        ////// HEADER //////
        Settings: "Podešavanja",
        "sr-RS-Cyrl": "Ćirilica",
        "sr-RS-Latn": "Latinica",
        Home: "Početna",
        eEncouragement: "ePodsticaj",
        Help: "Pomoć",
        Contact: "Kontakt",
        ChangeOrg: "Promeni PG",
        MyAccount: "Moj nalog",
        OrgNumberShort: "BPG",
        ChangeFarm: "Promeni PG",
        FindFarmLabel:
          "Pronađi poljoprivredno gazdinstvo po broju PG, matičnom broju ili JMBG-u nosioca",

        ////// PAGE //////
        PageTitleNON_ADMINISTRATIVE_PROCEDURES: "Vanupravni postupci",
        PageTitleADMINISTRATIVE_PROCEDURES: "Upravni postupci",
        PageTitleINVOICES: "Računi za gorivo",
        PageTitleDataChange: "Promena podataka",

        ////// SIDEBAR //////
        ProcessingItems: "Predmeti za obradu",
        NonAdministrativeProcedures: "Vanupravni postupak",
        AdministrativeProcedures: "Upravni postupak",
        DataChange: "Promena podataka",
        FarmsRegister: "Registar PG",
        OpenApprovalFarms: "Gazdinstva sa otvorenim zahtevima",

        ////// APPROVALS //////
        Farm: "Poljoprivredno gazdinstvo",
        ApprovalNumber: "Broj zahteva",
        ApprovalInfo: "Informacije o zahtevu",
        ChildApprovalInfo: "Informacije o povezanom zahtevu",
        ChangedObject: "Tip promene",
        RequestDate: "Datum zahteva",
        StatusTime: "Datum obrade",
        Status: "Status",
        ChangeStatus: "Promeni status",
        Processor: "Preuzeo na obradu",
        ChangeApprovalStatus: "Promena statusa zahteva",
        DownloadForProcessing: "Preuzmi na obradu",
        DownloadForProcessingTitle: "Preuzimanje zahteva na obradu",
        DownloadForProcessingDesc:
          "Kada preuzmete zahtev na obradu, on će biti zaključan za druge obrađivače",
        Preview: "Pogledaj",
        DetailsPreview: "Pogledaj detalje",
        DetailsPreviewTitle: "Preuzimanje zahteva na pregled",
        DetailsPreviewDesc: "Pregled detalja zahteva",
        Download: "Preuzmi",
        DownloadXlsx: "Preuzmi eksel fajl",
        RunProcedure: "Pokreni upravni postupak",
        OpenProcedureTitle: "Otvaranje upravnog postupka",
        SendToProcedure: "Pošalji na dalju obradu",
        ConfirmData: "Potvrdi podatke",
        EditorsComment: "Komentar obrađivača",
        ProcessType: "Vrsta postupka",
        RFarmDataChange: "Promena podataka u RPG",
        FarmBankAccountData:
          "Podaci o tekućem računu poljoprivrednog gazdinstva",
        ChildFarmBankAccountData: "Podaci o povezanom tekućem računu",
        AccountNumber: "Broj računa",
        AccountNumberHelper: "Namenski račun za uplate iz budžeta",
        SolutionNumber: "Broj rešenja",
        DateOfResolution: "Datum rešenja",
        BankName: "Naziv banke",
        FarmHeadOfficeData: "Podaci o sedištu poljoprivrednog gazdinstva",
        ChildFarmHeadOfficeData: "Podaci o povezanom sedištu",
        FarmRegistrationData: "Podaci o registraciji gazdinstva",
        ChildFarmRegistrationData: "Podaci o povezanoj registraciji gazdinstva",
        FarmHolderType: "Tip nosioca",
        HolderTypephysicalPerson: "Nosilac gazdinstva je fizičko lice",
        HolderTypelegalEntity: "Nosilac gazdinstva je pravno lice",
        ChangeApprovalStatusToPENDING: "Vrati na obradu",
        ChangeApprovalStatusToWAITING_COMPLEMENT: "Vrati na dopunu",
        FarmApprovals: "Lista otvorenih zahteva PG",
        ChangeApprovalStatusToWaitingComplementComment:
          "Razlog vraćanja na dopunu",
        OpenCase: "Otvaranje predmeta",
        CaseNumber: "Broj predmeta",
        OpenCasePage: "Otvaranje predmeta po službenoj dužnosti",
        LandsConfirmation: "Potvrda zemljišta",
        ConfirmOnAllLands: "Potvrdi sa svim parcelama",
        ConfirmPartially: "Potvrdi delimično",
        PartialConfirmationLandsTitle: "Potvrda registracije sa delom parcela",
        PartialConfirmationLandsDesc:
          "Odaberite parcele koje će biti upisane na gazdinstvo",

        ////// APPROVALS GROUPED //////
        ApprovalsGrouped: "Ukupan broj otvorenih zahteva",
        OpenApprovalsCount: "Ukupan broj otvorenih zahteva",
        LatestCratedAtDate: "Datum najstarijeg zahteva",
        ApprovalsStatus: "Zbirni status zahteva",
        ApprovalsByGroupPage: "Zahtevi po PG",
        ModeType: "Vrsta postupka",
        NonAdministrative: "Vanupravni",
        Administrative: "Upravni",
        OrgNumberS: "Broj PG",
        GetFarmToProcessing: "Preuzmi PG na obradu",

        ////// APPROVAL STATUS //////
        ApprovalStatusAPPROVED: "Obrađeno",
        ApprovalStatusREJECTED: "Odbijeno",
        ApprovalStatusPENDING: "Čeka na obradu",
        ApprovalStatusIN_PROGRESS: "U obradi",
        ApprovalStatusWAITING_COMPLEMENT: "Čeka dopunu",
        ApprovalStatusCOMPLEMENTED: "Dopunjen",

        ////// CHANGED OBJECT //////
        ChangedObjectBANK_ACCOUNT: "Tekući račun",
        ChangedObjectHEAD_OFFICE: "Sedište poljoprivrednog gazdinstva",
        ChangedObjectHOLDERphysicalPerson: "Nosioc fizičko lice",
        ChangedObjectHOLDERlegalEntity: "Nosioc pravno lice",
        ChangedObjectHOLDER: "Promena podataka nosioca",
        ChangedObjectTEMPORARY_HOLDER: "Privremeni nosilac",
        ChangedObjectCHANGE_HOLDER: "Promena nosioca (zamena nosioca)",
        ChangedObjectMEMBER: "Član",
        ChangedObjectREGISTRATION: "Registracija",
        ChangedObjectLEGAL_REPRESENTATIVE: "Odgovorno lice",
        ChangedObjectLAND: "Dodavanje zemljišta",
        ChangedObjectINVOICE: "Račun za gorivo",
        ChangedObjectALL_CHANGED_OBJECTS: "Sve promene",

        ////// BASIC INFO //////
        BasicInfoTitle: "Osnovni podaci o poljoprivrednom gazdinstvu",
        OrgNumberLong: "Broj poljoprivrednog gazdinstva",
        StatusPG: "Status PG",
        RenewalDate: "Datum obnove",
        ActiveStatusDays: "Vreme trajanje aktivnog statusa",
        RegistrationDate: "Datum registracije",
        FirstRegistrationDate: "Datum prve registracije",
        LatestChangeDate: "Datum poslednje izmene",
        OrganizationalForm: "Organizacioni oblik",
        Member: "Član",
        MemberName: "Naziv člana",
        ChildMember: "Povezani član",
        MembersCount: "Broj članova PG",
        ArgHolderInfo: "Podaci o nosiocu",
        ChildArgHolderInfo: "Podaci o nosiocu",
        ArgHolderEdit: "Izmeni podatke o nosiocu PG",
        CreateFarm: "Registrujte poljoprivredno gazdinstvo",
        SelectHolderType: "Izaberite tip poljoprivrednog gazdinstva:",
        LabelEXCERPT_FROM_REGISTER:
          "Izvod - podaci o poljoprivrednom gazdinstvu",
        LabelEXCERPT_PLANT_CULTURE: "Izvod - struktura biljne proizvodnje",
        LabelEXCERPT_ANIMAL: "Izvod - životinje",
        LabelSTATUS_CONFIRMATION: "Potvrda o trenutnom statusu",
        ShowHistoryDataOnDay: "Prikaz podataka na dan",
        FarmHistoryData: "Podaci o PG",
        FarmHistoryDataOnDay: "Podaci o PG na dan",
        NoDataForChosenDay: "Nema podataka za izabrani dan",

        ////// HOLDER //////
        Holder: "Nosioc",
        HolderName: "Naziv nosioca",
        HolderPersonalIdentityNumber: "JMBG nosioca",
        HolderRegistrationNumber: "Matični broj nosioca",
        PersonalIdentityNumber: "JMBG",
        FirstName: "Ime",
        LastName: "Prezime",
        BirthDate: "Datum rođenja",
        BirthPlace: "Mesto rođenja",
        BirthCountry: "Država rođenja",
        Gender: "Pol",
        WorkActivity: "Radna aktivnost",
        Education: "Školovanje",
        HolderNotVerifiedInfo: "Nosilac gazdinstva nije verifikovan",
        LLC: "Društvo sa ograničenom odgovornošću",
        ENTREPRENEUR: "Preduzetnik",
        MICRO: "Mikro",
        SMALL: "Malo",
        MEDIUM: "Srednje",
        LARGE: "Veliko",
        Temporary: "Privremeni",
        Permanently: "Stalni",
        HolderTemporary: "Privremeni nosilac",
        HolderPermanent: "Stalni nosilac",

        ////// LEGAL ENTITY //////
        LegalEntityInfo: "Podaci o Pravnom licu",
        LegalEntitySize: "Veličina pravnog lica",
        RegistrationNumber: "Matični broj",
        TaxId: "PIB",
        Jbkjs: "JBKJS",
        DedicatedRegisterNumber: "Broj iz lokalnog registra",
        Name: "Naziv",
        NumberOfEmployees: "Број стално запослених",
        AgriculturalProduction: "Poljoprivredna proizvodnja",
        AgriculturalProductsProcessing: "Prerada poljoprivrednih proizvoda",
        NonAgriculturalProduction:
          "Druge nepoljoprivredne delatnosti (ruralni turizam, stari zanati i sl.)",

        LEFormLLC: "Društvo sa ograničenom odgovornošću",
        LEFormENTREPRENEUR: "Preduzetnik",
        LEFormOTHER: "Ostalo",

        ////// HEAD OFFICE //////
        HeadOfficeTitle: "Sedište poljoprivrednog gazdinstva",
        HeadOfficeEdit: "Izmeni podatke o sedištu PG",
        Street: "Ulica",
        NumberLetter: "Broj i slovo",
        Place: "Mesto",
        Municipality: "Opština / grad",
        PostalCode: "Poštanski broj",
        HeadOfficeNotVerifiedInfo: "Sedište nije verifikovano",

        ////// MEMBERS //////
        MembersTitle: "Članovi poljoprivrednog gazdinstva",
        MemberNotVerifiedInfo: "Član nije verifikovan",
        AuthorizedPersonsTitle: "Ovlašćeni zastupnici",
        MemberLand: "Zemljište člana",
        HolderLand: "Zemljište nosioca",
        MemberPersonalIdentityNumber: "JMBG člana",

        ////// LAND //////
        LandShort: "Zemljište",
        Land: "Zemljišni fond i biljne kulture",
        MembersLandTitle:
          "Zemljište koje je član ustupio poljoprivrednom gazdinstvu",
        UnreportedLandTitle: "Neprijavljeno zemljište u državnoj svojini!",
        UnreportedLandDesc:
          "Postoji {{ha}}ha {{a}}a {{m}}m2 pod zakupom od države za koje niste prijavili biljnu kulturu. Svo zemljište u državnoj svojini mora biti prijavljeno u registru.",
        LandEdit: "Izmeni podatke o parceli",
        FarmLandData: "Podaci o parceli",
        LandAdd: "Dodaj parcelu",
        LandDelete: "Ukloni celu parcelu",
        DeleteLand: "Ukloni parcelu",
        DeleteLandDesc: "Da li ste sigurni da želite da uklonite parcelu?",
        CadastreNumberShort: "Kat.par.",
        CadastreNumber: "Broj kat. parcele",
        CadastreMunicipality: "Katastarska opština",
        CadastreMunicipalityNumber: "Katastarska opština/broj parcele",
        LandName: "Naziv parcele",
        CadastreCulture: "Katastarska kultura",
        Surface: "Površina parcele",
        UsageBasis: "Osnov korišćenja",
        UsageBasisUPZ: "Osnov korišćenja UPZ",
        LeaseEndDate: "Trajanje zakupa do",
        LessorIdentificationNumber: "JMBG / Matični broj zakupodavca",
        UsedSurface: "Površina koja se obrađuje",
        CadastreTotalSurface: "Ukupna površina u katastaru",
        AssignedPartSurface: "Površina ustupljenog udela",
        LandUsageBasis: "Tip svojine",
        ProcessorUser: "Obrađivač",
        CADASTRE_OWNERSHIP: "Vlasništvo",
        OWNERSHIP_OUT_CADASTRE: "Vlasništvo bez RGZ-a",
        LEASE: "Zakup / Ustupanje",
        LEASE_CONSOLIDATION: "Zakup zemljišta iz komasacije",
        LEASE_LEGAL_ENTITY: "Zakup zemljišta od pravnog lica",
        CONSOLIDATION: "Komasacija",
        UPZ: "UPZ",
        LandType: "Tip zemlje",
        LandReportWITHOUT_LEASE_AGREEMENT:
          "Prijava obrađivanja zemljišta bez pisanog ugovora o zakupu",
        LandReportCONSOLIDATION:
          "Prijava obrađivanja zemljišta iz komasacije, bez rešenja o raspodeli komasacione mase",
        LandReportUPZ: "Prijava obrađivanja državnog zemljišta bez ugovora",
        Submitter: "Podnosilac",
        SubmitterFirstNameAndLastName: "Ime i prezime podnosioca",
        SubmitterPersonalIdentityNumber: "JMBG podnosioca",
        LandReports: "Prijave obrade zemljišta",
        LandReport: "Prijava obrade zemljišta po drugom osnovu",
        CadastreCultureRecapitulationTitle:
          "Pregled prijavljene biljne kulture",
        SurfaceTitleFormatted: "Površina: ha a m2",
        TotalSurfaceUnderCultures: "Ukupno pod kulturama",
        NoReportedCulture: "Bez prijavljene kulture",
        TotalSurfaces: "Ukupno zemljište koje se obrađuje",
        UpdateDataFromRGZ: "Osveži podatke sa RGZ-a",

        // Add land
        AddLandTitle: "Dodavanje zemljišta",
        AddLandDesc:
          "Odaberite osnov korišćenja zemljišta koje će biti registrovano u okviru PG.",

        ////// LAND CULTURES //////
        LandCulture: "Biljna kultura",
        LandCulturesTitle: "Biljne kulture na parceli",
        LandCultureAdd: "Dodaj biljnu kulturu na parcelu",
        LandCultureEdit: "Izmeni podatke o kulturi",
        DeleteLandCulture: "Ukloni biljnu kulturu",
        DeleteLandCultureDesc:
          "Da li ste sigurni da želite da uklonite biljnu kulturu?",
        Culture: "Kultura",
        CultureSurface: "Površina pod kulturom",
        NumberOfSeedlings: "Broj sadnica/stabala",
        ProductionType: "Vrsta proizvodnje",
        Irrigation: "Navodnjava se",
        HailProtectionSurface: "Protivgradna zaštita na",
        NumberOfThrees: "Broj stabala",

        // Land part
        ShowLandPartEvidence: "Prikaži delove parcele",
        PartLand: "Delovi parcele",
        PartNumber: "Br. dela",
        PartSurface: "Površina m2",

        ////// APR LAND //////
        APRData: "Podaci sa APR-a",
        DownloadResponseFromAPR: "Preuzmi odgovor sa APR-a",
        UpdateLandDataFromAPR: "Osveži podatke sa APR-a",
        IsAprMember: "Da li je član privrednog društva koje je zakupodavac?",
        IsAprConnected:
          "Da li je direktor, član nadzornog odbora, zastupnik, prokurista ili likvidacioni upravnik pravnog lica koje je zakupodavac?",
        IsAprConnectedLegalEntityMember:
          "Da li je član Privrednog društva u čijem je vlasništvu zakupodavac?",
        IsAprPartnerOrComplementary:
          "Da li je ortak ili komplementar u društvu koje je zakupodavac?",
        IsAprFounder: "Da li je zakupac osnivač pravnog lica?",

        ////// CONTACT //////
        ContactTitle: "Kontakt podaci poljoprivrednog gazdinstva",
        ContactEdit: "Izmeni kontakt podatke",
        Email: "E-pošta",
        EmailAddress: "Adresa е-poštе",
        EmailHelper: "Adresa na koju će stizati obaveštenja",
        EmailNotVerified: "Adresa nije verifikovana.",
        EmailNotVerifiedInfo: `Adresa e-pošte nije verifikovana.
        Kliknite na dugme ”Izmeni kontakt podatke”, pa zatim na dugme ”Potvrdi adresu e-Pošte” i pratite dalja uputstva.`,
        VerifyEmail: "Potvrdite adresu e-pošte",
        PhoneNumber: "Broj mobilnog telefona",
        PhoneNumberHelper: "Na ovaj broj će stizati SMS obaveštenja",
        PhoneNumberNotVerified: "Broj mobilnog telefona nije verifikovan",
        PhoneNumberNotVerifiedInfo: `Broj mobilnog telefona nije verifikovan.
        Kliknite na dugme ”Izmeni kontakt podatke”, pa zatim na dugme ”Potvrdi broj telefona” i pratite dalja uputstva.`,
        VerifyPhoneNumber: "Potvrdite broj mobilnog telefona",
        EmailConfirmTitle: "Potvrda adrese e-pošte",
        EmailConfirmDesc:
          "Unesite KOD koji Vam je poslat na adresu e-pošte koju ste uneli",
        PhoneConfirmTitle: "Potvrda broja telefona",
        PhoneConfirmDesc:
          "Unesite KOD koji Vam je poslat na broj mobilnog telefona koju ste uneli",
        ResendActivationCode: "Pošaljite mi ponovo aktivacioni kod",

        ////// ANIMALS //////
        Animals: "Fond životinja",
        AnimalInfoDesc: `Podaci o fondu životinja se prikazuju iz registra Uprave za veterinu.
        Ukoliko su podaci nepotpuni ili nisu tačni obratite se nadležnoj veterinarskoj službi kako biste uredili stanje u registru.`,
        CheckAnimalDetails: "Pogledaj detalje iz uprave za veterinu",
        HidShort: "HID",
        Hid: "Identifikacioni broj (HID)",
        AnimalName: "Naziv životinja",
        AnimalGroup: "Grupa životinja",
        AnimalCount: "Broj životinja",
        AnimalCountOrganic: "Broj životinja (Organska proizvodnja)",
        AnimalCountConventional: "Broj životinja (Konvencionalna proizvodnja)",
        AnimalStatus: "Status u okviru PG",
        AnimalProductionType: "Način uzgoja",
        AnimalDescriptionAndCount: "Opis i obim uzgoja",
        AnimalCONVENTIONAL: "Konvencionalni",
        AnimalORGANIC: "Organski",
        Registered: "Prijavljen",
        Unregistered: "Neprijavljen",
        SHEEP: "Ovce i koze",
        PIG: "Svinje",
        CATTLE: "Goveda",
        HORSE: "Konji, magarci, mule i mazge",
        BIRTS: "Živina i ptice",
        BEES: "Pčelinje društvo - košnice",
        FISH: "Ribe",
        OTHER: "Životinje ostale",
        CUSTOM: "Ostale životinje",
        AnimalHidsSection: "Registrovani HID-ovi u Upravi za veterinu",
        AnimalHidsList: "Lista HID-ova",
        AnimalHidsSectionInfo: `Podaci o fondu životinja se preuzimaju iz registra Uprave za veterinu samo za već registrovana Poljoprivredna gazdinstva.
        Molimo da unesete sve HID-ove registrovane u Upravi za veterinu koji će biti prijavljeni na Vaše gazdinstvo.
        Nakon dobijanja Rešenja o upisu PG, potebno je da se ponovo prijavite u sistem i proverite podatke o životinjskom fondu.`,
        AnimalsRecapitulationTitle: "Pregled prijavljenog životinjskog fonda",
        EnrolledInUPV: "Upisano u UPV",
        OrganicFarming: "Organski uzgoj",
        ConventionalFarming: "Konv. uzgoj",
        TotalAnimalsCount: "Ukupno životinja",

        ////// RENEW //////
        RenewTitle: "Obnova - BPG: {{number}}",
        RenewalConditionsTable: "Pregled ispunjenosti uslova za obnovu",
        CountrysideTourism: "Seoski turizam",
        AtLeastOnePlantCultureOrAnimal: "Poljoprivredna proizvodnja",
        PlantCulture: "Uzgoj biljnih kultura",
        AnimalCulture: "Uzgoj životinja",
        AllowedAprCodes: "Prerada poljoprivrednih proizvoda",
        TechnicalPrerequisiteCondition: "Tehnički preduslovi",
        BasicInfoData: "Popunjeni osnovni podaci",
        AnimalNumberData: "Uneti podaci o broju životinja prema vrsti uzgoja",
        UpzCondition: "Prijavljene kulture na celoj površini iz UPZ",
        RenewalFarmStatus: "Status gazdinstva dozvoljava obnovu",
        HasEDelivery: "Ima aktivno eSanduče",
        HasEmailOrPhoneNumber: "Ima unete kontakt podatke",
        ReadyForRenewal: "Ispunjava uslov za obnovu",

        ////// INVOICES //////
        Invoices: "Računi",
        FuelInvoice: "Račun za gorivo",
        ChildFuelInvoice: "Povezani račun za gorivo",
        FuelInvoices: "Računi za gorivo",
        InvoiceNumber: "Broj računa",
        InvoiceDate: "Datum računa",
        GasStation: "Benzinska stanica",
        InvoiceItems: "Stavke računa",
        SellerTaxId: "PIB prodavca",

        ////// TABLE //////
        rowsPerPage: "Broj redova",
        of: "od",
        allResults: "Svi",
        page: "Strana",
        Filter: "Filteri",
        ResetFilter: "Resetuj filtere",
        Search: "Pretraga",
        Print: "Odštampaj",
        PrintExport: "Štampa/Izvoz",
        SaveXlsx: "Sačuvaj XLSX",
        PrintDate: "Datum izveštaja",
        from: "od",
        to: "do",
        Sort: "Sortiranje",
        Columns: "Kolone",
        DragDropListsInfo:
          "Odaberite kolone za prikazivanje prevlačenjem sa jedne liste na drugu",
        HiddenColumns: "Sakrivene kolone",
        SelectedColumns: "Odabrane kolone",
        NoResults: "Nema rezultata",

        ///// LAND RECAPITULATION //////
        RGZDataPreview: "Pregled podataka iz RGZ",
        RGZLandSurface: "Površina parcele u RGZ",
        DownloadDateS: "datum preuzimanja",
        RPGReportedLandSurface: "Površina prijavljena u RPG (trenutno, total)",
        VerificationS: "na verifikaciji",
        ApprovedS: "upisano",

        ///// FARM STATUS CHANGE //////
        FarmStatusChange: "Promena statusa gazdinstva",
        FarmStatusHistoryChange: "Istorija promenе statusa gazdinstva",
        NewStatus: "Novi status",
        OldStatus: "Stari status",
        PassiveStatusReason: "Razlog pasivnosti / brisanja",
        DecisionNumber: "Broj rešenja",
        FarmStatusACTIVE: "Aktivan",
        FarmStatusPASSIVE: "Pasivan",
        FarmStatusDELETED: "Izbrisan",

        ////// PLANT PROTECTION PRODUCTS //////
        PlantProtectionProducts: "Sredstva za zaštitu bilja",
        PlantProtectionProduct: "Sredstvo za zaštitu bilja",
        PlantProtectionProductDesc:
          "Da li ste sigurni da želite da obrišete sredstvo za zaštitu bilja",
        AddPlantProtectionProduct: "Dodaj sredstvo za zaštitu bilja",
        EditPlantProtectionProduct: "Izmeni sredstvo za zaštitu bilja",

        ///// FARM DATA CHANGE //////
        FarmDataChange: "Promena statusnih podataka",
        RenewalDateChange: "Promena datuma obnove",
        LegalEntityChange: "Promena osnovnih podataka pravnog lica",
        PersonalIdentityNumberChange: "Promena JMBG-a",
        LegalRepresentative: "Odgovorno lice",
        LegalRepresentativeInfo: "Podaci o odgovornom licu",
        ChildLegalRepresentativeInfo: "Podaci o povezanom odgovornom licu",

        ///// HOLDER/MEMBER DATA CHANGE /////
        HolderMemberDataChange: "Promena podataka nosioca/člana",
        HolderDataChange: "Promena podataka nosioca",
        MemberDataChange: "Promena podataka člana",
        HolderPhysicalPerson: "Nosioc mora biti fizičko lice",

        ///// UPLOAD DOCUMENT /////
        ImportExcelFile: "Uvoz Excel fajla",
        ExcelImportTitle: "Rezultat uvoza еksel fajla",
        RowsNumberChanged: "Broj promenjenih redova",
        LoadedFile: "Učitan fajl",

        ////// FILES //////
        Document: "Dokument",
        RemoveDocument: "Ukloni prilog",
        RemoveDocumentDesc: "Da li ste sigurni da želite da uklonite prilog",
        PCUpload: "Dodaj sa računara",
        UploadDocument: "Dodaj prilog",
        justDragAndDropFile: "ili samo prevuci dokument sa računara ovde",

        ////// ADMIN USERS //////
        AdminUsers: "Korisnički nalozi",
        AddAdminUser: "Dodaj korisnički nalog",
        EditAdminUser: "Izmeni korisnički nalog",
        Role: "Rola",
        RoleIsMissingDesc:
          "Niste izabrani rolu. Korisnik će izgubiti administratorski pristup.",

        ///// REPORTS /////
        Reports: "Izveštaji",
        ErpgReport: "Izveštaj o poljoprivrednim gazdinstvima u sistemu",
        ApprovalsReport: "Izveštaj o neobrađenim zahtevima",
        TotalErpgFarms: "Ukupno poljoprivrednih gazdinstava",
        ActiveFarms: "Aktivna poljoprivredna gazdinstva",
        PassiveFarms: "Pasivna poljoprivredna gazdinstva",
        RenewedFarmsInCurrentYear:
          "Obnovljena poljoprivredna gazdinstva u tekućoj godini",
        FarmsOnVerification:
          "Poljoprivredna gazdinstva kojа su trenutno na verifikaciji",
        RegisteredFarmsInCurrentYear:
          "Registrovana poljoprivredna gazdinstva u tekućoj godini",
        WorkRequestsReport: "Izveštaj o generisanim zahtevima",
        Pesticides: "Pesticidi",
        PesticideProcurements: "Izveštaj o kupovini pesticida",

        TotalSubmittedRequests: "Ukupno podnetih zahteva",
        TotalResolutionsIssued: "Ukupno donetih rešenja",
        TotalPayedRequests: "Ukupno isplaćenih zahteva",
        TotalRejectedRequests: "Ukupno neispravnih/odbijenih zahteva",
        TotalRequestsInDraft: "Ukupno zahteva u nacrtu",
        TotalSentToPaymentRequests: "Ukupno zahteva poslatih na plaćanje",
        ShowReport: "PRIKAŽI IZVEŠTAJ",

        RefractionRequestSummaryReport:
          "Prikaz sumarnog izveštaja o zahtevima za refrakciju",

        ///// WORK REQUESTS /////
        WorkRequestStatusCOMPLETED: "Završeno",
        WorkRequestStatusFAILED: "Neuspešno",
        WorkRequestStatusPENDING: "U pripremi",
        CompletionPercentage: "Procenat završenosti",

        ////// RERFACTION //////
        RefractionTitle: "Zahtev za refakciju",
        RefractionRequests: "Zahtevi za refakciju",
        RequestItems: "Stavke zahteva",
        RefactionStatusDRAFT: "U pripremi",
        RefactionStatusSUBMITTED: "Primljen",
        RefactionStatusAPPROVED: "Odobren",
        RefactionStatusREJECTED: "Odbijen",
        RefactionStatusSENT: "Poslat",
        RefactionStatusREADY_FOR_PAYMENT: "Spreman za plaćanje",
        RefactionStatusPAYED: "Plaćen",

        ////// GAS STATIONS //////
        GasStations: "Prodavci naftnih derivata",
        AddGasStation: "Dodaj prodavca",
        EditGasStation: "Izmeni prodavca",
        NameOrTaxId: "Naziv ili PIB",
        CodeOrName: "Šifra ili naziv",
        Code: "Šifra",
        FuelType: "Vrsta goriva",
        GasStationProducts: "Proizvodi prodavca",
        AddProduct: "Dodaj proizvod",
        EditProduct: "Izmeni proizvod",
        FuelTypeGAS_OIL: "Dizel",
        FuelTypeBIO_FUEL: "Biodizel",

        ///// ERROR //////
        ErrorTitle: "Došlo je do greške",
        ErrorRGZ_UNAVAILABLE: `Katastar nepokretnosti - Republički geodetski zavod
        trenutno nije aktivan.
        Molimo pokušajte kasnije.`,
        ErrorFARM_NOT_FOUND: "Poljoprivredno gazdinstvno nije pronađeno",
        ErrorAPPROVAL_OCCUPIED:
          "Postupak je u procesu obrade od strane drugog korisnika",
        ErrorPASSIVE_STATUS_REASON_REQUIRED:
          "Razlog pasivnosti / brisanja je obavezno polje",
        ErrorMULTIPLE_LAND_FOUND_FOR_CADASTRE_NUMBER:
          "Pronađene su višestruke parcele za katastarski broj {{ cadastreNumber }}. Opština {{ cadastreMuniciplaity }}.",
        ErrorLAND_WITH_CADASTRE_NUMBER_NOT_FOUND:
          "Parcela sa katastarskim brojem {{ cadastreNumber }} nije pronađena u RGZ-u. Opština {{ cadastreMuniciplaity }}.",
        ErrorHISTORY_AT_DAY_BEFORE_REGISTRATION_DATE:
          "Gazdinstvo je registrovano nakon odabranog datuma.",
        ErrorHOLDER_BY_REGISTRATION_NUMBER_NOT_FOUND:
          "Nosilac sa ovim MB nije pronađen",
        ErrorHOLDER_BY_UMNC_NOT_FOUND: "Nosilac sa ovim JMBG nije pronađen",
      },
    },
    "sr-RS-Cyrl": {
      translation: {
        ////// BASIC //////
        Yes: "Да",
        No: "Не",
        Back: "Назад",
        Confirm: "Потврди",
        Reject: "Одбаци",
        Ok: "У реду",
        Attachments: "Прилози",
        Cancel: "Откажи",
        Edit: "Измени",
        or: "или",
        number: "број",
        Important: "ВАЖНА ИНФОРМАЦИЈА!",
        eRPGLong: "Електронски регистар пољопривредних газдинстава",
        FooterFreeCall: "Бесплатан позив за подршку:",
        M: "Мушки",
        F: "Женски",
        FreeTextPlaceholder: "Слободан текст...",
        PENDING: "У припреми",
        PENDING_APPROVAL: "Чека одобрење",
        ACTIVE: "Активно",
        PASSIVE: "Пасивно",
        DELETED: "Обрисано из регистра",
        COMMERCIAL: "Комерцијално газдинство",
        NON_COMMERCIAL: "Некомерцијално газдинство",
        CONVENTIONAL: "Конвенционална",
        ORGANIC: "Органска",
        ChoosePlaceholder: "Изабери...",
        Title: "Наслов",
        Note: "Напомена",
        Save: "Сачувај",
        Delete: "Обриши",
        AddInput: "Унеси",
        Choose: "Изабери",
        Concluded: "Завршен",
        NoAccessMessage: "Немате привилегију за приступ овом ресурсу",
        Form: "Облик",
        ShowDetails: "Прикажи детаље",
        CreatedAtTime: "Време креирања",
        Number: "Број",
        HomePage: "Почетна страна",
        Actions: "Акције",
        Category: "Категорија",
        Producer: "Произвођач",
        Quantity: "Количина",
        UnitOfMeasure: "Јединица мере",
        PurchaseDate: "Датум куповине",
        Supplier: "Добављач",
        Date: "Датум",
        CreatedAt: "Датум креирања",

        ////// CONFIRM DIALOG //////
        ConfirmTitleSave: "Сачувано!",
        ConfirmTitleEdit: "Измењено!",
        ConfirmDescSave: "Унети подаци су успешно сачувани.",
        ConfirmDescEdit: "Подаци су успешно измењени.",
        ConfirmDescSaveValidation: `Унети подаци су успешно сачувани.
        Подаци ће бити унети у регистар након
        провере од стране Регистратора.`,
        ConfirmDescEditValidation: `Подаци су успешно измењени.
        Измењени подаци ће бити унети у регистар
        након провере од стране Регистратора.`,
        ConfirmRequestDesc:
          "Да ли сте сигурни да желите да ПРИХВАТИТЕ овај захтев?",
        ConfirmRequestButton: "Да, ПРИХВАТИ захтев",
        RejectRequestDesc:
          "Да ли сте сигурни да желите да ОДБИЈЕТЕ овај захтев?",
        RejectRequestButton: "Да, ОДБИЈАМ захтев",

        ////// LOGIN, REGISTRATION //////
        Login: "Пријави се",
        LoginTitle: "Пријавите се преко портала за електронску идентификацију",
        LogOut: "Одјави се",
        ChooseFarm: "Одаберите пољопривредно газдинство",

        ////// HEADER //////
        Settings: "Подешавања",
        "sr-RS-Cyrl": "Ћирилица",
        "sr-RS-Latn": "Латиница",
        Home: "Почетна",
        eEncouragement: "еПодстицаји",
        Help: "Помоћ",
        Contact: "Контакт",
        ChangeOrg: "Промени ПГ",
        MyAccount: "Мој налог",
        OrgNumberShort: "БПГ",
        ChangeFarm: "Промени ПГ",
        FindFarmLabel:
          "Пронађи пољопривредно газдинство по броју ПГ, матичном броју или ЈМБГ-у носиоца",

        ////// PAGE //////
        PageTitleNON_ADMINISTRATIVE_PROCEDURES: "Вануправни поступци",
        PageTitleADMINISTRATIVE_PROCEDURES: "Управни поступци",
        PageTitleINVOICES: "Рачуни за гориво",
        PageTitleDataChange: "Промена података",

        ////// SIDEBAR //////
        ProcessingItems: "Предмети за обраду",
        NonAdministrativeProcedures: "Вануправни поступак",
        AdministrativeProcedures: "Управни поступак",
        DataChange: "Промена података",
        FarmsRegister: "Регистар ПГ",
        OpenApprovalFarms: "Газдинства са отвореним захтевима",

        ////// APPROVALS //////
        Farm: "Пољопривредно газдинство",
        ApprovalNumber: "Број захтева",
        ApprovalInfo: "Информације о захтеву",
        ChildApprovalInfo: "Информације о повезаном захтеву",
        ChangedObject: "Тип промене",
        RequestDate: "Датум захтева",
        StatusTime: "Датум обраде",
        Status: "Статус",
        ChangeStatus: "Промени статус",
        Processor: "Преузео на обраду",
        ChangeApprovalStatus: "Промена статуса захтева",
        DownloadForProcessing: "Преузми на обраду",
        Preview: "Погледај",
        DetailsPreview: "Погледај детаље",
        DetailsPreviewTitle: "Преузимање захтева на преглед",
        DetailsPreviewDesc: "Преглед детаља захтева",
        DownloadForProcessingTitle: "Преузимање захтева на обраду",
        DownloadForProcessingDesc:
          "Када преузмете захтев на обраду, он ће бити закључан за друге обрађиваче",
        Download: "Преузми",
        DownloadXlsx: "Преузми ekсел фаjл",
        RunProcedure: "Покрени управни поступак",
        OpenProcedureTitle: "Отварање управног поступка",
        SendToProcedure: "Пошаљи на даљу обраду",
        ConfirmData: "Потврди податке",
        EditorsComment: "Коментар обрађивача",
        ProcessType: "Врста поступка",
        RFarmDataChange: "Промена података у РПГ",
        FarmBankAccountData:
          "Подаци о текућем рачуну пољопривредног газдинства",
        ChildFarmBankAccountData: "Подаци о повезаном текућем рачуну",
        AccountNumber: "Број рачуна",
        AccountNumberHelper: "Наменски рачун за уплате из буџета",
        SolutionNumber: "Број решења",
        DateOfResolution: "Датум решења",
        BankName: "Назив банке",
        FarmHeadOfficeData: "Подаци о седишту пољопривредног газдинства",
        ChildFarmHeadOfficeData: "Подаци о повезаном седишту",
        FarmRegistrationData: "Подаци о регистрацији газдинства",
        ChildFarmRegistrationData: "Подаци о повезаној регистрацији газдинства",
        FarmHolderType: "Тип носиоца",
        HolderTypephysicalPerson: "Носилац газдинства је физичко лице",
        HolderTypelegalEntity: "Носилац газдинства је правно лице",
        ChangeApprovalStatusToPENDING: "Врати на обраду",
        ChangeApprovalStatusToWAITING_COMPLEMENT: "Врати на допуну",
        FarmApprovals: "Листа отворених захтева ПГ",
        ChangeApprovalStatusToWaitingComplementComment:
          "Разлог враћања на допуну",
        OpenCase: "Отварање предмета",
        CaseNumber: "Број предмета",
        OpenCasePage: "Отварање предмета по службеној дужности",
        LandsConfirmation: "Потврда земљишта",
        ConfirmOnAllLands: "Потврди са свим парцелама",
        ConfirmPartially: "Потврди делимично",
        PartialConfirmationLandsTitle: "Потврда регистрације са делом парцела",
        PartialConfirmationLandsDesc:
          "Одаберите парцеле које ће бити уписане на газдинство",

        ////// APPROVALS GROUPED //////
        ApprovalsGrouped: "Укупан број отворених захтева",
        OpenApprovalsCount: "Укупан број отворених захтева",
        LatestCratedAtDate: "Датум најстаријег захтева",
        ApprovalsStatus: "Збирни статус захтева",
        ApprovalsByGroupPage: "Захтеви по ПГ",
        ModeType: "Врста поступка",
        NonAdministrative: "Вануправни",
        Administrative: "Управни",
        OrgNumberS: "Број ПГ",
        GetFarmToProcessing: "Преузми ПГ на обраду",

        ////// APPROVAL STATUS //////
        ApprovalStatusAPPROVED: "Обрађено",
        ApprovalStatusREJECTED: "Одбијено",
        ApprovalStatusPENDING: "Чека на обраду",
        ApprovalStatusIN_PROGRESS: "У обради",
        ApprovalStatusWAITING_COMPLEMENT: "Чека допуну",
        ApprovalStatusCOMPLEMENTED: "Допуњен",

        ////// CHANGED OBJECT //////
        ChangedObjectBANK_ACCOUNT: "Текући рачун",
        ChangedObjectHEAD_OFFICE: "Седиште пољопривредног газдинства",
        ChangedObjectHOLDERphysicalPerson: "Носиоц физичко лице",
        ChangedObjectHOLDERlegalEntity: "Носиоц правно лице",
        ChangedObjectHOLDER: "Промена података носиоца",
        ChangedObjectTEMPORARY_HOLDER: "Привремени носилац",
        ChangedObjectCHANGE_HOLDER: "Промена носиоца (замена носиоца)",
        ChangedObjectMEMBER: "Члан",
        ChangedObjectREGISTRATION: "Регистрација",
        ChangedObjectLEGAL_REPRESENTATIVE: "Одговорно лице",
        ChangedObjectLAND: "Додавање земљишта",
        ChangedObjectINVOICE: "Рачун за гориво",
        ChangedObjectALL_CHANGED_OBJECTS: "Све промене",

        ////// BASIC INFO //////
        BasicInfoTitle: "Основни подаци о пољопривредном газдинству",
        OrgNumberLong: "Број пољопривредног газдинства",
        StatusPG: "Статус ПГ",
        RenewalDate: "Датум обнове",
        ActiveStatusDays: "Време трајања активног статуса",
        RegistrationDate: "Датум регистрације",
        FirstRegistrationDate: "Датум прве регистрације",
        LatestChangeDate: "Датум последње измене",
        OrganizationalForm: "Организациони облик",
        Member: "Члан",
        MemberName: "Назив члана",
        ChildMember: "Повезани члан",
        MembersCount: "Број чланова ПГ",
        ArgHolderInfo: "Подаци о носиоцу",
        ChildArgHolderInfo: "Подаци о носиоцу",
        ArgHolderEdit: "Измени податке о носиоцу ПГ",
        CreateFarm: "Региструјте пољопривредно газдинство",
        SelectHolderType: "Изаберите тип пољопривредног газдинства:",
        LabelEXCERPT_FROM_REGISTER:
          "Извод - подаци о пољопривредном газдинству",
        LabelEXCERPT_PLANT_CULTURE: "Извод - структура биљне производње",
        LabelEXCERPT_ANIMAL: "Извод - животиње",
        LabelSTATUS_CONFIRMATION: "Потврда о тренутном статусу",
        ShowHistoryDataOnDay: "Приказ података на дан",
        FarmHistoryData: "Подаци о ПГ",
        FarmHistoryDataOnDay: "Подаци о ПГ на дан",
        NoDataForChosenDay: "Нема података за изабрани датум",

        ////// HOLDER //////
        Holder: "Носиоц",
        HolderName: "Назив носиоца",
        HolderPersonalIdentityNumber: "ЈМБГ носиоца",
        HolderRegistrationNumber: "Матични број носиоца",
        PersonalIdentityNumber: "ЈМБГ",
        FirstName: "Име",
        LastName: "Презиме",
        BirthDate: "Датум рођења",
        BirthPlace: "Место рођења",
        BirthCountry: "Држава рођења",
        Gender: "Пол",
        WorkActivity: "Радна активност",
        Education: "Школовање",
        HolderNotVerifiedInfo: "Носилац газдинства није верификован",
        LLC: "Друштво са ограниченом одговорношћу",
        ENTREPRENEUR: "Предузетник",
        MICRO: "Микро",
        SMALL: "Мало",
        MEDIUM: "Средње",
        LARGE: "Велико",
        Temporary: "Привремени",
        Permanently: "Стални",
        HolderTemporary: "Привремени носилац",
        HolderPermanent: "Стални носилац",

        ////// LEGAL ENTITY //////
        LegalEntityInfo: "Подаци о Правном лицу",
        LegalEntitySize: "Величина правног лица",
        RegistrationNumber: "Матични број",
        TaxId: "ПИБ",
        Jbkjs: "ЈБКЈС",
        DedicatedRegisterNumber: "Број из локалног регистра",
        Name: "Назив",
        NumberOfEmployees: "Број стално запослених",
        AgriculturalProduction: "Пољопривредна производња",
        AgriculturalProductsProcessing: "Прерада пољопривредних производа",
        NonAgriculturalProduction:
          "Друге непољопривредне делатности (рурални туризам, стари занати и сл.)",

        LEFormLLC: "Друштво са ограниченом одговорношћу",
        LEFormENTREPRENEUR: "Предузетник",
        LEFormOTHER: "Остало",

        ////// HEAD OFFICE //////
        HeadOfficeTitle: "Седиште пољопривредног газдинства",
        HeadOfficeEdit: "Измени податке о седишту ПГ",
        Street: "Улица",
        NumberLetter: "Број и слово",
        Place: "Место",
        Municipality: "Општина / град",
        PostalCode: "Поштански број",
        HeadOfficeNotVerifiedInfo: "Седиште није верификовано",

        ////// MEMBERS //////
        MembersTitle: "Чланови пољопривредног газдинства",
        MemberNotVerifiedInfo: "Члан није верификован",
        AuthorizedPersonsTitle: "Овлашћени заступници",
        MemberLand: "Земљиште члана",
        HolderLand: "Земљиште носиоца",
        MemberPersonalIdentityNumber: "ЈМБГ члана",

        ////// LAND //////
        LandShort: "Земљиште",
        Land: "Земљишни фонд и биљне културе",
        MembersLandTitle:
          "Земљиште које је члан уступио пољопривредном газдинству",
        UnreportedLandTitle: "Непријављено земљиште у државној својини!",
        UnreportedLandDesc:
          "Постоји {{ha}}ха {{a}}а {{m}}м2 под закупом од државе за које нисте пријавили биљну културу. Сво земљиште у државној својини мора бити пријављено у регистру.",
        LandEdit: "Измени податке о парцели",
        FarmLandData: "Подаци о парцели",
        LandAdd: "Додај парцелу",
        LandDelete: "Уклони целу парцелу",
        DeleteLand: "Уклони парцелу",
        DeleteLandDesc: "Да ли сте сигурни да желите да уклоните парцелу?",
        CadastreNumberShort: "Кат.пар.",
        CadastreNumber: "Број кат. парцеле",
        CadastreMunicipality: "Катастарска општина",
        CadastreMunicipalityNumber: "Катастарска општина/број парцеле",
        LandName: "Назив парцеле",
        CadastreCulture: "Катастарска култура",
        Surface: "Површина парцеле",
        UsageBasis: "Основ коришћења",
        UsageBasisUPZ: "Основ коришћења УПЗ",
        LeaseEndDate: "Трајање закупа до",
        LessorIdentificationNumber: "ЈМБГ / Матични број закуподавца",
        UsedSurface: "Површина која се обрађује",
        CadastreTotalSurface: "Укупна површина у катастру",
        AssignedPartSurface: "Површина уступљеног удела",
        LandUsageBasis: "Тип својине",
        ProcessorUser: "Обрађивач",
        CADASTRE_OWNERSHIP: "Власништво уписано у РГЗ",
        OWNERSHIP_OUT_CADASTRE: "Власништво без РГЗ-а",
        LEASE: "Закуп / Уступање",
        LEASE_CONSOLIDATION: "Закуп земљишта из комасације",
        LEASE_LEGAL_ENTITY: "Закуп земљишта од правног лица",
        CONSOLIDATION: "Комасација",
        UPZ: "УПЗ",
        LandType: "Тип земље",
        LandReportWITHOUT_LEASE_AGREEMENT:
          "Пријава обрађивања земљишта без писаног уговора о закупу",
        LandReportCONSOLIDATION:
          "Пријава обрађивања земљишта из комасације, без решења о расподели комасационе масе",
        LandReportUPZ: "Пријава обрађивања државног земљишта без уговора",
        Submitter: "Подносилац",
        SubmitterFirstNameAndLastName: "Име и презиме подносиоца",
        SubmitterPersonalIdentityNumber: "ЈМБГ подносиоца",
        LandReports: "Пријаве обраде земљишта",
        LandReport: "Пријава обраде земљишта по другом основу",
        CadastreCultureRecapitulationTitle: "Преглед пријављене биљне културе",
        SurfaceTitleFormatted: "Површина: ha a m2",
        TotalSurfaceUnderCultures: "Укупно под културама",
        NoReportedCulture: "Без пријављене културе",
        TotalSurfaces: "Укупно земљиште које се обрађује",
        UpdateDataFromRGZ: "Освежи податке са РГЗ-а",

        // Add land
        AddLandTitle: "Додавање земљишта",
        AddLandDesc:
          "Одаберите основ коришћења земљишта које ће бити регистровано у оквиру ПГ.",

        ////// LAND CULTURES //////
        LandCulture: "Биљна култура",
        LandCulturesTitle: "Биљне културе на парцели",
        LandCultureAdd: "Додај биљну културу на парцелу",
        LandCultureEdit: "Измени податке о култури",
        DeleteLandCulture: "Уклони биљну културу",
        DeleteLandCultureDesc:
          "Да ли сте сигурни да желите да уклоните биљну културу?",
        Culture: "Култура",
        CultureSurface: "Површина под културом",
        NumberOfSeedlings: "Број садница/стабала",
        ProductionType: "Врста производње",
        Irrigation: "Наводњава се",
        HailProtectionSurface: "Противградна заштита на",
        NumberOfThrees: "Број стабала",

        // Land part
        ShowLandPartEvidence: "Прикажи делове парцеле",
        PartLand: "Делови парцеле",
        PartNumber: "Бр. дела",
        PartSurface: "Површина м2",

        ////// APR LAND //////
        APRData: "Подаци са АПР-а",
        DownloadResponseFromAPR: "Преузми одговор са АПР-а",
        UpdateLandDataFromAPR: "Освежи податке са АПР-а",
        IsAprMember: "Да ли је члан привредног друштва које је закуподавац?",
        IsAprConnected:
          "Да ли је директор, члан надзорног одбора, заступник, прокуриста или ликвидациони управник правног лица које је закуподавац?",
        IsAprConnectedLegalEntityMember:
          "Да ли је члан Привредног друштва у чијем је власништву закуподавац?",
        IsAprPartnerOrComplementary:
          "Да ли је ортак или комплементар у друштву које је закуподавац?",
        IsAprFounder: "Да ли је закупац оснивач правног лица?",

        ////// CONTACT //////
        ContactTitle: "Контакт подаци пољопривредног газдинства",
        ContactEdit: "Измени контакт податке",
        Email: "Е-пошта",
        EmailAddress: "Адреса е-поште",
        EmailHelper: "Адреса на коју ће стизати обавештења",
        EmailNotVerified: "Адреса није верификована.",
        EmailNotVerifiedInfo: `Адреса е-поште није верификована.
        Кликните на дугме ”Измени контакт податке”, па затим на дугме ”Потврди адресу е-Поште” и пратите даља упутства.`,
        VerifyEmail: "Потврдите адресу е-поште",
        PhoneNumber: "Број мобилног телефона",
        PhoneNumberHelper: "На овај број ће стизати СМС обавештења",
        PhoneNumberNotVerified: "Број мобилног телефона није верификован.",
        PhoneNumberNotVerifiedInfo: `Број мобилног телефона није верификован.
        Кликните на дугме ”Измени контакт податке”, па затим на дугме ”Потврди број телефона” и пратите даља упутства.`,
        VerifyPhoneNumber: "Потврдите број мобилног телефона",
        EmailConfirmTitle: "Потврда адресе е-поште",
        EmailConfirmDesc:
          "Унесите КОД који Вам је послат на адресу е-поште коју сте унели",
        PhoneConfirmTitle: "Потврда броја телефона",
        PhoneConfirmDesc:
          "Унесите КОД који Вам је послат на број мобилног телефона коју сте унели",
        ResendActivationCode: "Пошаљите ми поново активациони код",

        //// ANIMALS //////
        Animals: "Фонд животиња",
        AnimalInfoDesc: `Подаци о фонду животиња се приказују из регистра Управе за ветерину.
        Уколико су подаци непотпуни или нису тачни обратите се надлежној ветеринарској служби како бисте уредили стање у регистру.`,
        CheckAnimalDetails: "Погледај детаље из управе за ветерину",
        HidShort: "ХИД",
        Hid: "Идентификациони број (ХИД)",
        AnimalName: "Назив животиња",
        AnimalGroup: "Група животиња",
        AnimalCount: "Broj животиња",
        AnimalCountOrganic: "Број животиња (Органскa производња)",
        AnimalCountConventional: "Број животиња (Конвенционална производња)",
        AnimalStatus: "Статус у оквиру ПГ",
        AnimalProductionType: "Начин узгоја",
        AnimalDescriptionAndCount: "Опис и обим узгоја",
        AnimalCONVENTIONAL: "Конвенционални",
        AnimalORGANIC: "Органски",
        Registered: "Пријављен",
        Unregistered: "Непријављен",
        SHEEP: "Овце и козе",
        PIG: "Свиње",
        CATTLE: "Говеда",
        HORSE: "Коњи, магарци, муле и мазге",
        BIRTS: "Живина и птице",
        BEES: "Пчелиње друштво - кошнице",
        FISH: "Рибе",
        OTHER: "Животиње остале",
        CUSTOM: "Остале животиње",
        AnimalHidsSection: "Регистровани ХИД-ови у Управи за ветерину",
        AnimalHidsList: "Листа ХИД-ова",
        AnimalHidsSectionInfo: `Подаци о фонду животиња се преузимају из регистра Управе за ветерину само за већ регистрована Пољопривредна газдинства.
        Молимо да унесете све ХИД-ове регистроване у Управи за ветерину који ће бити пријављени на Ваше газдинство.
        Након добијања Решења о упису ПГ, потебно је да се поново пријавите у систем и проверите податке о животињском фонду.`,
        AnimalsRecapitulationTitle: "Преглед пријављеног животињског фонда",
        EnrolledInUPV: "Уписано у УПВ",
        OrganicFarming: "Органски узгој",
        ConventionalFarming: "Конв. узгој",
        TotalAnimalsCount: "Укупно животиња",

        ////// RENEW //////
        RenewTitle: "Обнова - БПГ: {{number}}",
        RenewalConditionsTable: "Преглед испуњености услова за обнову",
        CountrysideTourism: "Сеоски туризам",
        AtLeastOnePlantCultureOrAnimal: "Пољопривредна производња",
        PlantCulture: "Узгој биљних култура",
        AnimalCulture: "Узгој животиња",
        AllowedAprCodes: "Прерада пољопривредних производа",
        TechnicalPrerequisiteCondition: "Технички предуслови",
        BasicInfoData: "Попуњени основни подаци",
        AnimalNumberData: "Унети подаци о броју животиња према врсти узгоја",
        UpzCondition: "Пријављене културе на целој површини из УПЗ",
        RenewalFarmStatus: "Статус газдинства дозвољава обнову",
        HasEDelivery: "Има активно еСандуче",
        HasEmailOrPhoneNumber: "Има унете контакт податке",
        ReadyForRenewal: "Испуњава услов за обнову",

        ////// INVOICES //////
        Invoices: "Рачуни",
        FuelInvoice: "Повезани рачун за гориво",
        FuelInvoices: "Рачуни за гориво",
        InvoiceNumber: "Број рачуна",
        InvoiceDate: "Датум рачуна",
        GasStation: "Бензинска станица",
        InvoiceItems: "Ставке рачуна",
        SellerTaxId: "ПИБ продавца",

        ////// TABLE //////
        rowsPerPage: "Број редова",
        of: "од",
        allResults: "Сви",
        page: "Страна",
        Filter: "Филтери",
        ResetFilter: "Ресетуј филтере",
        Search: "Претрага",
        Print: "Одштампај",
        PrintExport: "Штампа/Извоз",
        SaveXlsx: "Сачувај XLSX",
        PrintDate: "Датум извештаја",
        from: "од",
        to: "до",
        Sort: "Сортирање",
        Columns: "Колоне",
        DragDropListsInfo:
          "Одаберите колоне за приказивање превлачењем са једне листе на другу",
        HiddenColumns: "Сакривене колоне",
        SelectedColumns: "Одабране колоне",
        NoResults: "Нема резултата",

        ///// LAND RECAPITULATION //////
        RGZDataPreview: "Преглед података из РГЗ",
        RGZLandSurface: "Површина парцеле у РГЗ",
        DownloadDateS: "датум преузимања",
        RPGReportedLandSurface: "Површина пријављена у РПГ (тренутно, тотал)",
        VerificationS: "на верификацији",
        ApprovedS: "уписано",

        ///// FARM STATUS CHANGE //////
        FarmStatusChange: "Промена статуса газдинства",
        FarmStatusHistoryChange: "Историја промене статуса газдинства",
        NewStatus: "Нови статус",
        OldStatus: "Стари статус",
        PassiveStatusReason: "Разлог пасивности / брисања",
        DecisionNumber: "Број решења",
        FarmStatusACTIVE: "Активан",
        FarmStatusPASSIVE: "Пасиван",
        FarmStatusDELETED: "Избрисан",

        ////// PLANT PROTECTION PRODUCTS //////
        PlantProtectionProducts: "Средства за заштиту биљa",
        PlantProtectionProduct: "Средство за заштиту биља",
        PlantProtectionProductDesc:
          "Да ли сте сигурни да желите да обришете средство за заштиту биља",
        AddPlantProtectionProduct: "Додај средство за заштиту биља",
        EditPlantProtectionProduct: "Измени средство за заштиту биља",

        ///// FARM DATA CHANGE //////
        FarmDataChange: "Промена статусних података",
        RenewalDateChange: "Промена датума обнове",
        LegalEntityChange: "Промена основних података правног лица",
        PersonalIdentityNumberChange: "Промена ЈМБГ-а",
        LegalRepresentative: "Одговорно лице",
        LegalRepresentativeInfo: "Подаци о одговорном лицу",
        ChildLegalRepresentativeInfo: "Подаци о повезаном одговорном лицу",

        ///// HOLDER/MEMBER DATA CHANGE /////
        HolderMemberDataChange: "Промена података носиоца/члана",
        HolderDataChange: "Промена података носиоца",
        MemberDataChange: "Промена података члана",
        HolderPhysicalPerson: "Носиоц мора бити физичко лице",

        ///// UPLOAD DOCUMENT /////
        ImportExcelFile: "Увоз Ексел фајла",
        ExcelImportTitle: "Резултат увоза ексел фајла",
        RowsNumberChanged: "Број измењених редова",
        LoadedFile: "Учитан фајл",

        ////// FILES //////
        Document: "Документ",
        RemoveDocument: "Уклони прилог",
        RemoveDocumentDesc: "Да ли сте сигурни да желите да уклоните прилог",
        PCUpload: "Додај са рачунара",
        UploadDocument: "Додај прилог",
        justDragAndDropFile: "или само превуци документ са рачунара овде",

        ////// ADMIN USERS //////
        AdminUsers: "Кориснички налози",
        AddAdminUser: "Додај кориснички налог",
        EditAdminUser: "Измени кориснички налог",
        Role: "Рола",
        RoleIsMissingDesc:
          "Нисте изабрани ролу. Корисник ће изгубити администраторски приступ.",

        ///// REPORTS /////
        Reports: "Извештаји",
        ErpgReport: "Извештај о пољопривредним газдинствима у систему",
        ApprovalsReport: "Извештај о необрађеним захтевима",
        TotalErpgFarms: "Укупно пољoпривредних газдинстава",
        ActiveFarms: "Активна пољoпривредна газдинства",
        PassiveFarms: "Пасивна пољoпривредна газдинства",
        RenewedFarmsInCurrentYear:
          "Обновљена пољoпривредна газдинства у текућој години",
        FarmsOnVerification:
          "Пољопривредна газдинства која су тренутно на верификацији",
        RegisteredFarmsInCurrentYear:
          "Регистрована пољoпривредна газдинства у текућој години",
        WorkRequestsReport: "Извештај о генерисаним захтевима",
        Pesticides: "Пестициди",
        PesticideProcurements: "Извештај о куповини пестицида",

        TotalSubmittedRequests: "Укупно поднетих захтева",
        TotalResolutionsIssued: "Укупно донетих решења",
        TotalPayedRequests: "Укупно исплаћених захтева",
        TotalRejectedRequests: "Укупно неисправних/одбијених захтева",
        TotalRequestsInDraft: "Укупно захтева у нацрту",
        TotalSentToPaymentRequests: "Укупно захтева послатих на плаћање",

        ShowReport: "ПРИКАЖИ ИЗВЕШТАЈ",
        RefractionRequestSummaryReport:
          "Приказ сумарног извештаја о захтевима за рефракцију",

        ///// WORK REQUESTS /////
        WorkRequestStatusCOMPLETED: "Завршено",
        WorkRequestStatusFAILED: "Неуспешно",
        WorkRequestStatusPENDING: "У припреми",
        CompletionPercentage: "Проценат завршености",

        ////// RERFACTION //////
        RefractionTitle: "Захтев за рефакцију",
        RefractionRequests: "Захтеви за рефакцију",
        RequestItems: "Ставке захтева",
        RefactionStatusDRAFT: "У припреми",
        RefactionStatusSUBMITTED: "Примљен",
        RefactionStatusAPPROVED: "Одобрен",
        RefactionStatusREJECTED: "Одбијен",
        RefactionStatusSENT: "Послат",
        RefactionStatusREADY_FOR_PAYMENT: "Спреман за плаћање",
        RefactionStatusPAYED: "Плаћен",

        ////// GAS STATIONS //////
        GasStations: "Продавци нафтних деривата",
        AddGasStation: "Додај продавца",
        EditGasStation: "Измени продавца",
        NameOrTaxId: "Назив или ПИБ",
        CodeOrName: "Шифра или назив",
        Code: "Шифра",
        FuelType: "Врста горива",
        GasStationProducts: "Производи продавца",
        AddProduct: "Додај производ",
        EditProduct: "Измени производ",
        FuelTypeGAS_OIL: "Дизел",
        FuelTypeBIO_FUEL: "Биодизел",

        //// ERROR /////
        ErrorTitle: "Дошло је до грешке",
        ErrorFARM_NOT_FOUND: "Пољопривредно газдинство није пронађено",
        ErrorAPPROVAL_OCCUPIED:
          "Поступак је у процесу обраде од стране другог корисника",
        ErrorPASSIVE_STATUS_REASON_REQUIRED:
          "Разлог пасивности / брисања је обавезно поље",
        ErrorMULTIPLE_LAND_FOUND_FOR_CADASTRE_NUMBER:
          "Пронађене су вишеструке парцеле за катастарски број {{ cadastreNumber }}. Општина {{ cadastreMuniciplaity }}.",
        ErrorLAND_WITH_CADASTRE_NUMBER_NOT_FOUND:
          "Парцела са катастарским бројем {{ cadastreNumber }} није пронађена у РГЗ-у. Општина {{ cadastreMuniciplaity }}.",
        ErrorHISTORY_AT_DAY_BEFORE_REGISTRATION_DATE:
          "Газдинство је регистровано након одабраног датума.",
        ErrorHOLDER_BY_REGISTRATION_NUMBER_NOT_FOUND:
          "Носилац са овим МБ није пронађен",
        ErrorHOLDER_BY_UMNC_NOT_FOUND: "Носилац са овим ЈМБГ није пронађен",
      },
    },
  },
});

export default i18n;
