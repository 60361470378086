import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import { SubSection } from "../../SubSection";
import { getFarmReports } from "../../../repos/FarmRepo";
import { ISummaryReport } from "../../../types/IReport";

export const ErpgTotalsReport: FC = () => {
  const [data, setData] = useState<ISummaryReport>({});

  const { t } = useTranslation();

  useEffect(() => {
    getFarmReports().then((data) => setData(data));
  }, []);

  const renewalDateInfo: IInputComponentProps[] = [
    {
      label: t("TotalErpgFarms"),
      value: data.activeOrPassive || "/",
    },
    {
      label: t("ActiveFarms"),
      value: data.active || "/",
    },
    {
      label: t("PassiveFarms"),
      value: data.passive || "/",
    },
    {
      label: t("RenewedFarmsInCurrentYear"),
      value: data.farmRenewedInCurrentYear || "/",
    },
    {
      label: t("FarmsOnVerification"),
      value: data.farmOnVerification || "/",
    },
    {
      label: t("RegisteredFarmsInCurrentYear"),
      value: data.farmRegisteredInCurrentYear || "/",
    },
  ];

  return (
    <SubSection title={t("ErpgReport")} expandable={false}>
      <div className="form-container">
        {renewalDateInfo.map(
          (e) => !e.hidden && <Input key={e.label} component={e} />
        )}
      </div>
    </SubSection>
  );
};
