import { FC, useState } from "react";
import { Select } from "../../Inputs/Select";
import { useTranslation } from "react-i18next";
import { SubSection } from "../../SubSection";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import { IChangedObject, changedObjects } from "../../../types/IApprovals";
import { Button } from "@inceptionbg/ui-components";
import { uploadApproval } from "../../../repos/ApprovalsRepo";

export const ApprovalsReport: FC = () => {
  const [changedObjectValue, setChangedObjectValue] =
    useState<IChangedObject>();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const approvalSelection: IInputComponentProps[] = [
    {
      label: t("ChangedObject"),
      input: (
        <Select
          placeholder={`${t("Choose")}...`}
          isClearable
          value={
            changedObjectValue
              ? {
                  value: changedObjectValue,
                  label: t(`ChangedObject${changedObjectValue}`),
                }
              : null
          }
          options={changedObjects
            .filter((e) => e !== "CHANGE_HOLDER" && e !== "TEMPORARY_HOLDER")
            .map((e) => ({
              value: e,
              label: t(`ChangedObject${e}`),
            }))}
          onChange={(data) => setChangedObjectValue(data?.value)}
        />
      ),
    },
  ];

  return (
    <SubSection
      title={t("ApprovalsReport")}
      expandable={false}
      isLoading={isLoading}
    >
      <div className="form-container">
        {approvalSelection.map(
          (e) => !e.hidden && <Input key={e.label} component={e} edit />
        )}
        <div className="flex justify-right">
          <Button
            label={t("DownloadXlsx")}
            onClick={() => {
              setIsLoading(true);
              uploadApproval(changedObjectValue).finally(() =>
                setIsLoading(false)
              );
            }}
            size="s"
            className="primary"
            primary
          />
        </div>
      </div>
    </SubSection>
  );
};
