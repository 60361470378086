import { ITable, Table as TableIUI } from "@inceptionbg/ui-components";
import { FC } from "react";
import { t } from "i18next";

export const Table: FC<ITable> = (props) => (
  <TableIUI
    {...props}
    translations={{
      Cancel: t("Cancel"),
      Confirm: t("Confirm"),
      Sort: t("Sort"),
      noDataText: t("NoResults"),
      pagination: {
        allResults: t("allResults"),
        of: t("of"),
        page: t("page"),
        rowsPerPage: t("rowsPerPage"),
      },
      filter: {
        Filter: t("Filter"),
        ResetFilter: t("ResetFilter"),
        Delete: t("Delete"),
        Search: t("Search"),
      },
      print: {
        Print: t("Print"),
        PrintExport: t("PrintExport"),
        SaveXlsx: t("SaveXlsx"),
        TaxId: t("TaxId"),
        RegistrationNumber: t("RegistrationNumber"),
        PrintDate: t("PrintDate"),
        from: t("from"),
        to: t("to"),
      },
      columns: {
        Columns: t("Columns"),
        DragDropListsInfo: t("DragDropListsInfo"),
        HiddenColumns: t("HiddenColumns"),
        SelectedColumns: t("SelectedColumns"),
      },
    }}
  />
);
