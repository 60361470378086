import { FC, ReactNode, useEffect } from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { getUserPrivileges, getUserUuid } from "../../repos/UserRepo";
import { useDispatch } from "react-redux";
import {
  setUserPrivileges,
  setUserUuid,
} from "../../redux/reducers/UserReducer";

interface Props {
  children: ReactNode;
}

export const PageWrapper: FC<Props> = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getUserPrivileges()
      .then((data) => dispatch(setUserPrivileges(data)))
      .catch(() => {});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserUuid()
      .then((data) => dispatch(setUserUuid(data)))
      .catch(() => {});
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <div className="page">{children}</div>
      <Footer />
    </>
  );
};
