import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Select, TextInput } from "@inceptionbg/ui-components";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { SubSection } from "../../../SubSection";
import { changeFarmData } from "../../../../repos/FarmRepo";
import { IHolderNew } from "../../../../types/IHolder";

export const FarmPersonalIdentityNumberChange: FC = () => {
  const [regNum, setRegNum] = useState("");
  const [isHolder, setIsHolder] = useState<boolean>();
  const [data, setData] = useState<IHolderNew>({});
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const renewalDateInfo: IInputComponentProps[] = [
    {
      label: t("OrgNumberShort"),
      input: (
        <TextInput required value={regNum} setValue={(e) => setRegNum(e)} />
      ),
    },
    {
      label: t("PersonalIdentityNumberChange"),
      input: (
        <Select
          placeholder={`${t("Choose")}...`}
          isClearable
          value={
            typeof isHolder === "boolean"
              ? {
                  value: isHolder,
                  label: t(isHolder ? "Holder" : "LegalRepresentative"),
                }
              : null
          }
          options={[true, false].map((e) => ({
            value: e,
            label: t(e ? "Holder" : "LegalRepresentative"),
          }))}
          onChange={(data) =>
            setIsHolder(
              typeof data?.value === "boolean" ? data.value : undefined
            )
          }
        />
      ),
    },
    {
      label: t("PersonalIdentityNumber"),
      input: (
        <TextInput
          required
          value={data.personalIdentityNumber}
          setValue={(e) => setData({ ...data, personalIdentityNumber: e })}
        />
      ),
    },
  ];

  return (
    <SubSection
      title={t("PersonalIdentityNumberChange")}
      expandable={false}
      isLoading={isLoading}
      className="mb-3"
    >
      <div className="form-container">
        {renewalDateInfo.map(
          (e) => !e.hidden && <Input key={e.label} component={e} edit />
        )}
        <div className="flex justify-right">
          <Button
            label={t("Save")}
            onClick={() => {
              setIsLoading(true);
              changeFarmData(
                regNum,
                isHolder
                  ? { holder: { ...data, type: "physicalPerson" } }
                  : { legalRepresentative: data }
              )
                .then(() => {
                  setData({});
                  setRegNum("");
                  setIsHolder(undefined);
                })
                .catch(() => {})
                .finally(() => setIsLoading(false));
            }}
            disabled={
              !regNum ||
              !data.personalIdentityNumber ||
              typeof isHolder !== "boolean"
            }
            size="s"
            className="primary"
            primary
          />
        </div>
      </div>
    </SubSection>
  );
};
