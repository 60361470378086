import {
  Button,
  ITableColumn,
  ITableDataItem,
  formatDateAndTime,
} from "@inceptionbg/ui-components";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getWorkRequests } from "../../../repos/WorkRequestRepo";
import { Table } from "../../CustomIUI/Table/Table";
import { SubSection } from "../../SubSection";
import { downloadFile } from "../../../repos/FileRepo";

export const WorkRequestsReport: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleGetWorkRequests = useCallback(() => {
    setIsLoading(true);
    getWorkRequests({ limit, offset })
      .then(({ workRequests }) => {
        setData(
          workRequests.map((workRequest) => ({
            uuid: workRequest.uuid,
            cells: {
              name: {
                value: workRequest.name
                  ? t(`ChangedObject${workRequest.name}`)
                  : "",
              },
              startTime: { value: formatDateAndTime(workRequest.startTime) },
              completionPercentage: {
                value: workRequest.completionPercentage + "%",
              },
              status: {
                value: <p>{t(`WorkRequestStatus${workRequest.status}`)}</p>,
              },
              actions: {
                value: workRequest.status === "COMPLETED" && (
                  <Button
                    label={t("DownloadForProcessing")}
                    onClick={() =>
                      downloadFile(workRequest.storedFile?.downloadUrl!).then(
                        (response) => {
                          const blob = new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          });
                          const url = window.URL.createObjectURL(blob);

                          window.open(url, "_blank");
                        }
                      )
                    }
                    outlined
                    size={"s"}
                    disabled={workRequest.status !== "COMPLETED"}
                  />
                ),
              },
            },
          })) as ITableDataItem[]
        );
        setTotalRows(totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [t, totalRows, limit, offset]);

  useEffect(handleGetWorkRequests, [handleGetWorkRequests]);

  const columns: ITableColumn[] = [
    { id: "name", label: t("Name") },
    { id: "startTime", label: t("CreatedAtTime"), align: "center" },
    { id: "completionPercentage", label: t("CompletionPercentage") },
    { id: "status", label: t("Status") },
    { id: "actions", label: t("Actions") },
  ];

  return (
    <>
      <SubSection title={t("WorkRequestsReport")} expandable={false}>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          serverSidePagination={{
            limit,
            offset,
            setLimit,
            setOffset,
            totalRows,
          }}
          footerAction={{
            onClick: handleGetWorkRequests,
          }}
        />
      </SubSection>
    </>
  );
};
