import { axiosInstance } from "../App";
import { IPagination } from "@inceptionbg/ui-components";
import {
  IGasStation,
  IGasStationProduct,
  IGasStationProductSearch,
  IGasStationSearch,
} from "../types/IGasStation";

export const getGasStations = (
  pagination: IPagination,
  search?: IGasStationSearch
) =>
  axiosInstance
    .get(`/gas-stations`, { params: { ...search, ...pagination } })
    .then(({ data }) => ({
      gasStations: data.gasStations as IGasStation[],
      totalRows: data.totalRows as number,
    }));

export const getGasStationByUuid = (gasStationUuid: string) =>
  axiosInstance
    .get(`/gas-station/${gasStationUuid}`)
    .then(({ data }) => data as IGasStation);

export const saveOrUpdateGasStation = (data: Partial<IGasStation>) =>
  axiosInstance.post(`/gas-station`, data);

export const getGasStationProducts = (
  gasStationUuid: string,
  pagination: IPagination,
  search?: IGasStationProductSearch
) =>
  axiosInstance
    .get(`/gas-station/${gasStationUuid}/products`, {
      params: { ...search, ...pagination },
    })
    .then(({ data }) => ({
      products: data.products as IGasStationProduct[],
      totalRows: data.totalRows as number,
    }));

export const getGasStationProductByUuid = (productUuid: string) =>
  axiosInstance
    .get(`/gas-station/product/${productUuid}`)
    .then(({ data }) => data as IGasStationProduct);

export const saveOrUpdateGasStationProduct = (
  data: Partial<IGasStationProduct>
) => axiosInstance.post(`/gas-station/product`, data);
