import { FC, useContext, useState } from "react";
import logo from "../assets/svg/eAgrarLogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "../assets/icons/light/faCircleUser";
import { faAngleDown } from "../assets/icons/light/faAngleDown";
import { useTranslation } from "react-i18next";
import { UserContext } from "../App";
import { ChangeLanguage } from "./ChangeLanguage";
import { Menu, MenuItem } from "@inceptionbg/ui-components";
import { Link } from "react-router-dom";
import { logout } from "../repos/AuthRepo";

export const Header: FC = () => {
  const [isUserOpen, setIsUserOpen] = useState(false);

  const user = useContext(UserContext);
  const { t } = useTranslation();

  const navItems = [
    { label: t("Home"), url: "/" },
    // { label: t("Help"), url: "/" },
    // { label: t("Contact"), url: "/" },
  ];

  return (
    <div className="header">
      <div className="top-content">
        {/*<p>{t("Settings")}</p>*/}
        <ChangeLanguage />
      </div>
      <div className="bottom-content">
        <img src={logo} alt="eAgrar" height={52} />
        {user && (
          <div className="flex align-center gap-3">
            {navItems.map((e) => (
              <Link key={e.label} className="nav-item" to={e.url}>
                {e.label}
              </Link>
            ))}
            <Menu
              isOpen={isUserOpen}
              onClose={() => setIsUserOpen(false)}
              placementX="right"
              renderButton={(ref) => (
                <div
                  ref={ref}
                  className="user ml-3"
                  onClick={() => setIsUserOpen(!isUserOpen)}
                >
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    className="icon-3 color-primary"
                  />
                  <div>
                    <p>{`${user.firstName} ${user.lastName}`}</p>
                    {/* <p>{`${t("OrgNumberShort")}: ${
                      farm?.registrationNumber || "/"
                    }`}</p> */}
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              )}
            >
              {/* <MenuItem label={t("ChangeFarm")} onClick={changeFarm} /> */}
              <MenuItem label={t("LogOut")} onClick={() => logout()} />
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
};
