import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ITableColumn,
  ITableDataItem,
} from "@inceptionbg/ui-components";
import { Table } from "../../../Components/CustomIUI/Table/Table";
import {
  getGasStationProducts,
  saveOrUpdateGasStationProduct,
} from "../../../repos/GasStationRepo";
import {
  IGasStationProduct,
  IGasStationProductSearch,
} from "../../../types/IGasStation";
import { Dialog } from "../../../Elements/Dialog";
import { GasStationProductForm } from "./GasStationProductForm";
import { useAppSelector } from "../../../redux/reduxHooks";
import { GasStationProductsFilters } from "./GasStationProductsFilters";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "../../../assets/icons/regular/faArrowLeft";

interface IProps {
  gasStationUuid: string;
}
export const GasStationProductsTable: FC<IProps> = ({ gasStationUuid }) => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [details, setDetails] = useState<Partial<IGasStationProduct>>({});
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);

  const [search, setSearch] = useState<IGasStationProductSearch>({});
  const [searchData, setSearchData] = useState<IGasStationProductSearch>({});

  const { t } = useTranslation();
  const navigate = useNavigate();
  const hasWPrivilege = useAppSelector(
    (state) => !!state.user.privileges.GAS_STATION_R
  );

  const handleGetProducts = useCallback(() => {
    setIsLoading(true);
    getGasStationProducts(gasStationUuid, { limit, offset }, search)
      .then(({ products, totalRows }) => {
        setData(
          products.map((e) => ({
            uuid: e.uuid,
            cells: {
              code: {
                value: e.code,
              },
              name: {
                value: e.name,
              },
              fuelType: {
                value: t(`FuelType${e.fuelType}`),
              },
              actions: {
                value: (
                  <Button
                    label={t("Edit")}
                    onClick={() => {
                      setDetails(e);
                      setIsUserFormOpen(true);
                    }}
                    outlined
                    size={"s"}
                  />
                ),
              },
            },
          })) as ITableDataItem[]
        );
        setTotalRows(totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [gasStationUuid, limit, offset, t, search]);

  useEffect(handleGetProducts, [handleGetProducts]);

  const columns: ITableColumn[] = [
    { id: "code", label: t("Code") },
    { id: "name", label: t("Name") },
    { id: "fuelType", label: t("FuelType") },
    { id: "actions", label: "", align: "center", unavailable: !hasWPrivilege },
  ];

  const onSearch = (search: IGasStationProductSearch) => setSearch(search);

  const onSubmit = () => {
    setIsLoading(true);
    saveOrUpdateGasStationProduct({
      ...details,
      gasStation: { uuid: gasStationUuid },
    })
      .then(() => {
        handleGetProducts();
        setDetails({});
        setIsUserFormOpen(false);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className="flex justify-right">
        <Button
          label={t("Back")}
          icon={faArrowLeft}
          size="s"
          outlined
          onClick={() => navigate("/gas-stations")}
        />
        {hasWPrivilege && (
          <Button
            className="ml-3"
            label={t("AddProduct")}
            size="s"
            primary
            onClick={() => setIsUserFormOpen(true)}
          />
        )}
      </div>
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        serverSidePagination={{ limit, offset, setLimit, setOffset, totalRows }}
        filterData={{
          columns: ["name"],
          filters: GasStationProductsFilters(searchData, setSearchData),
          search,
          searchData,
          setSearchData,
          onSubmit: onSearch,
        }}
      />
      <Dialog
        className="over"
        isOpen={isUserFormOpen}
        onClose={() => {
          setDetails({});
          setIsUserFormOpen(false);
        }}
        desc={t(!!details.uuid ? "EditProduct" : "AddProduct")}
        children={
          <GasStationProductForm
            data={details}
            setData={setDetails}
            onSubmit={onSubmit}
          />
        }
        cancelButton={{
          onClick: () => {
            setDetails({});
            setIsUserFormOpen(false);
          },
        }}
      />
    </>
  );
};
