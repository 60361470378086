import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../Components/Sidebar";
import { useAppSelector } from "../../redux/reduxHooks";
import { NoAccessComponent } from "../../Components/Common/NoAccessComponent";
import { ApprovalsGroupedTable } from "../../Components/Tables/ApprovalsGroupedTable";

export const ApprovalsGroupedPage: FC = () => {
  const { t } = useTranslation();
  const hasPrivilegeR = useAppSelector(
    (state) => state.user.privileges.APPROVAL_R
  );

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t("OpenApprovalFarms")}`}</h1>
      </div>
      <div className="flex p-3">
        <Sidebar />
        {hasPrivilegeR ? (
          <div className="full-width">
            <ApprovalsGroupedTable />
          </div>
        ) : (
          <NoAccessComponent />
        )}
      </div>
    </div>
  );
};
