import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  ITableColumn,
  ITableDataItem,
} from "@inceptionbg/ui-components";
import { Table } from "../CustomIUI/Table/Table";
import { getLandReports } from "../../repos/LandRepo";
import { SurfaceValue } from "../Inputs/Surface/SurfaceValue";
import { convertSurfaceToObj } from "../../utils/CalculateUtils";
import { ILandReport } from "../../types/ILand";
import { LandReportForm } from "../Sections/Land/LandReportForm";

export const LandReportsTable: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [reportDetails, setReportDetails] = useState<ILandReport>({});

  const { t } = useTranslation();

  const handleGetLandReports = useCallback(() => {
    setIsLoading(true);
    getLandReports({ limit, offset })
      .then(({ reports, totalRows }) => {
        setData(
          reports.map((e) => ({
            uuid: e.uuid,
            cells: {
              cadastreMunicipality: {
                value: e.cadastreMunicipality?.name || "",
              },
              cadastreNumber: {
                value: e.cadastreNumber || "",
              },
              type: {
                value: e.type ? t(`LandReport${e.type}`) : "",
              },
              usedSurface: {
                value: (
                  <SurfaceValue
                    valueObj={convertSurfaceToObj(e.usedSurface)}
                    simple
                  />
                ),
              },
              submitter: {
                value: e.farm?.registrationNumber || "",
              },
              status: {
                value: e.status ? t(`${e.status}`) : "",
              },
              actions: {
                value: (
                  <Button
                    label={t("ShowDetails")}
                    onClick={() => setReportDetails(e)}
                    outlined
                    size={"s"}
                  />
                ),
              },
            },
          })) as ITableDataItem[]
        );
        setTotalRows(totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [limit, offset, t]);

  useEffect(handleGetLandReports, [handleGetLandReports]);

  const columns: ITableColumn[] = [
    { id: "cadastreMunicipality", label: t("CadastreMunicipality") },
    { id: "cadastreNumber", label: t("CadastreNumber") },
    { id: "type", label: t("LandType") },
    { id: "usedSurface", label: t("UsedSurface") },
    { id: "submitter", label: t("Submitter") },
    { id: "status", label: t("Status") },
    { id: "actions", label: "" },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        serverSidePagination={{ limit, offset, setLimit, setOffset, totalRows }}
        footerAction={{
          onClick: handleGetLandReports,
        }}
      />
      <Dialog
        isOpen={!!reportDetails.uuid}
        onClose={() => setReportDetails({})}
        title={t("LandReport")}
        children={<LandReportForm landReport={reportDetails} />}
        cancelButton={{ onClick: () => setReportDetails({}) }}
      />
    </>
  );
};
