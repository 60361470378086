import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoBox } from "../../../Elements/Alert/InfoBox";
import {
  getLands,
  getLandsSum,
  getUnclaimedLand,
} from "../../../repos/LandRepo";
import { ILand, ILandsSum } from "../../../types/ILand";
import { convertSurfaceToObj } from "../../../utils/CalculateUtils";
import { Section } from "../../Section";
import { IFarm } from "../../../types/IFarm";
import { Land } from "../../Procedures/Land";
import { LandsSumRecapitulationTable } from "../../Recapitulation/LandsSumRecapitulationTable";
import { IUpdateApprovalEntityData } from "../../../types/IApprovals";
import { updateApproval } from "../../../repos/ApprovalsRepo";

interface Props {
  farm: IFarm;
  approvalUuid?: string;
  editable?: boolean;
  hideUpdateInfoRgz?: boolean;
  refreshApproval?: () => void;
}

export const LandSection: FC<Props> = ({
  farm,
  approvalUuid,
  editable = false,
  hideUpdateInfoRgz = true,
  refreshApproval,
}) => {
  const [lands, setLands] = useState<ILand[]>([]);
  const [unclaimedSurface, setUnclaimedSurface] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [landsSum, setLandsSum] = useState<ILandsSum>();
  const [isSumLoading, setIsSumLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const { t } = useTranslation();

  const handleGetLands = useCallback(
    (callback?: () => void) => {
      if (farm) {
        if (farm.lands) {
          setLands(farm.lands);
        } else {
          setIsLoading(true);
          getLands(farm.uuid, {
            fetchOptions: [
              "CADASTRE_CULTURE",
              "CADASTRE_MUNICIPALITY",
              "VERFICATION_STATUS",
              "LEASE_TYPE",
            ],
          })
            .then((e) => {
              setLands(e.lands);
              callback && callback();
            })
            .then(() => {
              getUnclaimedLand(farm.uuid)
                .then((surface) => setUnclaimedSurface(surface | 0))
                .catch(() => {});
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
        }
      }
    },
    [farm]
  );

  useEffect(handleGetLands, [handleGetLands]);

  const handleGetLandsSum = useCallback(() => {
    if (farm) {
      setIsSumLoading(true);
      getLandsSum(farm.uuid)
        .then((e) => setLandsSum(e.sum))
        .catch(() => {})
        .finally(() => setIsSumLoading(false));
    }
  }, [farm]);

  useEffect(handleGetLandsSum, [handleGetLandsSum]);

  const onApprovalUpdate = (data: IUpdateApprovalEntityData) => {
    setIsUpdateLoading(true);
    !!approvalUuid &&
      updateApproval(approvalUuid, data)
        .then(refreshApproval)
        .catch(() => {})
        .finally(() => setIsUpdateLoading(false));
  };

  return (
    <Section id="land" title={t("Land")}>
      <div className="form-container">
        {unclaimedSurface > 0 && (
          <InfoBox
            title={t("UnreportedLandTitle")}
            className={"danger"}
            desc={t(
              "UnreportedLandDesc",
              convertSurfaceToObj(unclaimedSurface)!
            )}
          />
        )}
        {!!landsSum && !isSumLoading && (
          <LandsSumRecapitulationTable landsSum={landsSum} />
        )}
        {farm &&
          !isLoading &&
          lands.map((land) => (
            <Land
              key={land.uuid}
              land={land}
              farm={farm}
              isUpdateLoading={isUpdateLoading}
              editable={editable}
              onSubmit={onApprovalUpdate}
              hideUpdateInfoRgz={hideUpdateInfoRgz}
            />
          ))}
      </div>
    </Section>
  );
};
