import { ISimpleObjectWithCode } from "@inceptionbg/ui-components";
import { IEvidence } from "./IFile";
import { IMemberNew } from "./IMember";
import { IUser } from "./IUser";
import { IFarm } from "./IFarm";

export const landUsageBasis = [
  "CADASTRE_OWNERSHIP",
  "OWNERSHIP_OUT_CADASTRE",
  "CONSOLIDATION",
  "LEASE",
  "LEASE_LEGAL_ENTITY",
  "LEASE_CONSOLIDATION",
] as const;
export type ILandUsageBasis = (typeof landUsageBasis)[number];

export const productionType = ["CONVENTIONAL", "ORGANIC"] as const;
export type IProductionType = (typeof productionType)[number];

export const landReportType = [
  "WITHOUT_LEASE_AGREEMENT",
  "CONSOLIDATION",
  "UPZ",
] as const;
export type ILandReportType = (typeof landReportType)[number];

export interface ILand {
  uuid: string;
  usageBasis: ILandUsageBasis | "UPZ";
  upzBase?: ISimpleObjectWithCode;
  cadastreMunicipality: ISimpleObjectWithCode;
  cadastreNumber: string;
  cadastreCulture: ISimpleObjectWithCode;
  surface: number;
  usedSurface: number;
  cadastreTotalSurface: number;
  lessorIdentification: string;
  nickname?: string;
  leaseEndDate?: string;
  evidences?: IEvidence[];
  member?: IMemberNew;
  approved?: boolean;
  farm?: IFarm;
  leaseType?: ISimpleObjectWithCode;
  surfaceObj?: ISurfaceObj;
}

export interface ILandReport {
  uuid?: string;
  cadastreMunicipality?: ISimpleObjectWithCode;
  cadastreNumber?: string;
  usedSurface?: number | string;
  type?: ILandReportType;
  status?: "PENDING" | "FORWARDED" | "CONCLUDED";
  createdAt?: string;
  createdBy?: IUser;
  farm?: IFarm;
  note?: string;
  creatorPersonalIdentityNumber?: string;
}

export interface ISurfaceObj {
  ha?: string;
  a?: string;
  m?: string;
}

export type LandFetchOption =
  | "CADASTRE_CULTURE"
  | "CADASTRE_MUNICIPALITY"
  | "VERFICATION_STATUS"
  | "MEMBER"
  | "LEASE_TYPE";

export interface ICultureNew {
  uuid?: string;
  plantCulture?: ISimpleObjectWithCode;
  productionType?: IProductionType;
  surface?: number | string;
  surfaceObj?: ISurfaceObj;
  hailProtectionSurface?: number | string;
  hailProtectionSurfaceObj?: ISurfaceObj;
  numberOfSeedlings?: number | string;
  irrigation?: boolean;
  numberOfTrees?: number | string;
}

export interface ICulture extends ICultureNew {
  uuid: string;
  plantCulture: ISimpleObjectWithCode;
  productionType?: IProductionType;
  surface?: number;
  hailProtectionSurface?: number;
  numberOfSeedlings?: number;
  irrigation?: boolean;
}

export interface ILandPlantCulture extends ICulture {
  land?: ILand;
}

export interface ILandsSum {
  totalByCultures: ICulture[];
  totalSurface: number;
  totalSurfaceUnderCultures: number;
}

export interface ILandPart {
  partNumber?: string;
  partSurface?: number;
  cadastreCulture?: ISimpleObjectWithCode;
}

export interface ILandInfo {
  owners: ILandOwner[];
  usedSurfaces: IUsedSurface[];
  downloadDate?: string;
  totalSurface?: number;
  totalUsedSurface?: number;
}

export interface IUsedSurface {
  registrationNumber: string;
  usedSurface: number;
  usageBasis: ILandUsageBasis | "UPZ";
  approved: boolean;
}

export interface ILandOwner {
  totalSurface: number;
  ownedSurface: number;
  ownerName: string;
  ownerUmcn?: string;
  shareDesc?: string;
}

export interface IAprResponse {
  member?: boolean;
  connected?: boolean;
  connectedLegalEntityMember?: boolean;
  partnerOrComplementary?: boolean;
  founder?: boolean;
}
