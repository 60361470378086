import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../../Elements/Input";
import { ICulture } from "../../../types/ILand";
import { convertSurfaceToObj } from "../../../utils/CalculateUtils";
import { SurfaceValue } from "../../Inputs/Surface/SurfaceValue";

interface Props {
  culture?: ICulture;
}

export const LandCultureForm: FC<Props> = ({ culture = {} }) => {
  const { t } = useTranslation();

  const surfaceObj = convertSurfaceToObj(culture.surface);
  const hailProtectionSurfaceObj = convertSurfaceToObj(
    culture.hailProtectionSurface
  );

  const cultureInfo: IInputComponentProps[] = [
    {
      label: t("Culture"),
      value: culture.plantCulture?.name,
    },
    {
      label: t("CultureSurface"),
      valueElement: <SurfaceValue valueObj={surfaceObj} />,
    },
    {
      label: t("NumberOfSeedlings"),
      value: `${culture.numberOfSeedlings || "/"}`,
    },
    {
      label: t("ProductionType"),
      value: culture.productionType ? t(culture.productionType) : "/",
    },
    {
      label: t("Irrigation"),
      value: t(culture.irrigation ? "Yes" : "No"),
    },
    {
      label: t("HailProtectionSurface"),
      valueElement: <SurfaceValue valueObj={hailProtectionSurfaceObj} />,
    },
  ];

  return (
    <div className="form-container">
      {cultureInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} />
      )}
    </div>
  );
};
