import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IApprovalNew, IApprovalSearch } from "../../types/IApprovals";
import {
  Button,
  clsx,
  deleteProps,
  formatDate,
  ITableColumn,
  ITableDataItem,
} from "@inceptionbg/ui-components";
import { Table } from "../CustomIUI/Table/Table";
import { Dialog } from "../../Elements/Dialog";
import { ApprovalsFilters } from "./ApprovalsFilters";
import {
  changeApprovalProcessorInBulk,
  getApprovals,
} from "../../repos/ApprovalsRepo";
import {
  defaultApprovalsSearch,
  PageModeContext,
} from "../../Pages/ProceduresPage";
import { useAppSelector } from "../../redux/reduxHooks";
import { SubSection } from "../SubSection";

interface IProps {
  farmUuid?: string;
  farmRegistrationNumber?: string;
  count?: number;
  processingStarted?: boolean;
}

export const ApprovalsTable: FC<IProps> = ({
  farmUuid,
  farmRegistrationNumber,
  count,
  processingStarted,
}) => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(count ? (count > 50 ? count : 50) : 10);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<IApprovalSearch>(defaultApprovalsSearch);
  const [searchData, setSearchData] = useState<IApprovalSearch>(
    defaultApprovalsSearch
  );
  const [processingApproval, setProcessingApproval] = useState<IApprovalNew>(
    {}
  );

  const mode = useContext(PageModeContext);
  const { t } = useTranslation();
  const activeUserUuid = useAppSelector((state) => state.user.uuid);

  const privileges = useAppSelector((state) => state.user.privileges);
  const justPreviewRights = !privileges.APPROVAL_W && privileges.APPROVAL_R;

  const handleGetApprovals = useCallback(() => {
    setIsLoading(true);
    getApprovals(
      { limit, offset },
      {
        ...search,
        administrativeProcedure: !farmRegistrationNumber
          ? mode === "ADMINISTRATIVE_PROCEDURES"
          : undefined,
        excludeChangedObjects: mode !== "INVOICES" ? ["INVOICE"] : undefined,
        farmUuid,
      }
    )
      .then(({ approvals, totalRows }) => {
        setData(
          approvals.map((e) => ({
            uuid: e.uuid,
            cells: {
              approvalNumber: { value: e.approvalNumber || "" },
              changedObject: {
                value: e.changedObject
                  ? t(
                      e.holder
                        ? `ChangedObject${e.changedObject}${e.holder.type}`
                        : `ChangedObject${e.changedObject}`
                    ) +
                    (e.changedObject === "HOLDER" && e.holder?.temporary
                      ? ` (${t("Temporary")})`
                      : "")
                  : "",
              },
              farmRegistrationNumber: {
                value: e.farm?.registrationNumber || "",
              },
              createdAt: { value: formatDate(e.createdAt) || "" },
              statusTime: { value: formatDate(e.statusTime) || "" },
              processorUser: {
                value: e.processor
                  ? e.processor?.firstName + " " + e.processor?.lastName
                  : "",
              },
              approvalStatus: {
                value: e.approvalStatus ? (
                  <p
                    className={clsx({
                      "color-warning": e.approvalStatus === "IN_PROGRESS",
                      "color-error": e.approvalStatus === "PENDING",
                      disabled:
                        e.approvalStatus === "REJECTED" ||
                        e.approvalStatus === "APPROVED",
                    })}
                  >
                    {t(`ApprovalStatus${e.approvalStatus}`)}
                  </p>
                ) : (
                  ""
                ),
              },
              mode: {
                value: t(
                  mode === "ADMINISTRATIVE_PROCEDURES"
                    ? "Administrative"
                    : "NonAdministrative"
                ),
              },
              details: {
                value: (
                  <Button
                    label={t("Preview")}
                    onClick={() => {
                      window.open(
                        `/approval/${e.uuid}/edit?approvalPreview=true`,
                        "_blank"
                      );
                    }}
                    outlined
                    size={"s"}
                  />
                ),
              },
              actions: {
                value:
                  e.approvalStatus !== "REJECTED" ? (
                    <Button
                      label={t(
                        e.approvalStatus === "APPROVED"
                          ? "DetailsPreview"
                          : "DownloadForProcessing"
                      )}
                      onClick={() => setProcessingApproval(e)}
                      outlined
                      size={"s"}
                      disabled={
                        e.approvalStatus === "IN_PROGRESS" &&
                        !!activeUserUuid &&
                        activeUserUuid !== e.processor?.uuid
                      }
                    />
                  ) : (
                    ""
                  ),
              },
            },
          })) as ITableDataItem[]
        );
        setTotalRows(totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [
    mode,
    limit,
    offset,
    search,
    t,
    activeUserUuid,
    farmRegistrationNumber,
    farmUuid,
  ]);

  useEffect(handleGetApprovals, [handleGetApprovals]);

  const columns: ITableColumn[] = [
    { id: "approvalNumber", label: t("ApprovalNumber") },
    { id: "changedObject", label: t("ChangedObject") },
    {
      id: "farmRegistrationNumber",
      label: t("OrgNumberShort"),
      hidden: !!farmRegistrationNumber,
    },
    { id: "createdAt", label: t("RequestDate") },
    {
      id: "statusTime",
      label: t("StatusTime"),
      hidden: !!farmRegistrationNumber,
    },
    { id: "processorUser", label: t("ProcessorUser") },
    { id: "approvalStatus", label: t("Status") },
    { id: "mode", label: t("ModeType"), hidden: !farmRegistrationNumber },
    { id: "details", label: "" },
    { id: "actions", label: "", hidden: justPreviewRights },
  ];

  const onSearch = (search: IApprovalSearch) => {
    setSearch(
      deleteProps(
        {
          ...search,
          changedObject: ["TEMPORARY_HOLDER", "CHANGE_HOLDER"].includes(
            search.changedObject!
          )
            ? "HOLDER"
            : search.changedObject,
          holderTemporary:
            search.changedObject === "TEMPORARY_HOLDER"
              ? true
              : search.changedObject === "CHANGE_HOLDER"
              ? false
              : null,
        },
        ["farmObj", "processorUser"]
      )
    );
    setOffset(0);
  };

  return (
    <>
      {!!farmRegistrationNumber && (
        <h5>
          <SubSection title={`${t("OrgNumberS")}: ${farmRegistrationNumber}`}>
            <Button
              label={t("GetFarmToProcessing")}
              onClick={() =>
                changeApprovalProcessorInBulk(
                  data.map((e) => ({ uuid: e.uuid }))
                )
                  .then(handleGetApprovals)
                  .catch(() => {})
              }
              outlined
              disabled={processingStarted}
            />
          </SubSection>
        </h5>
      )}
      <Table
        columns={columns}
        data={data}
        filterData={
          !farmRegistrationNumber
            ? {
                columns: [
                  "processorUser",
                  "concluded",
                  "approvalNumber",
                  "farm",
                  "farmRegistrationNumber",
                  "changedObject",
                  "holderTemporary",
                  "approvalStatus",
                  "creationDate",
                  "statusTime",
                  "memberLand",
                  "holderPersonalIdentityNumber",
                  "holderRegistrationNumber",
                  "landUsageBasis",
                ],
                filters: ApprovalsFilters(searchData, setSearchData),
                search,
                searchData,
                setSearchData,
                onSubmit: onSearch,
                resetData: defaultApprovalsSearch,
              }
            : undefined
        }
        isLoading={isLoading}
        serverSidePagination={{ limit, offset, setLimit, setOffset, totalRows }}
        footerAction={{
          onClick: handleGetApprovals,
        }}
        hideFooter={!!farmRegistrationNumber}
      />
      <Dialog
        isOpen={!!processingApproval?.uuid}
        onClose={() => setProcessingApproval({})}
        title={t(
          processingApproval?.approvalStatus === "APPROVED"
            ? "DetailsPreviewTitle"
            : "DownloadForProcessingTitle"
        )}
        desc={t(
          processingApproval?.approvalStatus === "APPROVED"
            ? "DetailsPreviewDesc"
            : "DownloadForProcessingDesc"
        )}
        confirmButton={{
          label: t("Download"),
          onClick: () => {
            window.open(`/approval/${processingApproval.uuid}/edit`, "_blank");
          },
        }}
        cancelButton={{ onClick: () => setProcessingApproval({}) }}
      />
    </>
  );
};
