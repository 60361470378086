import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import { useAppSelector } from "../redux/reduxHooks";
import { NoAccessComponent } from "../Components/Common/NoAccessComponent";
import { FarmsTable } from "../Components/Tables/FarmsTable";

export const FarmsRegisterPage: FC = () => {
  const { t } = useTranslation();
  const privileges = useAppSelector((state) => state.user.privileges);

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`FarmsRegister`)}`}</h1>
      </div>
      <div>
        <div className="flex p-3">
          <Sidebar />
          {privileges.SEARCH_R ? <FarmsTable /> : <NoAccessComponent />}
        </div>
      </div>
    </div>
  );
};
