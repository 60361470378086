import { IPagination } from "@inceptionbg/ui-components";
import { axiosInstance } from "../App";
import {
  IPesticideProcurement,
  IPesticideProcurementSearch,
  PesticideProcurementsFetchOptions,
} from "../types/IPesticideProcurement";

export const getProcurementPesticides = (
  pagination: IPagination,
  search?: IPesticideProcurementSearch,
  fetchOptions?: PesticideProcurementsFetchOptions[]
) =>
  axiosInstance
    .get("/admin/pesticide-procurements", {
      params: { ...pagination, ...search, fetchOptions },
    })
    .then(({ data }) => ({
      pesticideProcurements:
        data.pesticideProcurements as IPesticideProcurement[],
      totalRows: data.totalRows as number,
    }));
