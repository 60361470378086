import {
  deleteProps,
  ITableFilter,
  TextInput,
} from "@inceptionbg/ui-components";
import { t } from "i18next";
import { IAdminUserSearch } from "../../types/IUser";

export const AdminUsersFilters: (
  search: IAdminUserSearch,
  setSearch: (search: IAdminUserSearch) => void
) => ITableFilter = (search, setSearch) => ({
  firstName: {
    label: t("FirstName"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.firstNameLike || ""}
        setValue={(firstNameLike) => setSearch({ ...search, firstNameLike })}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["firstNameLike"])),
  },
  lastName: {
    label: t("LastName"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.lastNameLike || ""}
        setValue={(lastNameLike) => setSearch({ ...search, lastNameLike })}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["lastNameLike"])),
  },
  email: {
    label: t("Email"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.emailLike || ""}
        setValue={(emailLike) => setSearch({ ...search, emailLike })}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["emailLike"])),
  },
  umcn: {
    label: t("PersonalIdentityNumber"),
    field: (
      <TextInput
        placeholder={t("AddInput") + "..."}
        value={search.umcn || ""}
        setValue={(umcn) => setSearch({ ...search, umcn })}
      />
    ),
    resetField: () => setSearch(deleteProps(search, ["umcn"])),
  },
});
