import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../Elements/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "../../assets/icons/light/faArrowRight";

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onPartialSubmit: () => void;
}

export const LandsConfirmationDialog: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  onPartialSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={t("LandsConfirmation")}>
      <div>
        <div onClick={onSubmit}>
          <div className="flex dialog-link">
            {t("ConfirmOnAllLands")}
            <div className="flex full-width justify-right">
              <FontAwesomeIcon
                icon={faArrowRight}
                className="color-primary icon-3"
              />
            </div>
          </div>
        </div>
        <div onClick={onPartialSubmit}>
          <div className="flex dialog-link">
            {t("ConfirmPartially")}
            <div className="flex full-width justify-right">
              <FontAwesomeIcon
                icon={faArrowRight}
                className="color-primary icon-3"
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
