import { Dispatch, FC, SetStateAction } from "react";
import { SubSection } from "../SubSection";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { DateInput, formatDate, TextInput } from "@inceptionbg/ui-components";
import { EvidencesList } from "../Files/EvidencesList";
import { IApprovalNew } from "../../types/IApprovals";

interface Props {
  approval: IApprovalNew;
  setApproval: Dispatch<SetStateAction<IApprovalNew>>;
  enableEdit?: boolean;
}

export const EvidencesForm: FC<Props> = ({
  approval,
  setApproval,
  enableEdit = false,
}) => {
  const { t } = useTranslation();

  const formInfo: IInputComponentProps[] = [
    {
      label: t("SolutionNumber"),
      value: approval.decisionNumber || "",
      input: ["APPROVED", "REJECTED"].includes(
        approval.approvalStatus || ""
      ) ? (
        <p>{approval.decisionNumber}</p>
      ) : (
        <TextInput
          value={approval.decisionNumber}
          setValue={(decisionNumber) =>
            setApproval({ ...approval, decisionNumber })
          }
          required
        />
      ),
    },
    {
      label: t("DateOfResolution"),
      value: formatDate(approval.decisionDate),
      input: ["APPROVED", "REJECTED"].includes(
        approval.approvalStatus || ""
      ) ? (
        <p>{formatDate(approval.decisionDate)}</p>
      ) : (
        <DateInput
          required
          date={approval.decisionDate}
          setDate={(decisionDate) => setApproval({ ...approval, decisionDate })}
        />
      ),
    },
  ];

  return (
    <SubSection title={t("Attachments")} expandable={false} initialyOpen={true}>
      {!!approval?.evidences?.length && (
        <EvidencesList evidences={approval.evidences} />
      )}
      {enableEdit && approval?.administrativeProcedure && (
        <div className="form-container pt-3">
          {formInfo.map(
            (e) => !e.hidden && <Input key={e.label} component={e} edit />
          )}
        </div>
      )}
    </SubSection>
  );
};
