import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { SubSection } from "../SubSection";
import { getLandAPRData, updateLandAPRData } from "../../repos/LandRepo";
import { IAprResponse } from "../../types/ILand";
import { IFile } from "../../types/IFile";
import { Button } from "@inceptionbg/ui-components";
import { API_URL } from "../../App";

interface Props {
  landUuid: string;
}

export const APRData: FC<Props> = ({ landUuid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [aprResponse, setAprResponse] = useState<IAprResponse>();
  const [storedFile, setStoredFile] = useState<IFile>();

  const { t } = useTranslation();

  const handleGetLandAPRData = useCallback(() => {
    setIsLoading(true);
    getLandAPRData(landUuid)
      .then((e) => {
        setAprResponse(e.aprResponse);
        setStoredFile(e.storedFile);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [landUuid]);
  useEffect(handleGetLandAPRData, [handleGetLandAPRData]);

  const formInfo: IInputComponentProps[] = [
    {
      label: t("IsAprMember"),
      valueElement: (
        <p className="left-auto">{t(aprResponse?.member ? "Yes" : "No")}</p>
      ),
    },
    {
      label: t("IsAprConnected"),
      valueElement: (
        <p className="left-auto">{t(aprResponse?.connected ? "Yes" : "No")}</p>
      ),
    },
    {
      label: t("IsAprConnectedLegalEntityMember"),
      valueElement: (
        <p className="left-auto">
          {t(aprResponse?.connectedLegalEntityMember ? "Yes" : "No")}
        </p>
      ),
    },
    {
      label: t("IsAprPartnerOrComplementary"),
      valueElement: (
        <p className="left-auto">
          {t(aprResponse?.partnerOrComplementary ? "Yes" : "No")}
        </p>
      ),
    },
    {
      label: t("IsAprFounder"),
      valueElement: (
        <p className="left-auto">{t(aprResponse?.founder ? "Yes" : "No")}</p>
      ),
    },
  ];

  return (
    <SubSection
      title={t("APRData")}
      isLoading={isLoading}
      expandable={true}
      initialyOpen
    >
      {formInfo.map((e) => !e.hidden && <Input key={e.label} component={e} />)}
      <div className="page-sub-section-buttons flex justify-right">
        <Button
          label={t(
            !!storedFile?.downloadUrl
              ? "DownloadResponseFromAPR"
              : "UpdateLandDataFromAPR"
          )}
          onClick={() => {
            setIsLoading(true);
            if (!!storedFile?.downloadUrl) {
              window.open(
                API_URL + storedFile.downloadUrl,
                "_blank",
                "noreferrer"
              );
              setIsLoading(false);
            } else {
              updateLandAPRData(landUuid)
                .then(handleGetLandAPRData)
                .catch(() => {})
                .finally(() => setIsLoading(false));
            }
          }}
          size="s"
          className="primary"
          primary
        />
      </div>
    </SubSection>
  );
};
