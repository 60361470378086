import { Button } from "@inceptionbg/ui-components";
import clsx from "clsx";
import { FC, ReactElement } from "react";
import { IAlertType } from "../types/IBase";
import { AlertIcon } from "./Alert/Alert";

export interface IInputComponentProps {
  label: string;
  labelHelper?: string;
  value?: string;
  valueElement?: ReactElement;
  input?: ReactElement;
  inputHelper?: {
    text?: string;
    action?: {
      label: string;
      onClick: () => void;
    };
  };
  alertType?: IAlertType;
  readOnly?: boolean;
  hidden?: boolean;
}

interface Props {
  component: IInputComponentProps;
  edit?: boolean;
}

export const Input: FC<Props> = ({
  component: {
    label,
    alertType,
    input,
    inputHelper,
    labelHelper,
    value,
    valueElement,
    readOnly,
  },
  edit,
}) => (
  <div className="input-container">
    <div className={clsx("input-item gap-2", { edit })}>
      <div className="label-container">
        <p className="label">{label}</p>
        <p className="helper-label">{labelHelper}</p>
      </div>
      {!readOnly && edit && input
        ? input
        : valueElement || <p className="full-width">{value || "/"}</p>}
      {alertType && <AlertIcon type={alertType} className="input-alert" />}
    </div>
    {edit && inputHelper && (
      <div className="input-helper">
        {inputHelper.text && (
          <p
            className={clsx("helper-text", {
              "color-error": alertType === "error",
            })}
          >
            {inputHelper.text}
          </p>
        )}
        {inputHelper.action && (
          <Button
            label={inputHelper.action.label}
            primary
            onClick={inputHelper.action.onClick}
            size="xs"
            className={alertType}
          />
        )}
      </div>
    )}
  </div>
);
