import { Button } from "@inceptionbg/ui-components";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { PageWrapper } from "../Components/Wrapper/PageWrapper";
import { getToken, login } from "../repos/AuthRepo";
import { IUser } from "../types/IUser";
import { localStorageGet } from "../utils/LocalStorageHelper";
import { setUserFromToken } from "../utils/TokenUtils";
import { parseUrlSearch } from "../utils/UrlUtils";

interface Props {
  setUser: (user: IUser | null) => void;
}

export const LoginPage: FC<Props> = ({ setUser }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { code } = parseUrlSearch(search);
    const token = localStorageGet("token");
    if (code && !token) {
      getToken(code)
        .then((token) => {
          setUserFromToken(token, setUser);
          navigate("/");
        })
        .catch(() => {});
    }
  }, [search, setUser, navigate]);

  return (
    <PageWrapper>
      <div className="page flex column gap-4 justify-center align-center">
        <h1>{t("LoginTitle")}</h1>
        <Button label={t("Login")} size="l" primary onClick={() => login()} />
      </div>
    </PageWrapper>
  );
};
