import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IFarm } from "../../../types/IFarm";
import { IMemberNew } from "../../../types/IMember";
import { getMembers } from "../../../repos/MembersRepo";
import { Section } from "../../Section";
import { Member } from "../../Procedures/Member";

interface Props {
  farm: IFarm;
}

export const MembersSection: FC<Props> = ({ farm }) => {
  const [members, setMembers] = useState<IMemberNew[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleGetMembers = useCallback(
    (callback?: () => void) => {
      if (farm) {
        if (farm.members) {
          setMembers(farm.members);
        } else {
          setIsLoading(true);
          getMembers(farm.uuid)
            .then((data) => {
              setMembers(data.members);
              callback && callback();
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
        }
      }
    },
    [farm]
  );

  useEffect(handleGetMembers, [handleGetMembers]);

  return (
    <Section id="members" title={t("MembersTitle")}>
      <div className="form-container">
        {farm &&
          !isLoading &&
          members.map((member) => (
            <Member
              key={member.personalIdentityNumber}
              member={member}
              farm={farm}
            />
          ))}
      </div>
    </Section>
  );
};
