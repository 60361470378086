import { IPrivileges } from "../../types/IPrivileges";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserState {
  uuid: string;
  privileges: IPrivileges;
}

const initialState: IUserState = {
  uuid: "",
  privileges: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserUuid: (state, action: PayloadAction<string>) => {
      state.uuid = action.payload;
    },
    setUserPrivileges: (state, action: PayloadAction<string[]>) => {
      state.privileges = action.payload.reduce(
        (acc: IPrivileges, privilege: string) => ({
          ...acc,
          [privilege]: true,
        }),
        {}
      );
    },
  },
});

export const { setUserUuid, setUserPrivileges } = userSlice.actions;

export default userSlice.reducer;
