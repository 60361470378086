import { FC, useCallback, useEffect, useState } from "react";
import { IFarmStatusHistory } from "../../types/IFarmStatusHistory";
import { useTranslation } from "react-i18next";
import { IFarm } from "../../types/IFarm";
import {
  ITableColumn,
  ITableDataItem,
  formatDateAndTime,
} from "@inceptionbg/ui-components";
import { Table } from "../CustomIUI/Table/Table";
import { getFarmStatusHistory } from "../../repos/FarmRepo";

interface Props {
  farm: IFarm;
}

export const FarmStatusHistoryTable: FC<Props> = ({ farm }) => {
  const [farmStatusHistoryData, setFarmStatusHistoryData] = useState<
    IFarmStatusHistory[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleGetFarmStatusHistoryData = useCallback(() => {
    if (farm) {
      setIsLoading(true);
      getFarmStatusHistory(farm.uuid)
        .then((data) => setFarmStatusHistoryData(data.farmStatusChanges))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [farm]);

  useEffect(handleGetFarmStatusHistoryData, [handleGetFarmStatusHistoryData]);

  const columns: ITableColumn[] = [
    { id: "decisionNumber", label: t("DecisionNumber") },
    { id: "oldStatus", label: t("OldStatus") },
    { id: "newStatus", label: t("NewStatus") },
    { id: "passiveStatus", label: t("PassiveStatusReason") },
    { id: "createdAt", label: t("CreatedAt") },
  ];

  const data: ITableDataItem[] = farmStatusHistoryData.map((e) => ({
    uuid: e.uuid!,
    cells: {
      decisionNumber: {
        value: e.decisionNumber,
      },
      oldStatus: {
        value: t(e.oldStatus),
      },
      newStatus: {
        value: t(e.newStatus),
      },
      passiveStatus: {
        value: e.passiveStatusReason?.name,
      },
      createdAt: {
        value: formatDateAndTime(e.createdAt),
      },
    },
  }));

  return (
    <Table
      headerWrap
      isLoading={isLoading}
      data={data}
      columns={columns}
      hideFooter
      customPagination={{ defaultLimit: 1000 }}
    />
  );
};
