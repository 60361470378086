import { axiosInstance } from "../App";
import { IFile } from "../types/IFile";

export const uploadFile = (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return axiosInstance
    .post("/stored-file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        noToast: true,
      },
    })
    .then(
      ({ data }) =>
        ({
          uuid: data.identifier,
          originalFileName: data.originalFileName,
        } as IFile)
    );
};

export const downloadFile = (downloadUrl?: string) =>
  axiosInstance
    .get(`${downloadUrl}`, { responseType: "arraybuffer" })
    .then((res) => {
      return { data: res.data };
    });
