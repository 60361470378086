import { Dispatch, FC, SetStateAction } from "react";
import { IPlantProtectionProduct } from "../../types/IPlantProtectionProduct";
import { useTranslation } from "react-i18next";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { Button, TextInput } from "@inceptionbg/ui-components";

interface Props {
  data?: Partial<IPlantProtectionProduct>;
  setData: Dispatch<SetStateAction<Partial<IPlantProtectionProduct>>>;
  onSubmit: () => void;
}

export const PlantProtectionProductForm: FC<Props> = ({
  data,
  setData,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const formInfo: IInputComponentProps[] = [
    {
      label: t("Name"),
      value: data?.name || "",
      input: (
        <TextInput
          required
          value={data?.name || ""}
          setValue={(e) => setData({ ...data, name: e })}
        />
      ),
    },
    {
      label: t("Producer"),
      value: data?.manufacturerName || "",
      input: (
        <TextInput
          required
          value={data?.manufacturerName || ""}
          setValue={(e) => setData({ ...data, manufacturerName: e })}
        />
      ),
    },
    {
      label: t("Category"),
      value: data?.category || "",
      input: (
        <TextInput
          required
          value={data?.category || ""}
          setValue={(e) => setData({ ...data, category: e })}
        />
      ),
    },
  ];

  return (
    <form
      className="form-container"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {formInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} edit />
      )}
      <div className="flex justify-right">
        <Button label={t("Save")} size="s" className="primary" primary />
      </div>
    </form>
  );
};
