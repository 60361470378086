import { Dispatch, FC, SetStateAction } from "react";
import { IEvidence } from "../../types/IFile";
import { uploadFile } from "../../repos/FileRepo";
import { EvidencesList } from "./EvidencesList";
import { FilesDropzone } from "./Dropzone/FilesDropzone";

interface Props {
  evidences: IEvidence[];
  setEvidences: Dispatch<SetStateAction<IEvidence[]>>;
  setIsLoading: (isLoading: boolean) => void;
}

export const OpenCaseEvidences: FC<Props> = ({
  evidences,
  setEvidences,
  setIsLoading,
}) => {
  const handleUpload = (data: File[]) => {
    setIsLoading(true);
    data.forEach((file, i) => {
      uploadFile(file)
        .then((e) =>
          setEvidences((evidences) => [...evidences, { storedFile: e }])
        )
        .catch(() => setIsLoading(false))
        .finally(() => {
          if (i === data.length - 1) {
            setIsLoading(false);
          }
        });
    });
  };

  const onDeleteFile = (index: number, callback: () => void) => {
    setEvidences((e) => {
      let newList = [...e];
      newList.splice(index, 1);
      return newList;
    });
    callback();
  };

  return (
    <>
      {!!evidences.length && (
        <EvidencesList evidences={evidences} onDeleteFile={onDeleteFile} />
      )}
      <FilesDropzone onSubmit={handleUpload} />
    </>
  );
};
