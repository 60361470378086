import {
  ITableColumn,
  ITableDataItem,
  formatDate,
  formatDateAndTime,
} from "@inceptionbg/ui-components";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getProcurementPesticides } from "../../../repos/PesticideProcurementsReportRepo";
import { SubSection } from "../../SubSection";
import { Table } from "../../CustomIUI/Table/Table";
import { IPesticideProcurementSearch } from "../../../types/IPesticideProcurement";
import { PesticideProcurementFilters } from "../../Tables/PesticideProcurementFilters";

export const PesticideProcurementsReport: FC = () => {
  const [data, setData] = useState<ITableDataItem[]>([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<IPesticideProcurementSearch>({});
  const [searchData, setSearchData] = useState<IPesticideProcurementSearch>({});

  const { t } = useTranslation();

  const columns: ITableColumn[] = [
    { id: "farmRegistrationNumber", label: t("OrgNumberShort") },
    { id: "category", label: t("Category") },
    { id: "producer", label: t("Producer") },
    { id: "name", label: t("Name") },
    { id: "quantity", label: t("Quantity") },
    { id: "unit", label: t("UnitOfMeasure") },
    { id: "date", label: t("PurchaseDate") },
    { id: "createdAt", label: t("CreatedAtTime") },
  ];

  const handleGetPesticideProcurements = useCallback(() => {
    setIsLoading(true);
    getProcurementPesticides({ limit, offset }, { ...search }, [
      "FARM",
      "ORGANIZATION_FORM",
      "PLANT_PROTECTION_PRODUCT",
    ])
      .then(({ pesticideProcurements }) => {
        setData(
          pesticideProcurements.map((pesticiceProcurement) => ({
            uuid: pesticiceProcurement.uuid,
            cells: {
              farmRegistrationNumber: {
                value: pesticiceProcurement.farm?.registrationNumber,
              },
              category: {
                value: pesticiceProcurement.plantProtectionProduct?.category,
              },
              producer: {
                value:
                  pesticiceProcurement.plantProtectionProduct?.manufacturerName,
              },
              name: {
                value: pesticiceProcurement.plantProtectionProduct?.name,
              },
              quantity: {
                value: pesticiceProcurement.quantity,
              },
              unit: {
                value: pesticiceProcurement.unit,
              },
              date: {
                value: formatDate(pesticiceProcurement.date),
              },
              createdAt: {
                value: formatDateAndTime(pesticiceProcurement.createdAt),
              },
            },
          })) as ITableDataItem[]
        );
        setTotalRows(totalRows);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [totalRows, limit, offset, search]);

  useEffect(handleGetPesticideProcurements, [handleGetPesticideProcurements]);

  const onSearch = (search: IPesticideProcurementSearch) => setSearch(search);

  return (
    <SubSection title={t("PesticideProcurements")} expandable={false}>
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        serverSidePagination={{
          limit,
          offset,
          setLimit,
          setOffset,
          totalRows,
        }}
        filterData={{
          columns: [
            "farmRegistrationNumber",
            "plantProtectionProductUuid",
            "supplierNameLike",
            "date",
          ],
          filters: PesticideProcurementFilters(searchData, setSearchData),
          search,
          searchData,
          setSearchData,
          onSubmit: onSearch,
        }}
        footerAction={{
          onClick: handleGetPesticideProcurements,
        }}
      />
    </SubSection>
  );
};
