import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../Components/Sidebar";
import { LandReportsTable } from "../Components/Tables/LandReportsTable";
import { useAppSelector } from "../redux/reduxHooks";
import { NoAccessComponent } from "../Components/Common/NoAccessComponent";

export const LandReportsPage: FC = () => {
  const { t } = useTranslation();
  const hasAccess = useAppSelector((state) => state.user.privileges.APPROVAL_W);

  return (
    <div>
      <div className="background-info px-4 py-2">
        <h1>{`${t("eRPGLong")} - ${t(`LandReports`)}`}</h1>
      </div>
      <div>
        <div className="flex p-3">
          <Sidebar />
          {hasAccess ? <LandReportsTable /> : <NoAccessComponent />}
        </div>
      </div>
    </div>
  );
};
