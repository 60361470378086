import { FC } from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/svg/FooterLogo.svg";

export const Footer: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <img src={logo} alt="Footer logo" height={180} />
      <div className="full-width">
        <div className="container-primary">
          <div>
            <p>{t("FooterFreeCall")}</p>
            <h1>0800 106 107</h1>
          </div>
        </div>
        <div className="container-secondary" />
      </div>
    </div>
  );
};
