import { FC, FormEvent, ReactNode } from "react";
import { Button } from "@inceptionbg/ui-components";
import { IInputComponentProps, Input } from "../../Elements/Input";
import { useTranslation } from "react-i18next";
import { Loader } from "../../Elements/Loader";
import { IButtonSize } from "../../types/IBase";

export interface IForm {
  data: IInputComponentProps[];
  edit: boolean;
  setEdit: (e: boolean) => void;
  submitButton: {
    editLabel: string;
    icon?: any;
    disabled?: boolean;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    primary?: boolean;
    hidden?: boolean;
  };
  onResetForm: () => void;
  onCancel?: () => void;
  otherButtons?: {
    label: string;
    icon?: any;
    disabled?: boolean;
    outlined?: boolean;
    primary?: boolean;
    onClick: () => void;
    hidden?: boolean;
  }[];
  buttonSize?: IButtonSize;
  isLoading: boolean;
  className?: string;
  children?: ReactNode;
}

export const Form: FC<IForm> = ({
  data,
  edit,
  setEdit,
  submitButton,
  onResetForm,
  onCancel,
  otherButtons,
  buttonSize,
  isLoading,
  className,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Loader isLoading={isLoading}>
      <form
        className={className}
        onSubmit={(e) => {
          e.preventDefault();
          !submitButton.disabled && submitButton.onSubmit(e);
        }}
      >
        <div className="form-container">
          {data.map(
            (e) =>
              !e.hidden && <Input key={e.label} component={e} edit={edit} />
          )}
          {children}
          <div className="flex justify-right gap-2 mr-5">
            {!edit &&
              otherButtons?.map(
                (e) =>
                  !e.hidden && (
                    <Button
                      key={e.label}
                      label={e.label}
                      icon={e.icon}
                      disabled={isLoading || e.disabled}
                      outlined={e.outlined}
                      primary={e.primary}
                      onClick={e.onClick}
                      size={buttonSize || "s"}
                    />
                  )
              )}
            {edit && (
              <Button
                label={t("Cancel")}
                onClick={
                  onCancel
                    ? onCancel
                    : () => {
                        onResetForm();
                        setEdit(false);
                      }
                }
                outlined
                size={buttonSize || "s"}
              />
            )}
            {/* Must be separated because of the form submit */}
            {!submitButton.hidden && edit && (
              <Button
                label={submitButton.editLabel || t("SaveChanges")}
                primary
                size={buttonSize || "s"}
              />
            )}
            {!submitButton.hidden && !edit && (
              <Button
                label={submitButton.editLabel}
                onClick={() => setEdit(!edit)}
                size={buttonSize || "s"}
                outlined
              />
            )}
          </div>
        </div>
      </form>
    </Loader>
  );
};
