import { axiosInstance } from "../App";
import { IPassiveStatusReason, IPersonalInfoData } from "../types/IFarm";
import {
  ISimpleObject,
  ISimpleObjectWithCode,
} from "@inceptionbg/ui-components";
import { IPlantProtectionProduct } from "../types/IPlantProtectionProduct";

export const getUserOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/users", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.users.map((e: IPersonalInfoData) => ({
        value: e.uuid,
        label: e.firstName + " " + e.lastName,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getPassiveStatusReasonOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/passive-status-reasons", {
      params: {
        fullText,
      },
    })
    .then(({ data }) => ({
      options: data.passiveStatusReasons.map((e: IPassiveStatusReason) => ({
        value: e.uuid,
        label: e.name,
      })),
      hasMore: false,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getCadastreMunicipalityOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/cadastre-municipalities", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.municipalities.map((e: ISimpleObjectWithCode) => ({
        value: e.uuid,
        label: e.name,
        code: e.code,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getAdminUserRoles = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/roles", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.roles.map((e: ISimpleObject) => ({
        value: e.uuid,
        label: e.name,
      })),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));

export const getPlantProtectionProductOptions = (
  fullText: string,
  loadedOptions: [],
  offset: { page: number }
) =>
  axiosInstance
    .get("/plant-protection-products", {
      params: {
        fullText,
        limit: 50,
        offset: offset.page,
      },
    })
    .then(({ data }) => ({
      options: data.plantProtectionProducts.map(
        (e: IPlantProtectionProduct) => ({
          value: e.uuid,
          label: e.name,
        })
      ),
      hasMore: loadedOptions.length < data.pagination?.totalRows,
      additional: { page: offset.page + 1 },
    }))
    .catch((error) => console.error(error));
